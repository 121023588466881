import DashboardLayout from "@/views/App/DashboardLayout.vue";

import auth from "@/middleware/auth";
import creator from "@/middleware/creator";

import ListEventItems from "@/views/Events/Management/ListEventItems";
import AddEventItem from "@/views/Events/Management/AddEventItem";
import EditEventItem from "@/views/Events/Management/EditEventItem";
import ListCategoryPage from "@/views/Examples/CategoryManagement/ListCategoryPage";
import ListTagPage from "@/views/Tags/Management/ListTagItems";
import EventLandingPage from "@/views/Events/Pages/PreviewEventPage";
import ScanEventPage from "@/views/Events/Pages/ScanEventPage";


let itemsNav = {
    path: "/manage",
    component: DashboardLayout,
    name: "Events",
    children: [
        {
            path: "events/list-items",
            name: "List Events",
            components: { default: ListEventItems },
            meta: { middleware: auth }
        },
        {
            path: "events/add-item",
            name: "Add Event",
            components: { default: AddEventItem },
            meta: { middleware: auth }
        },
        {
            path: "events/edit-item/:id",
            name: "Edit Event",
            components: { default: EditEventItem },
            meta: { middleware: auth }
        },
        {
            path: "categories/list-categories",
            name: "List Categories",
            components: { default: ListCategoryPage },
            meta: { middleware: auth }
        },
        {
            path: "tags/list-tags",
            name: "List Tags",
            components: { default: ListTagPage },
            meta: { middleware: auth }
        },
        {
            path: "event/:id",
            name: "Event Preview",
            components: { default: EventLandingPage },
        },
        {
            path: "event-scan",
            name: "Scan Event",
            components: { default: ScanEventPage },
            meta: { middleware: auth }
        },
    ]
}

export default itemsNav;
