<template>
  <!-- Card header -->
  <div
    class="row justify-content-center mt-3 mb-3"
    style="display: flex; flex-wrap: wrap"
  >
    <div
      class="content"
      style="display: flex; justify-content: space-between; width: 85%"
    >
      <h3 class="h3">
        My Tasklist
        <small class="text-muted font-weight-bold">({{ total }})</small>
      </h3>
      <base-button
        type="primary"
        icon
        size="sm"
        @click="toggleNewTask()"
        style="cursor: pointer"
      >
        <span v-if="toggle" class="btn-inner--icon"
          ><i class="ni ni-fat-add"></i
        ></span>
        <span class="btn-inner--text">{{
          toggle ? "New task" : "Cancel"
        }}</span>
      </base-button>
    </div>
    <div v-if="!toggle" style="flex: 0 0 70%">
      <form @submit.prevent="handleSubmit">
        <div class="mt-4 mb-4">
          <base-input label="Name *"
                      v-model="task.name"
                      placeholder="Set up a reminder for yourself"
          />
          <!-- <validation-error :errors="apiValidationErrors.name" /> -->
          <base-input label="Description *">
            <textarea
              v-model="task.description"
              name="description"
              placeholder="Few lines on what's next to plan for the perfect event"
              class="form-control"
              rows="2"
            />
          </base-input>
          <!-- <validation-error :errors="apiValidationErrors.description" /> -->

          <base-button
            type="button"
            class="btn btn-sm btn-primary ml-0"
            native-type="submit"
          >
            <i class="fa fa-save" /> Create Task
          </base-button>
        </div>
      </form>
      <hr />
    </div>

    <!-- Card body -->
    <div v-else class="col-11 mt-2">
      <ul class="list-group list-group-flush" data-toggle="checklist">
        <li
          style="padding: 0.5rem 1rem"
          class="checklist-entry list-group-item flex-column align-items-start px-4"
          v-for="task in tasks"
          :key="task.id"
        >
          <div
            class="checklist-item"
            :class="{
              'checklist-item-checked': task.status,
              'checklist-item-danger': task.status,
              [`checklist-item-${tasks.checkType}`]: task.checkType,
            }"
          >
            <div class="checklist-info">
              <h5 class="checklist-title mb-0">{{ task.name }}</h5>
              <small>{{ task.description }}</small>
            </div>
            <div>
              <el-checkbox v-model="task.status" @change="updateStatus(task)" />
            </div>
          </div>
          <div class="table-actions text-right">
            <el-tooltip content="Delete" placement="top">
              <a
                type="text"
                @click="toggleToDelete(task, true)"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="far fa-trash-alt"></i>
              </a>
            </el-tooltip>
          </div>
          <div v-if="task.toDelete && !loading">
            <button
              @click="deleteTask(task)"
              type="button"
              class="btn btn-warning task-confrim"
              aria-label=""
              style="display: inline-block"
            >
              Yes, delete it!
            </button>
            <button
              @click="toggleToDelete(task, false)"
              type="button"
              class="btn btn-primary task-confrim"
              aria-label=""
              style="display: inline-block"
            >
              No, keep it
            </button>
          </div>
          <div class="text-center" v-if="task.toDelete && loading">
            <slot name="loading">
              <i class="fas fa-spinner fa-spin"></i>
            </slot>
          </div>
        </li>
      </ul>
    </div>

    <div class="col-12 d-flex flex-wrap" style="flex-direction: column">
      <base-pagination
        size="sm"
        style="padding-top: 1em; justify-content: center"
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import { BasePagination } from "@/components/";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  RadioButton,
  Button,
  Checkbox,
} from "element-ui";

export default {
  layout: "TaskList",

  mixins: [formMixin],

  components: {
    ValidationError,
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      toggle: true,
      pagination: {
        perPage: 5,
        currentPage: 1,
      },

      total: 0,
      loading: false,

      task: {
        type: "tasks",
        name: null,
        description: null,
      },
      tasks: [],
      selectedRows: [],
    };
  },
  created() {
    // Load for 1st time all the Task
    this.getTasks();
  },
  watch: {
    pagination: {
      handler: "getTasks",
      immediate: false,
      deep: true,
    },
  },
  methods: {
    async handleSubmit() {
      try {
        // Send a request to the server in order to create a new task (db record)
        await this.$store.dispatch("tasks/add", this.task).then(() => {
          // Step 1 - Notify on successful save (green notification)
          this.$notify({
            type: "success",
            message: "Task added successfully.",
          });

          // Step 2 - Reload for 2nd - 3rd ... times the tasks list
          this.getTasks();

          // Step 3 - Hide the form
          this.toggle = true;
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },

    // display tasks on todo List
    async getTasks() {
      let params = {
        filter: {
          ...(this.user ? { user_id: this.user.id } : { user_id: 0 }),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };

      await this.$store.dispatch("tasks/list", params).then(() => {
        this.tasks = this.$store.getters["tasks/list"];
        this.tasks.forEach((task) => {
          task.status = task.status === 1;
          task.toDelete = false;
        });
        this.total = this.$store.getters["tasks/listTotal"];
        this.loading = false;
      });
    },

    async deleteTask(task) {
      // try {
      //   const confirmation = await swal.fire({
      //     title: `Delete this task?`,
      //     type: "question",
      //     buttonsStyling: false,
      //     showCancelButton: true,
      //     confirmButtonText: "Yes, delete it!",
      //     cancelButtonText: "No, keep it",
      //     confirmButtonClass: "btn btn-warning",
      //     cancelButtonClass: "btn btn-primary",
      //     allowOutsideClick: false,
      //     allowEscapeKey: false,
      //   });

      //   if (confirmation.value === true) {
      // send DELETE request to remove the Task
      this.loading = true;
      await this.$store.dispatch("tasks/destroy", task.id).then(
        () => this.getTasks() // fetch to refresh the remaining Tag list
      );
      this.$notify({
        type: "success",
        message: "Task deleted successfully.",
      });
      //  }
      //}
      // catch (error) {
      //   if (error.response.data.errors[0]) {
      //     this.$notify({
      //       type: "danger",
      //       message: "This Tag still has associated Items.",
      //     });
      //   } else {
      //     this.$notify({
      //       type: "danger",
      //       message: "Oops, something went wrong!",
      //     });
      //   }
      // }
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    updateStatus(val) {
      let temp = JSON.parse(JSON.stringify(val));
      temp.status = temp.status ? 1 : -1;
      this.$store.dispatch("tasks/update", temp);
    },
    toggleNewTask() {
      this.task.name = null;
      this.task.description = null;
      this.toggle = !this.toggle;
    },
    toggleToDelete(task, val) {
      this.tasks.forEach((tempTask) => {
        tempTask.toDelete = false;
      });
      //fire change detection by making change to a v-model value and then reset it back
      let temp = JSON.parse(JSON.stringify(task.status));
      task.status = undefined;
      task.status = temp;
      task.toDelete = val;
    },
  },
};
</script>
<style></style>
