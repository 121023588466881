<template>
    <div class="form-group">
      <slot name="label">
        <label v-if="label" :class="labelClasses">
          {{label}}
        </label>
      </slot>
       <vue-editor v-model="localValue" :id="id" :placeholder="placeholder" :editorToolbar="configs.toolbar" :disabled="disabled" class="overflow: auto; white-space: nowrap;"></vue-editor>
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor";

export default {
  name: 'BaseHtmlInput',
  components: {
     VueEditor,
  },
  props: {
    configs : {
      type: Object,
      default: () => {
        return {
          toolbar: [
            [], // Empty slot
            ['clean', { 'color': [] }, { 'background': [] } ], // Text formatting options and color and background color
            ['bold', 'italic', 'underline', 'strike'], // Text formatting options
            [{ 'align': [] }, { 'direction': 'rtl' }, ], // Right-to-left text direction
            ['link', 'blockquote', 'code-block'], // Blockquote and code block
            [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Ordered and unordered lists
            [{ 'script': 'sub' }, { 'script': 'super' }], // Subscript and superscript
            [], // Empty slot
            [{ 'font': [] }], // Font family
            [{ 'header': 1 }, { 'header': 2 }], // Heading options
            [{ 'size': ['small', 'medium',  'large', 'huge'] }], // Text size options
        ],
        };
      },
    },
    value: {
      type: String,
      default: ''
    },
    name: String,
    label: {
      type: String,
      description: "Input label (text before input)"
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
      default: "form-control-label"
    },
    placeholder: {
      type: String,
      default: 'Provide more details on what are you about to do. This rich-text (wysiwyg) description enables you to create a unique content for your audience...'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'html-editor'
    }
  },
  data() {
    return {
      localValue: ''
    };
  },
  mounted() {
    this.localValue = this.value; // Set initial value
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
    localValue(newValue) {
      this.$emit('input', newValue); // Emit the updated value
    }
  }
}

</script>
<style>
.tox-tinymce {
  border: 0.5px solid #dee2e6 !important;
  border-radius: 0.55rem !important;
}

.tox-statusbar__branding {
  visibility: hidden !important;
}
</style>
