<template>
  <div>
    <div class="container-fluid mt--6">
      <div class="help-container mb-1">
        <el-drawer
          title="I am the title"
          :visible.sync="drawer"
          :with-header="false"
        >
          <h4 class="mt-5 mb-3">Visual Guide:</h4>
          <vue-plyr>
            <div
              data-plyr-provider="youtube"
              data-plyr-embed-id="-B58GgsehKQ"
            />
          </vue-plyr>
          <hr />
          <h4>Quick Guide:</h4>
          <ul>
            <li>Follow BookMeInScotty on: <a href="#">Twitter</a>.</li>
            <li>
              Read and subscribe to our newsletter
              <a href="#">Book Me In Scotty Blog</a>.
            </li>
            <li>Follow Book Me In Scotty on <a href="#">Instagram</a>.</li>
            <li>
              Follow Me In Scotty on
              <a href="https://www.facebook.com/creativetim">Facebook</a>.
            </li>
          </ul>
          <hr />

          <h4>Help Center:</h4>
          <a href="#">Read the official documentation here</a>
        </el-drawer>
      </div>

      <div id="event-list-card">
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template v-slot:header v-if="!isPublic">
            <div class="row">
              <div class="col-6">
                <h3 class="mb-0">
                  My Collection List
                  <!--            <i class="fa fa-info-circle pointer-class" @click="drawer = true"/>-->
                </h3>

                <small class="text-muted">
                  Showing {{ to }} of {{ total }} results

                  <span v-if="selectedRows.length">
                    &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                  </span>
                </small>
              </div>

              <!-- <div class="col-6 text-right">
                    <base-button type="primary" icon size="sm" @click="addItem()">
                      <span class="btn-inner--icon"
                        ><i class="ni ni-fat-add"></i
                      ></span>
                      <span class="btn-inner--text">New Event</span>
                    </base-button>
                  </div> -->
            </div>
          </template>

          <template>
            <div id="event-list-table">
              <!-- Search and filtering -->
              <div class="row justify-content-center flex-wrap">
                <div class="col-md-11 ml-2 mr-2 mt-3">
                  <base-input
                    v-model="query"
                    type="search"
                    prepend-icon="fas fa-search"
                    placeholder="Type an 'event name to search..."
                    clearable
                    :class="{
                      'filter-opened': showFilter,
                    }"
                  >
                    <small slot="append" class="font-weight-bold">
                      <div @click="showFilter = !showFilter">
                        <span class="filter-icon">
                          <i class="fa fa-filter" />
                        </span>
                      </div>
                    </small>
                  </base-input>
                </div>

                <div
                  class="col-md-10 filters-container mb-5"
                  v-show="showFilter"
                >
                  <div class="row">
                    <div class="col-md-3 offset-md-1">
                      <base-input label="Filter by Month">
                        <flat-picker
                          slot-scope="{ blur }"
                          @on-open="customFocus(0)"
                          @on-close="blur"
                          :config="{
                            allowInput: true,
                            altInput: true,
                            plugins: plugins,
                          }"
                          class="form-control custom-form-radius-left datepicker"
                          placeholder="Click to open"
                          v-model="month"
                        >
                        </flat-picker>
                        <small slot="append" class="font-weight-bold">
                          <div @click="month = null">
                            <span style="color: #adb5bd; cursor: pointer">
                              <i class="fa fa-times-circle" />
                            </span></div
                        ></small>
                      </base-input>
                    </div>

                    <div class="col-md-3">
                      <base-input label="Filter by Type">
                        <el-select
                          v-model="eventType"
                          filterable
                          clearable
                          prepend-icon="fas fa-user"
                          placeholder="Select..."
                        >
                          <el-option
                            v-for="eventType in allEventTypes"
                            :key="eventType"
                            :label="eventType"
                            :value="eventType"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="col-md-3">
                      <base-input label="When is Happening">
                        <el-select
                          v-model="eventStatus"
                          filterable
                          clearable
                          prepend-icon="fas fa-user"
                          placeholder="Select..."
                        >
                          <el-option
                            v-for="eventStatus in allEventStatus"
                            :key="eventStatus"
                            :label="eventStatus"
                            :value="eventStatus"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="col-md-2 text-right filter-button">
                      <base-button
                        type="primary"
                        size="md"
                        class="btn-base btn-icon"
                        @click="fetchList()"
                      >
                        <span class="btn-inner--text">Filter</span>
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Load Event Items -->
              <div v-if="items" class="container align-content-between">
                <div class="row">
                  <div
                    v-for="eventItem in items"
                    :key="eventItem.id"
                    class="col-md-4"
                    @click="getEventModal(eventItem)"
                  >
                    <!-- Image-Text Card -->
                    <div class="card">
                      <!-- Card Header Image -->
                      <div class="event-item">
                        <el-image
                          class="card-img-top"
                          :src="getHeroImage(eventItem.hero_image)"
                          :alt="'image-' + eventItem.name"
                          fit="cover"
                          lazy
                        >
                          <div slot="placeholder" class="image-slot">
                            <el-skeleton :rows="1" animated>
                              <template slot="template">
                                <div>
                                  <el-skeleton-item
                                    variant="image"
                                    style="height: 200px"
                                    class="mb-1"
                                  />
                                </div>
                              </template>
                            </el-skeleton>
                          </div>
                        </el-image>
                        <span
                          class="badge badge-lg badge-success floating-badge-left"
                        >
                          {{ getEventFullStatusTypes(eventItem.event_type) }}
                        </span>
                        <span
                          class="badge badge-lg badge-base floating-badge-right"
                        >
                          {{
                            localTimeShorthand(
                              eventItem.start_at,
                              eventItem.timezone
                            ).dateTime
                          }}
                          <br />
                          {{
                            localTimeShorthand(
                              eventItem.start_at,
                              eventItem.timezone
                            ).monthTime
                          }}
                        </span>
                      </div>
                      <!-- Card Main Body -->
                      <div class="card-body">
                        <h5 class="h3 card-title text-break mb-0">
                          {{ eventItem.name }}
                        </h5>
                        <hr class="mt-2 mb-3" />

                        <h5 class="text-muted mt-2 ml--2">
                          <badge
                            type="base"
                            size="lg"
                            class="mr-2"
                            style="
                              border-radius: calc(0.75rem - 1px)
                                calc(0.75rem - 1px) calc(0.75rem - 1px) 0;
                            "
                          >
                            <i class="fas fa-clock mr-2" />When
                          </badge>
                          {{ eventItem.start_at | humanFullTime }}
                        </h5>

                        <h5
                          v-if="
                            eventItem.venues &&
                            (eventItem.event_type !== 'tba' ||
                              eventItem.event_type !== 'virtual')
                          "
                          class="card-text text-muted text-hide-long mt-2 ml--2"
                        >
                          <badge
                            type="base"
                            size="lg"
                            class="mr-2"
                            style="
                              border-radius: 0 calc(0.75rem - 1px)
                                calc(0.75rem - 1px) 0;
                            "
                          >
                            <i class="fa fa-map-marker mr-2" />Where
                          </badge>
                          {{ eventItem.venues.address_name }}
                        </h5>

                        <h5 v-else class="card-text text-muted mt-2 ml--2">
                          <badge
                            type="base"
                            size="lg"
                            class="mr-1"
                            style="
                              border-radius: 0 calc(0.75rem - 1px)
                                calc(0.75rem - 1px) 0;
                            "
                          >
                            <i class="fa fa-map-marker mr-2" />Where
                          </badge>
                          {{ getEventFullStatusTypes(eventItem.event_type) }}
                        </h5>

                        <h5 class="card-text text-muted mt-2 ml--2">
                          <badge
                            type="base"
                            size="lg"
                            class="mr-2"
                            style="
                              border-radius: 0 calc(0.75rem - 1px)
                                calc(0.75rem - 1px) calc(0.75rem - 1px);
                            "
                          >
                            <i class="fa fa-headset mr-2" />Who
                            <span class="ml-2" />
                          </badge>
                          {{ eventItem.organizer }}
                        </h5>
                      </div>

                      <div class="card-footer text-center pt-2 pb-1">
                        <el-tooltip content="Preview event" placement="top">
                          <a>
                            <router-link
                              :to="{
                                name: 'Event Preview',
                                params: { id: eventItem.id },
                              }"
                              class="table-action"
                              style="cursor: pointer"
                            >
                              <a href="#!" class="btn btn-link px-0">
                                <i class="far fa-eye text-base"></i> View
                              </a>
                            </router-link>
                          </a>
                        </el-tooltip>

                        <span class="vertical-divider mr-2 ml-2"></span>

                        <el-tooltip content="Edit event" placement="top">
                          <a href="#!" class="btn btn-link px-0">
                            <router-link
                              :to="{
                                name: 'Edit Event',
                                params: { id: eventItem.id },
                              }"
                              class="table-action"
                              style="cursor: pointer"
                            >
                              <a href="#!" class="btn btn-link px-0">
                                <i class="far fa-edit text-base"></i> Edit
                              </a>
                            </router-link>
                          </a>
                        </el-tooltip>

                        <span class="vertical-divider mr-3 ml-2"></span>

                        <a href="#!" class="btn btn-link px-0">
                          <i class="fas fa-ellipsis-v"></i> More
                        </a>
                      </div>
                    </div>
                    <!-- ./ Image-Text Card -->
                  </div>
                </div>
              </div>

              <!-- Load Spinner -->
              <div class="text-center" slot="empty" v-if="loading">
                <div class="row justify-content-center flex-wrap">
                  <img
                    src="/img/loading.gif"
                    style="height: 100px; width: 100px"
                  />
                </div>
              </div>
            </div>
          </template>

          <template v-slot:footer>
            <div
              id="event-list-footer"
              class="row d-flex justify-content-center flex-wrap"
              :class="{
                'justify-content-md-between': !isPublic,
              }"
            >
              <div class="col-auto" v-if="!isPublic">
                <el-select
                  class="select-primary pagination-select mb-1"
                  style="width: 140px"
                  v-model="pagination.perPage"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary mt-1"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="col-auto">
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                />
              </div>
            </div>
          </template>
        </card>
      </div>
      <!-- Event Details Modal -->
      <modal
        :show.sync="showEditModal"
        modal-classes="modal-secondary"
        :header-classes="'calendar-modal-header public'"
        size="lg"
      >
        <template slot="header">
          <h2 class="mt-2">Happening details</h2>
        </template>

        <div class="mt--5 mb-3">
          <el-image
            :src="getHeroImage(model.hero_image)"
            class="d-block shadow-1-strong rounded w-100"
            alt="1"
            @load="heroImageLoaded = true"
          >
            <div slot="placeholder" class="image-slot">
              <el-skeleton :rows="6" animated>
                <template slot="template">
                  <div style="padding: 14px">
                    <el-skeleton-item
                      variant="image"
                      style="width: 100%; height: 480px"
                    /><br />
                  </div>
                </template>
              </el-skeleton>
            </div>
          </el-image>
        </div>
        <template slot="footer" v-if="!isPublic">
          <base-button size="sm" type="primary" @click="redirectToEvent()">
            <i class="far fa-edit mr-2" />Edit event
          </base-button>
        </template>

        <template slot="footer" v-if="isPublic">
          <base-button
            size="sm"
            type="primary"
            @click="redirectToLink(model.virtual_link)"
            v-if="model.virtual_link"
          >
            <i class="fa fa-satellite-dish" /> Virtual link
          </base-button>
          <base-button
            size="sm"
            type="primary"
            @click="redirectToLink(model.event_link)"
          >
            <i class="fa fa-eye" /> Visit event
          </base-button>
        </template>

        <div class="calendar-modal-section">
          <h3 class="text-muted text-sm font-weight-bold">Event</h3>
          <h3 class="mb-0 ml-2">{{ model.name }}</h3>
        </div>

        <div class="calendar-modal-section">
          <h3 class="text-muted text-sm font-weight-bold">Description</h3>
          <span class="mb-0 ml-2"
            >{{ model.description }}
            <i
              v-if="
                isPublic &&
                (model.event_type === 'hybrid' ||
                  model.event_type === 'virtual')
              "
              class="fa fa-satellite-dish"
            />
          </span>
        </div>

        <div class="calendar-modal-section" v-if="!isPublic">
          <div class="d-flex justify-content-between pt-1">
            <h3 class="text-muted text-sm font-weight-bold">
              When
              <small class="text-muted">
                <el-tooltip
                  class="item"
                  style="border-radius: 0"
                  effect="light"
                  :content="localTimezone()"
                  placement="top"
                >
                  <i class="fa fa-info-circle" />
                </el-tooltip>
              </small>
            </h3>
            <div class="text-right">
              <small class="text-muted">
                <i class="fas fa-clock mr-1" />
                {{ this.displayModalDateFromNow(model.start_at) }}
              </small>
            </div>
          </div>
          <div class="ml-2">
            {{ localTime(model.start_at, model.timezone) }}
          </div>
        </div>

        <div v-if="isPublic" class="calendar-modal-section">
          <div class="d-flex justify-content-between pt-1">
            <h3 class="text-muted text-sm font-weight-bold">
              When
              <small class="text-muted">
                <el-tooltip
                  class="item"
                  style="border-radius: 0"
                  effect="light"
                  :content="localTimezone()"
                  placement="top"
                >
                  <i class="fa fa-info-circle" />
                </el-tooltip>
              </small>
            </h3>
            <div class="text-right">
              <small class="text-muted">
                <i class="fas fa-clock mr-1" />
                {{ this.displayModalDateFromNow(model.start_at) }}
              </small>
            </div>
          </div>
          <div class="ml-3">
            {{ localTime(model.start_at, model.timezone) }}
          </div>
        </div>

        <div v-else class="calendar-modal-section divider">
          <h3 class="text-muted text-sm font-weight-bold">Category:</h3>
          <div class="badge badge-primary badge-pill ml-3">
            {{ model.category.name }}
          </div>
        </div>

        <div class="calendar-modal-section" v-if="!isPublic">
          <h3 class="text-muted text-sm font-weight-bold">Tags</h3>
          <div
            v-for="(tag, index) in model.tags"
            :key="index"
            class="badge badge-default ml-3"
            :style="{ backgroundColor: tag.color }"
          >
            {{ tag.name }}
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Drawer,
  Skeleton,
  SkeletonItem,
  Image,
} from "element-ui";
import { BasePagination } from "@/components/";
import flatPicker from "vue-flatpickr-component";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
require("flatpickr/dist/plugins/monthSelect/style.css");
require("flatpickr/dist/flatpickr.css");
import swal from "sweetalert2";
import moment from "moment";
import _ from "lodash";
import { BackButton } from "@/components";

export default {
  name: "base-collections",
  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Drawer.name]: Drawer,
    [Skeleton.name]: Skeleton,
    [SkeletonItem.name]: SkeletonItem,
    [Image.name]: Image,
    ["flatPicker"]: flatPicker,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      plugins: [
        new monthSelectPlugin({
          // shorthand: true, //defaults to false
          // dateFormat: "F Y", //defaults to "F Y"
          // altFormat: "F Y", //defaults to "F Y"
          // theme: "light" // defaults to "light"
        }),
      ],
      showFilter: false,
      drawer: false,
      query: null,
      filter: {
        pastItems: false,
        upcomingItems: false,
      },
      selectedRows: [],
      sort: "-start_at",
      total: 0,
      pagination: {
        perPage: 9,
        currentPage: 1,
        perPageOptions: [9, 12, 20, 40],
      },
      dropdown: {
        hideOnClick: true,
        placement: "bottom-end",
      },
      items: [],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      loading: true,
      lodash_: _,
      allEventTypes: ["physical", "virtual", "hybrid", "tba"],
      allEventStatus: ["All Events", "Upcoming Events"],
      eventType: null,
      eventStatus: "Upcoming Events",
      month: null,
      showEditModal: false,
      model: {
        id: "",
        hash_id: "",
        name: "",
        className: "bg-default",
        description: "",
        hero_image: "",
        event_type: "",
        start_at: "",
        end_at: "",
        tags: [],
        category: {},
        timezone: "",
        virtual_link: null,
        event_link: "",
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters["profile/me"];
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    query: {
      handler: "fetchListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "fetchList",
      immediate: false,
      deep: true,
    },
  },
  methods: {
    redirectToPreview() {
      this.showPreviewModal = false;
      this.$router.push({
        name: "Event Preview",
        params: { id: this.item.id },
      });
    },
    redirectToEvent() {
      this.showEditModal = false;
      this.$router.push({ name: "Edit Event", params: { id: this.model.id } });
    },

    fetchListDebounced: _.debounce(function () {
      this.fetchList();
    }, 300),
    async fetchList() {
      let start_at;
      let end_at;
      if (this.month) {
        let tempDate = this.getDateFormat(this.month);
        start_at = moment(tempDate)
          .startOf("month")
          .format("YYYY-MM-DD 00:00:00");
        end_at = moment(tempDate).endOf("month").format("YYYY-MM-DD 23:59:59");
      }
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.user ? { user_id: this.user.id } : { user_id: 0 }),
            ...(this.query ? { name: this.query } : {}),
            ...(this.eventStatus === "Upcoming Events"
              ? { upcoming_at: 1 }
              : {}),
            ...(start_at && end_at
              ? { start_at: [start_at, end_at] }
              : { start_at: [] }),
            ...(this.eventType ? { event_type: this.eventType } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          uuid: this.isPublic ? this.$route.params.id : undefined,
          include: "category,tags,venues",
        };

        // fire a GET request to load the items
        await this.$store
          .dispatch(this.isPublic ? "publicItems/list" : "items/list", params)
          .then(() => {
            this.items =
              this.$store.getters[
                this.isPublic ? "publicItems/list" : "items/list"
              ];
            this.total =
              this.$store.getters[
                this.isPublic ? "publicItems/listTotal" : "items/listTotal"
              ];
          });

        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
    getDateFormat(month) {
      let tempMonth = month.substring(0, month.length - 4);
      let tempYear = month.substring(month.length - 4, month.length);
      return new Date(Date.parse(tempMonth + "1, " + tempYear));
    },
    getHeroImage(hero) {
      if (hero.includes("http://") || hero.includes("https://")) {
        return hero;
      }
      // return cloud url
      return process.env.VUE_APP_AWS_S3_URL + hero;
    },
    eventPublicURL(item) {
      const itemId = _.isEmpty(item.hash_code) ? item.id : item.hash_code;
      return process.env.VUE_APP_BASE_URL + "/event/" + itemId;
    },
    copyPublicLink(item) {
      try {
        swal.fire({
          title: "Public link - Copied",
          html:
            "You can use this link to share it with a wide group of people, post it on your Social Media or Spread the Word<br></hr> " +
            "<br><b>Public Event Link:</b><br> <button type='button' class='btn-icon-clipboard text-center'>" +
            this.eventPublicURL(item) +
            "</button> <br></hr>",
          type: "success",
          confirmButtonClass: "btn btn-outline-danger btn-fill",
          confirmButtonText: " Close",
          buttonsStyling: false,
          icon: "success",
        });
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    getEventModal(event) {
      let description = event?.description;
      if (this.isPublic) {
        if (event?.event_type === "virtual" || event?.event_type === "hybrid") {
          description = "This event is available online ";
        } else if (event?.event_type === "physical") {
          //to change with event address
          description = event?.venues?.address_name;
        } else {
          description = "To-Be Announced Soon!";
        }
      }
      this.model = {
        id: event.id,
        name: event.name,
        className: event.classNames ? event.classNames.join(" ") : "",
        start_at: event.start_at,
        end_at: event.end_at,
        description: description,
        hero_image: event?.hero_image,
        virtual_link: event?.virtual_link,
        event_type: event?.event_type ? event.event_type : "",
        event_link:
          process.env.VUE_APP_BASE_URL +
          "/event/" +
          (event?.hash_code ? event?.hash_code : event.id),
        tags: event?.tags ? event.tags : [],
        category: event?.category ? event.category : {},
        timezone: event?.timezone ? event.timezone : {},
      };
      this.showEditModal = true;
    },
    localTimezone() {
      const tz = this.timezone;
      return (
        "The times for the events are shown in your own local timezone (" +
        tz +
        ")"
      );
    },
    displayModalDateFromNow(date) {
      return moment(date).fromNow();
    },
    localTime(time, timezone) {
      const originalTime = moment.tz(
        moment(time).format("YYYY-MM-DD hh:mm:ss"),
        timezone
      );

      return (
        originalTime.clone().tz(this.timezone).format("dddd, D MMM YYYY") +
        " at " +
        originalTime.clone().tz(this.timezone).format("h:mm A")
      );
    },
    localTimeShorthand(time, timezone) {
      const originalTime = moment.tz(
        moment(time).format("YYYY-MM-DD hh:mm:ss"),
        timezone
      );

      const localDateTime = originalTime.clone().tz(this.timezone);

      return {
        fullTime: localDateTime.format("ddd Do MMM"),
        dateTime: localDateTime.format("Do"),
        dayTime: localDateTime.format("ddd"),
        monthTime: localDateTime.format("MMM"),
      };
    },
    redirectToPreview() {
      this.showEditModal = false;
      setTimeout(() => {
        this.$router.push({
          name: "Event Preview",
          params: { id: this.model.id },
        });
      });
    },
    redirectToEvent() {
      this.showEditModal = false;
      setTimeout(() => {
        this.$router.push({
          name: "Edit Event",
          params: { id: this.model.id },
        });
      });
    },
    redirectToLink(link) {
      window.open(link, "_blank");
    },
    customFocus(index) {
      setTimeout(function () {
        document.getElementsByClassName("flatpickr-calendar")[index].focus();
      }, 10);
    },
  },
};
</script>
<style>
.filters-container {
  width: 85%;
  padding: 15px;
  border: 1px solid #dee2e6;
  border-radius: 0.55rem;
  justify-content: space-between;
}

.datepicker {
  cursor: pointer !important;
}

.filter-opened .input-group-append .input-group-text {
  background-color: #5e72e4;
}

.filter-icon {
  color: #adb5bd;
  cursor: pointer;
}
.filter-opened .filter-icon {
  color: white;
}

.event-item {
  margin-bottom: -15px;
}

.floating-badge-left {
  position: absolute;
  right: 0px;
  top: 8px;
  border-radius: 0.75rem 0 0 0.75rem;
  border-top-left-radius: calc(0.75rem - 1px);
}

.floating-badge-right {
  position: absolute;
  left: 8px;
  top: 8px;
  border-radius: 0 0.75rem 0.75rem 0;
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.text-hide-long {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 320px;
  white-space: nowrap;
}
</style>
