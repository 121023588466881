<template>
  <div>
    <div v-if="isDeveloper()" class="d-flex justify-content-end">
      <base-button
        size="sm"
        type="primary"
        @click="copyPublicLink()"
        class="mt-2"
      >
        <i class="fa fa-link" />Public Survey Link
      </base-button>
    </div>
    <div v-if="loading" class="row justify-content-center mt-2">
      <div slot="empty">
        <img src="/img/loading.gif" style="height: 100px; width: 100px" />
      </div>
    </div>
    <survey v-if="survey" :survey="survey" />
  </div>
</template>
<script>
import { Survey } from "survey-vue-ui";
import * as SurveyVue from "survey-vue-ui";
import "survey-core/defaultV2.min.css";
import swal from "sweetalert2";

export default {
  name: "base-survey-component",
  components: {
    Survey,
    survey: SurveyVue.Survey,
  },
  props: {
    surveyId: {
      type: String,
      default: null,
    },
    submitSurvey: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      survey: null,
      loading: false,
      surveyUUID: null,
    };
  },
  created() {
    this.get();
  },
  computed: {
    surveyPublicURL() {
      return process.env.VUE_APP_BASE_URL + "/survey/" + this.surveyUUID;
    },
  },
  methods: {
    async get() {
      var surveyRS;
      try {
        this.loading = true;
        const id = this.surveyId;
        let params = {
          filter: {
            ...{ user_id: 0 },
          },
        };
        await this.$store
          .dispatch("surveys/get", { id, params })
          .then(() => (surveyRS = this.$store.getters["surveys/survey"]));
        this.surveyUUID = surveyRS.uuid;
        this.survey = new SurveyVue.Model(surveyRS.payload);
        this.survey.onComplete.add(this.surveyComplete);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
      }
    },
    async surveyComplete(sender) {
      console.log(sender.data);
      if (this.submitSurvey) {
        const surveyData = {
          data: sender.data,
          id: this.$route.params.id,
          type: "surveys",
        };
        await this.$store.dispatch("surveys/save", surveyData).then(() => {
          this.$notify({
            type: "success",
            message: "Survey results sent successfully.",
          });
        });
      }
      // else case would be to emit data to parent component for it to handle
    },

    copyPublicLink() {
      try {
        navigator.clipboard.writeText(this.surveyPublicURL);
        swal.fire({
          title: "Copied - Public Survey Link",
          html:
            "You can use this link to share your survey with a wide group of people.<br></hr> " +
            "<br><b>Public Survey Link:</b><br> <button type='button' class='btn-icon-clipboard text-center'>" +
            this.surveyPublicURL +
            "</button> <br></hr>",
          type: "success",
          confirmButtonClass: "btn btn-outline-danger btn-fill",
          confirmButtonText: " Close",
          buttonsStyling: false,
          icon: "success",
        });
      } catch ($e) {
        alert("Cannot copy");
      }
    },
  },
};
</script>
<style></style>
