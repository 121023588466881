<template>
  <div class="container-fluid">
    <div v-if="!onlyBookButton">

      <!-- Sticky CTA Booking Buttons -->
      <div
        id="sticky-buttons-top"
        class="row justify-content-center"
        v-if="hasBookButton"
      >
        <div class="col-md-10 mb--3">
          <card id="cta" type="frame">
            <div class="row align-content-end mt--4 mb--3">

              <div class="col-md-4 mb-2">
                <h6 class="surtitle text-muted mt-3">
                  <i class="fas fa-shopping-basket mr-1"/>
                  <span
                    v-if="item.custom_book_button_label && item.book_promotion_label"
                    >{{ item.book_promotion_label }}
                  </span>
                  <span v-else>
                    Book your ticket now with
                  </span>
                </h6>
              </div>

              <div class="col-md-4 offset-md-4">
                <book-button-component
                  :item="item"
                  :modals="modals"
                  :hideEndOfSalesDate="true"
                  @addTicket="addTicket($event)"
                ></book-button-component>
              </div>
            </div>
          </card>
        </div>
      </div>

      <!-- Hero Section -->
      <div class="row justify-content-center mt-1">
        <!-- Announcement High-Importance Section -->
        <div class="col-md-10">
          <card
            v-if="
              item?.show_latest_announcement &&
              announcements?.importance_type === 'high' &&
              announcements?.message.length > 0
            "
            style="
              border-style: solid;
              border-color: #5e72e4;
              border-width: 2px;
            "
          >
            <div class="row align-items-center">
              <div class="col-auto ml-2">
                <span class="h6 surtitle text-muted">
                  <i class="ni ni-notification-70" />
                  Announcement
                </span>
              </div>
              <div class="col text-right">
                <small class="text-muted">
                  <i class="fas fa-clock mr-1" />
                  {{ displayAnnouncementTime(announcements.updated_at) }}
                </small>
              </div>
            </div>
            <div class="col-auto ml-2">
              <h3>{{ announcements.message }}</h3>
            </div>
          </card>
        </div>

        <div class="col-md-10 carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <el-image
                :src="getHeroImage()"
                class="d-block shadow-1-strong rounded w-100"
                alt="1"
                @load="heroImageLoaded = true"
              >
                <div slot="placeholder" class="image-slot">
                  <el-skeleton :rows="6" animated>
                    <template slot="template">
                      <div style="padding: 14px">
                        <el-skeleton-item
                          variant="image"
                          style="width: 100%; height: 480px"
                        /><br />
                      </div>
                    </template>
                  </el-skeleton>
                </div>
              </el-image>

              <div
                style="padding-bottom: 0px; border-radius: 1em"
                class="carousel-caption bg-gradient-primary d-none d-md-block mb-5"
                v-if="heroImageLoaded"
              >
                <h5 v-if="eventTotalDuration < 1" class="text-white">
                  <i class="fa fa-calendar-day" />
                  {{ eventStartDateTimeShort }} @ {{ localStartTime }} -
                  {{ localEndTime }}
                </h5>

                <h5 v-else class="text-white">
                  <i class="fa fa-calendar-day" />
                  {{ eventStartDateTimeShort }} to {{ eventEndDateTimeShort }} @
                  {{ localStartTime }} -
                  {{ localEndTime }}
                </h5>

                <h1 class="display-2 text-white" style="color: #e8b44f">
                  {{ item.name }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Event Details -->
      <div
        id="event-details"
        class="row justify-content-center mt-4"
        style="margin-top: -40px !important; padding-bottom: 10px !important"
      >
        <!-- Social share and Happening section-->
        <div class="col-md-4 order-md-0 order-1">
          <div class="card">
            <!-- Card body -->
            <div class="card-body">
              <!-- Event Share -->
              <h3
                class="col-md-10 text-left text-base"
                style="display: inline"
                v-if="tags"
              >
                <i class="fas fa-share mr-2" />Share with audience

                <!-- Card body -->
                <div class="row align-content-around mb--5">
                  <div class="col-md-12 mb-1">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Copy this Event's public link"
                      placement="bottom"
                    >
                      <base-button
                        round
                        @click="copyURL()"
                        class="btn-base btn-icon-only pb-1 pt-1 mt-2 mb-2"
                      >
                        <span class="btn-inner--icon"
                          ><i class="fa fa-link"
                        /></span>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Share this Event via e-mail"
                      placement="bottom"
                    >
                      <a
                        :href="
                          'mailto:' +
                          '' +
                          '?subject=' +
                          '[Event] ' +
                          item.name +
                          ' (by Book Me In Scotty)' +
                          '&body=' +
                          '%0D%0A' +
                          'Hey,' +
                          '%0D%0A' +
                          'I found this amazing event - visit here for more: ' +
                          eventPublicURL +
                          '%0D%0A' +
                          virtualLinkMessage +
                          'Short description:' +
                          '%0D%0A' +
                          eventShortDescription +
                          '%0D%0A' +
                          '%0D%0A' +
                          'Event Tags: ' +
                          getHashtagsForEmail()
                        "
                      >
                        <base-button round class="btn btn-icon-only bg-danger">
                          <span class="btn-inner--icon">
                            <i class="fa fa-envelope" aria-hidden="true" />
                          </span>
                        </base-button>
                      </a>
                    </el-tooltip>

                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Save this Event in your Calendar"
                      placement="bottom"
                    >
                      <base-button
                        round
                        @click="getCalendarFile()"
                        class="btn btn-icon-only bg-info ml-2"
                      >
                        <span class="btn-inner--icon"
                          ><i class="fa fa-calendar-plus"
                        /></span>
                      </base-button>
                    </el-tooltip>

                    <share-network
                      network="twitter"
                      :url="eventPublicURL"
                      :title="item.name"
                      :description="item.description"
                      :quote="item.name"
                      :hashtags="getHashtags()"
                      class="mr-2"
                    >
                      <base-button
                        class="btn-icon-only rounded-circle bg-dark pt-1"
                      >
                        <span class="btn-inner--icon"
                          ><font-awesome-icon icon="fab fa-x-twitter" />
                        </span>
                      </base-button>
                    </share-network>

                    <share-network
                      network="facebook"
                      :url="eventPublicURL"
                      :title="item.name"
                      :description="item.description"
                      image="https://www.uniconexed.org/wp-content/uploads/canstockphoto21211673-online-event-770x662.jpg"
                      :quote="item.name"
                      :hashtags="getHashtags()"
                      class="mr-2"
                    >
                      <base-button
                        type="facebook"
                        class="btn-icon-only rounded-circle"
                      >
                        <span class="btn-inner--icon"
                          ><i class="fab fa-facebook"></i
                        ></span>
                      </base-button>
                    </share-network>

                    <share-network
                      network="linkedin"
                      :url="eventPublicURL"
                      :title="item.name"
                      :description="item.description"
                      :quote="item.name"
                      :hashtags="getHashtags()"
                      class="mr-2"
                    >
                      <base-button
                        type="linkedin"
                        class="btn-icon-only rounded-circle"
                      >
                        <span class="btn-inner--icon"
                          ><i class="fab fa-linkedin"></i
                        ></span>
                      </base-button>
                    </share-network>

                    <share-network
                      network="whatsapp"
                      :url="eventPublicURL"
                      :title="item.name"
                      :description="item.description"
                      :quote="item.name"
                      class="mr-2"
                    >
                      <base-button
                        type="whatsapp"
                        class="btn-icon-only rounded-circle bg-success"
                      >
                        <span class="btn-inner--icon"
                          ><i class="fab fa-whatsapp text-white" />
                        </span>
                      </base-button>
                    </share-network>

                    <share-network
                      network="telegram"
                      :url="eventPublicURL"
                      :title="item.name"
                      :description="item.description"
                      :quote="item.name"
                      class="mr-2"
                    >
                      <base-button
                        type="telegram"
                        class="btn-icon-only rounded-circle"
                      >
                        <span class="btn-inner--icon"
                          ><i class="fab fa-telegram text-white" />
                        </span>
                      </base-button>
                    </share-network>
                  </div>
                </div>
              </h3>

              <hr class="mb-3" />

              <!-- Happening's Location & Dates Section-->
              <div class="d-flex justify-content-between pt-1">
                <div>
                  <span class="h6 surtitle text-muted">
                    <i class="fas fa-clock" /> WHEN
                  </span>
                </div>

                <div class="text-right" v-if="item.event_type !== 'hybrid'">
                  <small class="text-muted">
                    <el-tooltip
                      class="item"
                      style="border-radius: 0"
                      effect="light"
                      :content="
                        item.event_type === 'physical'
                          ? originalTimezone
                          : localTimezone
                      "
                      placement="top"
                    >
                      <i class="fa fa-info-circle" />
                    </el-tooltip>
                  </small>
                </div>
              </div>

              <template
                v-if="
                  item.event_type === 'tba' || item.event_type === 'virtual'
                "
              >
                <h2 v-if="eventTotalDuration < 1">{{ eventStartDateTime }}</h2>
                <h2 v-else>
                  {{ eventStartDateTime }} <small>to</small>
                  {{ eventEndDateTime }}
                </h2>

                <p class="card-text mt-4" style="margin-top: -5px !important">
                  <small>starts at </small>
                  <strong class="text-underline"> {{ localStartTime }} </strong>
                  <small>until </small>
                  <strong class="text-underline"> {{ localEndTime }}</strong>
                  <!--                  <small v-if="eventTotalDuration >= 1">-->
                  &nbsp;
                  <!--                    <badge type="base" tag="a" size="md">-->
                  <!--                      duration {{ eventTotalDuration }} days-->
                  <!--                    </badge>-->
                  <!--                  </small>-->
                </p>
              </template>

              <template v-else-if="item.event_type === 'physical'">
                <h2 v-if="eventTotalDuration < 1">{{ eventStartDateTime }}</h2>
                <h2 v-else>
                  {{ eventStartDateTime }} <small>to</small>
                  {{ eventEndDateTime }}
                </h2>

                <p class="card-text mt-4" style="margin-top: -5px !important">
                  <small>starts at </small>
                  <strong class="text-underline"> {{ eventStartTime }} </strong>
                  <small>until </small>
                  <strong class="text-underline"> {{ eventEndTime }}</strong>
                  <!--                  <small v-if="eventTotalDuration >= 1">-->
                  <!--                    &nbsp;-->
                  <!--                    <badge type="base" tag="a" size="md">-->
                  <!--                      duration {{ eventTotalDuration }} days-->
                  <!--                    </badge>-->
                  <!--                  </small>-->
                </p>
              </template>

              <template v-else-if="item.event_type === 'hybrid'">
                <template>
                  <small class="text-muted text-base ml-2">
                    {{
                      isHybridSameTimezone
                        ? "In-Person and Virtual"
                        : "In-Person"
                    }}
                    Venue Time
                    <el-tooltip
                      class="item ml-2"
                      style="border-radius: 0"
                      effect="light"
                      :content="originalTimezone"
                      placement="top"
                    >
                      <i class="fa fa-info-circle" />
                    </el-tooltip>
                  </small>

                  <h2 v-if="eventTotalDuration < 1">
                    {{ eventStartDateTime }}
                  </h2>
                  <h2 v-else>
                    {{ eventStartDateTime }} <small>to</small>
                    {{ eventEndDateTime }}
                  </h2>

                  <p class="card-text mt-4" style="margin-top: -5px !important">
                    <small>starts at </small>
                    <strong class="text-underline"
                      >{{ eventStartTime }}
                    </strong>
                    <small>until </small>
                    <strong class="text-underline">{{ eventEndTime }}</strong>
                    <small v-if="eventTotalDuration >= 1">
                      (+{{ eventTotalDuration }} days)
                    </small>
                  </p>
                </template>

                <template v-if="!isHybridSameTimezone">
                  <small class="text-muted text-base ml-2">
                    Virtual Local Time
                    <el-tooltip
                      class="item"
                      style="border-radius: 0"
                      effect="light"
                      :content="localTimezone"
                      placement="top"
                    >
                      <i class="fa fa-info-circle ml-2" />
                    </el-tooltip>
                  </small>
                  <h2 v-if="eventTotalDuration < 1">
                    {{ eventStartDateTime }}
                  </h2>
                  <h2 v-else>
                    {{ eventStartDateTime }} <small>to</small>
                    {{ eventEndDateTime }}
                  </h2>
                  <p class="card-text mt-4" style="margin-top: -5px !important">
                    <small>starts at </small>
                    <strong class="text-underline"
                      >{{ localStartTime }}
                    </strong>
                    <small>until </small>
                    <strong class="text-underline">{{ localEndTime }}</strong>
                    <small v-if="eventTotalDuration >= 1">
                      (+{{ eventTotalDuration }} days)
                    </small>
                  </p>
                </template>
              </template>

              <div class="row">
                <div class="col">
                  <span class="h6 surtitle text-muted"
                    ><i class="ni ni-pin-3"></i>
                    WHERE
                  </span>
                  <div v-if="item.event_type === 'physical'">
                    <span class="d-block h3">{{ venue.address_name }}</span>
                  </div>
                  <div
                    v-else-if="
                      item.event_type === 'hybrid' ||
                      item.event_type === 'virtual'
                    "
                  >
                    <span class="d-block h3">{{ venue.address_name }}</span>
                    <base-alert type="secondary" class="ml--2">
                      <p class="text-muted text-wrap mt--4 mb--2">
                        This event is
                        {{ item.event_type === "hybrid" ? "also" : "only" }}
                        available online
                        <i class="fa fa-satellite-dish" />
                      </p>
                    </base-alert>
                  </div>
                  <div v-else-if="item.event_type === 'tba'">
                    <h4>To-Be Announced Soon!</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Organizer's Card-->
          <card>
            <div class="row align-items-center">
              <div class="col ml-2">
                <span class="h6 surtitle text-muted"
                  ><i class="fa fa-headset"></i>
                  ORGANIZER
                </span>
                <span class="d-block h3">{{ item.organizer }}</span>
              </div>

              <template>
                <div class="col-auto mt-1">
                  <div
                    class="icon icon-shape bg-gradient-base text-white rounded-circle shadow"
                  >
                    <img
                      v-if="organizerPicture !== null"
                      class="avatar avatar-md rounded-circle bg-gradient-primary"
                      style="height: 42px; width: 42px"
                      :src="organizerPicture"
                    />
                    <i v-else class="fa fa-shapes" />
                  </div>
                </div>
              </template>
            </div>
          </card>

          <!--Latest Announcement-->
          <card
            v-if="
              item?.show_latest_announcement &&
              announcements?.importance_type === 'normal' &&
              announcements?.message.length > 0
            "
            style="
              border-style: solid;
              border-color: #5e72e4;
              border-width: 2px;
            "
          >
            <div class="row align-items-center">
              <div class="col-auto ml-2">
                <span class="h6 surtitle text-muted">
                  <i class="ni ni-notification-70" />
                  Announcement
                </span>
              </div>
              <div class="col text-right">
                <small class="text-muted">
                  <i class="fas fa-clock mr-1" />
                  {{ displayAnnouncementTime(announcements.updated_at) }}
                </small>
              </div>
            </div>
            <div class="col-auto ml-2">
              <h3>{{ announcements.message }}</h3>
            </div>
          </card>
        </div>

        <!-- Happening details' WHEN-WHERE -->
        <div class="col-md-6 order-md-1 order-0">
          <!--Latest Announcement section-->
          <card
            v-if="
              item?.show_latest_announcement &&
              announcements?.importance_type === 'medium' &&
              announcements?.message.length > 0
            "
            style="
              border-style: solid;
              border-color: #5e72e4;
              border-width: 2px;
            "
          >
            <div class="row align-items-center">
              <div class="col-auto ml-2">
                <span class="h6 surtitle text-muted">
                  <i class="ni ni-notification-70" />
                  Announcement
                </span>
              </div>
              <div class="col text-right">
                <small class="text-muted">
                  <i class="fas fa-clock mr-1" />
                  {{ displayAnnouncementTime(announcements.updated_at) }}
                </small>
              </div>
            </div>
            <div class="col-auto ml-2">
              <h3>{{ announcements.message }}</h3>
            </div>
          </card>

          <!-- About this event section-->
          <div class="card">
            <!-- Card body -->
            <div class="card-body">
              <h3 class="inline col-md-10 text-base">
                <i class="fa fa-clipboard-list mr-2" />About this event
              </h3>
              <h1 class="mb--2">{{ item.name }}</h1>
              <hr />

              <span
                id="expandable-description"
                :class="{
                  'collapsed-text': textCollapsed && showDescriptionsExpand(),
                }"
              >
                <p class="text-left" style="margin-bottom: 0.5em">
                  {{ item.description }}
                </p>
                <div
                  style="margin-bottom: 0.5em"
                  v-html="item.additional_description"
                ></div>
              </span>

              <a
                @click="textCollapsed = !textCollapsed"
                class="mt-2 link-view-more float-right"
                v-if="showDescriptionsExpand()"
              >
                <badge type="base" tag="a" size="md">
                  <small style="font-weight: bolder">
                    <i v-if="textCollapsed" class="el-icon-sort-down" />
                    <i v-else class="el-icon-sort-up" />
                    {{
                      textCollapsed
                        ? " Read more details"
                        : " Read less details"
                    }}
                  </small>
                </badge>
              </a>

              <div class="mt-5">
                <h3 class="inline col-md-10 text-base" style="margin-top: 1em">
                  <i class="fa fa-tags mr-2" />Official hashtags
                </h3>
                <div
                  v-for="(tag, index) in getTags()"
                  :key="index"
                  class="badge badge-default badge-pill mr-3"
                  :style="{ backgroundColor: tag.color }"
                >
                  #{{ tag.name.toUpperCase() }}
                </div>
              </div>
            </div>
          </div>

          <!--Latest Announcement section-->
          <card v-if="showAnnouncement">
            <div class="row align-items-center">
              <div class="col ml-2">
                <span class="h6 surtitle text-muted"
                  ><i class="ni ni-bell-55"></i>
                  Announcement
                </span>
                <div>
                  <!-- <base-switch v-model="switches"></base-switch> -->
                  <span class="clearfix"></span>
                </div>
                <span class="d-block h3">{{}}</span>
              </div>
            </div>
          </card>

          <!--Event Agenda Section-->
          <div class="card order-3" v-if="item.event_agenda">
            <h3 class="card-title inline text-base ml-4 mt-4 mb-0">
              <i class="el-icon-s-management mr-1" />Detailed agenda
            </h3>

            <div class="card-body mt-0">
              <div class="row">
                <div class="col">
                  <el-tabs type="card">
                    <el-tab-pane
                      v-for="(_item, idx) in item?.event_agenda"
                      :key="_item + idx"
                    >
                      <span slot="label">
                        <h3 class="card-title mb--2 mt-2">{{ _item.title }}</h3>
                        <small class="mt-0">
                          {{ getAgendaLabel(_item) }}
                        </small>
                      </span>

                      <div class="d-flex text-wrap">
                        <div class="card-text" v-html="_item.content"></div>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- BMIS Branding Banner -->
      <div
        id="event-brand-banner"
        class="row justify-content-center"
        v-if="false"
      >
        <div class="col-md-10">
          <card
            class="bg-gradient-primary"
            v-if="!showSpeakers || !showSponsors"
          >
            <!-- Card body -->
            <div class="row">
              <div class="col">
                <h5 class="card-title text-uppercase text-muted text-white">
                  Seize The Moment
                </h5>
              </div>
              <div class="col-auto mt-1">
                <img :src="logo" width="28" height="45" />
              </div>
            </div>
            <p class="text-sm mt--3 mb--2">
              <span class="text-nowrap text-light"
                >Don't miss out, book your slot now!
              </span>
            </p>
          </card>

          <card
            class="align-items-center"
            style="background: #f1f0ef !important"
            v-else
          >
            <card id="mantra-banner" class="bg-gradient-primary">
              <!-- Card body -->
              <div class="row">
                <div class="col">
                  <h5
                    class="card-title text-uppercase text-muted mb-0 text-white"
                  >
                    Book your ticket now...
                  </h5>
                </div>
                <div class="col-auto">
                  <div
                    class="icon icon-shape bg-white text-dark rounded-circle shadow"
                  >
                    <i class="ni ni-atom"></i>
                  </div>
                </div>
              </div>
              <p class="mb-0 text-sm">
                <span class="text-nowrap text-light"
                  >and Seize The Moment!</span
                >
              </p>
            </card>

            <!-- Sponsors & Speaker -->
            <div class="card-body mt-2 pt-0">
              <template>
                <div
                  class="col-md-12 justify-content-center"
                  v-show="showSpeakers"
                >
                  <h1 class="display-1 text-center" v-show="showSpeakers">
                    Speakers
                  </h1>
                  <div class="col-md-3 float-left">
                    <card>
                      <a href="#!">
                        <img
                          src="https://demos.creative-tim.com/vue-argon-dashboard-pro/img/theme/team-2.jpg"
                          class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                          style="width: 140px"
                        />
                      </a>
                      <div class="pt-4 text-center">
                        <h3 class="h3 title">
                          <p class="h3">Maria Tompson</p>
                          <small
                            class="h4 font-weight-light text-muted position-static"
                            >Web Developer</small
                          >
                        </h3>
                      </div>
                    </card>
                  </div>

                  <div class="col-md-3 ml-md-auto">
                    <card>
                      <a href="#!">
                        <img
                          src="https://demos.creative-tim.com/vue-argon-dashboard-pro/img/theme/team-3.jpg"
                          class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                          style="width: 140px"
                        />
                      </a>
                      <div class="pt-4 text-center">
                        <h3 class="h3 title">
                          <p class="h3">Lorena Brown</p>
                          <small class="h4 font-weight-light text-muted"
                            >Web Developer</small
                          >
                        </h3>
                      </div>
                    </card>
                  </div>

                  <div class="col-md-3 ml-auto">
                    <card>
                      <a href="#!">
                        <img
                          src="https://demos.creative-tim.com/vue-argon-dashboard-pro/img/theme/team-1.jpg"
                          class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                          style="width: 140px"
                        />
                      </a>
                      <div class="pt-4 text-center">
                        <h3 class="h3 title">
                          <p class="h3">Ryan Tompson</p>
                          <small class="h4 font-weight-light text-muted"
                            >Web Developer</small
                          >
                        </h3>
                      </div>
                    </card>
                  </div>
                </div>
              </template>

              <!--Sponsors-->
              <template>
                <div
                  class="col-12 justify-content-center"
                  v-show="showSponsors"
                >
                  <hr />
                  <h1 class="display-1 text-center">Sponsors</h1>

                  <div class="card-deck">
                    <div class="card bg-dark text-white border-0">
                      <img
                        class="card-img"
                        src="https://demos.creative-tim.com/vue-argon-dashboard-pro/img/theme/img-1-1000x600.jpg"
                        alt="Card image"
                      />
                      <div class="card-img-overlay d-flex align-items-center">
                        <div>
                          <h5 class="h2 card-title text-white mb-2">
                            Microsoft
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div class="card bg-dark text-white border-0">
                      <img
                        class="card-img"
                        src="https://demos.creative-tim.com/vue-argon-dashboard-pro/img/theme/img-1-1000x600.jpg"
                        alt="Card image"
                      />
                      <div class="card-img-overlay d-flex align-items-center">
                        <div>
                          <h5 class="h2 card-title text-white mb-2">Cisco</h5>
                        </div>
                      </div>
                    </div>

                    <div class="card bg-dark text-white border-0">
                      <img
                        class="card-img"
                        src="https://demos.creative-tim.com/vue-argon-dashboard-pro/img/theme/img-1-1000x600.jpg"
                        alt="Card image"
                      />
                      <div class="card-img-overlay d-flex align-items-center">
                        <div>
                          <h5 class="h2 card-title text-white mb-2">Apple</h5>
                        </div>
                      </div>
                    </div>

                    <div class="card bg-dark text-white border-0">
                      <img
                        class="card-img"
                        src="https://demos.creative-tim.com/vue-argon-dashboard-pro/img/theme/img-1-1000x600.jpg"
                        alt="Card image"
                      />
                      <div class="card-img-overlay d-flex align-items-center">
                        <div>
                          <h5 class="h2 card-title text-white mb-2">Other</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </card>
        </div>
      </div>

      <!-- Content Section -->
      <div
        id="event-document-assets"
        v-show="getDocuments().length > 0"
        class="row justify-content-center"
      >
        <h2 class="display-1 text-center mt-2">
          <i class="fa fa-folder-open" />
          Content Hub
        </h2>
        <div class="col-md-10">
          <card>
            <div class="row justify-content-center">
              <div
                class="col-md-2"
                v-for="(doc, idx) in getDocuments()"
                :key="idx"
              >
                <!-- PDF Viewer -->
                <base-stats-card
                  title="Total ticket orders"
                  type="gradient-base"
                >
                  <div v-if="isDocPDF(doc.mimeType)" class="text-center">
                    <pdf :src="doc.src"></pdf>
                  </div>

                  <img
                    v-else
                    class="card-img-top"
                    alt="Image placeholder"
                    src="/img/placeholders/bmis-content-asset-docs.svg"
                  />
                  <br />
                  <hr class="mt-0 mb-0" />
                  <div class="text-center mt-2 mb--3">
                    <small class="text-muted text-center">
                      <badge
                        type="base"
                        tag="a"
                        target="_blank"
                        :href="doc.src"
                        size="lg"
                      >
                        <slot name="icon"
                          ><i class="fa fa-download text-base mr-1" /> Download
                          Asset
                        </slot>
                      </badge>
                    </small>
                  </div>
                </base-stats-card>
              </div>
            </div>
          </card>
        </div>
      </div>

      <!--Gallery Section -->
      <div
        id="event-gallery-media"
        class="row justify-content-center mt-4 media-gallery"
        v-show="getImages().length > 0"
      >
        <div class="col-10">
          <h2 class="display-1 text-center">
            <i class="fa fa-camera mr-2" />
            Media Gallery
          </h2>

          <div class="row justify-content-center">
            <div class="col-md-12 col-12">
              <el-carousel :interval="4000" type="card" height="300px">
                <el-carousel-item
                  v-for="(image, imgIdx) in getImages()"
                  :key="imgIdx"
                >
                  <el-image
                    @click="idx = imgIdx"
                    :src="image.src"
                    :alt="image.id"
                    class="rounded"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </el-carousel-item>
              </el-carousel>

              <!-- Image viewer -->
              <vue-cool-lightbox
                :items="getImages()"
                :index="idx"
                @close="idx = null"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Video Section -->
      <div
        id="event-video-media"
        class="row justify-content-center mt-4"
        v-show="item.video_url"
      >
        <div class="col-md-10">
          <h2
            v-if="item.video_url && getImages().length <= 0"
            class="display-1 text-center"
          >
            <i class="fa fa-film mr-2" />
            Media Gallery
          </h2>
        </div>

        <div class="col-md-10" v-if="item.video_url">
          <card type="frame">
            <div class="row align-content-end">
              <div class="col-md-4 mb-2">
                <h6 class="surtitle text-muted mt-3">
                  <i class="fa fa-video" />
                  Event Promo Video
                </h6>
              </div>
              <div class="col-md-4 offset-md-4">
                <base-button
                  block
                  type="base"
                  @click="modals.openVideo = true"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fa fa-play" /> Watch Trailer
                </base-button>
              </div>
            </div>
          </card>

          <!--Video Modal -->
          <modal :show.sync="modals.openVideo" size="lg" gradient="primary">
            <h6 slot="header" class="modal-title text-center text-white mt-1">
              <i class="fa fa-tv" /> Promo Trailer
            </h6>

            <base-video-you-tube-player
              v-if="modals.openVideo"
              :yt-video="item.video_url"
            />
            <div v-else class="col-md-12 mb-4">
              <img
                src="/videos/bmis-video-placeholder.jpg"
                width="100%"
                height="100%"
              />
            </div>
          </modal>
        </div>
      </div>

      <!-- Map Section -->
      <div id="event-happening" class="row justify-content-center mt-5">
        <div
          class="col-md-10"
          v-if="
            item.event_type === 'physical' ||
            item.event_type === 'hybrid' ||
            item.event_type === 'virtual'
          "
        >
          <h2 class="display-1 text-center">
            <i class="fa fa-map-marked-alt mr-2" />
            {{ getMapLabel() }}
          </h2>
          <card
            v-if="item.event_type === 'hybrid' || item.event_type === 'virtual'"
          >
            <div class="row align-items-center">
              <div class="col-md-8">
                <span class="h6 surtitle text-muted">
                  <i class="fa fa-satellite-dish" />
                  Online Event
                </span>
                <h3 class="display-3 mb-0">Join the online event via</h3>
              </div>
              <div class="col-md-4 mt-2">
                <a :href="item.virtual_link" target="_blank" class="text-white">
                  <base-button block type="base">
                    <i class="fa fa-satellite-dish" />
                    {{ getVirtualLink(item.virtual_link) }}
                  </base-button>
                </a>
              </div>
            </div>
          </card>
          <el-tabs
            v-model="activeName"
            @tab-click="setMapbox"
            v-if="item?.venues?.length > 1"
            type="card"
          >
            <el-tab-pane
              v-for="(_item, idx) in item?.venues"
              :key="_item.id + idx"
            >
              <span slot="label">
                <h3 class="card-title mb--2 mt-2">{{ _item.name }}</h3>
              </span>

              <!-- <base-mapbox
                :long="_item.long"
                :lat="_item.lat"
                label="Venue Location"
                :search="false"
                :draggable-pin="false"
                :enable-shortcuts="true"
                :scrollable="true"
                :navigation-btn="true"
              /> -->
            </el-tab-pane>
          </el-tabs>
          <base-mapbox
            :long="venue.long"
            :lat="venue.lat"
            label="Venue Location"
            :search="false"
            :draggable-pin="false"
            :enable-shortcuts="true"
            :scrollable="true"
            :navigation-btn="true"
            v-if="
              venue.long &&
              venue.lat &&
              (item.event_type === 'physical' || item.event_type === 'hybrid')
            "
          />
        </div>

        <div class="col-md-10 mt--5" v-else-if="item.event_type === 'tba'">
          <card>
            <div class="row align-items-center">
              <div class="col-auto">
                <span class="h6 surtitle text-muted"
                  ><i class="fas fa-bullhorn"></i>
                  To-Be Announced
                </span>
                <h2 class="display-2 text-center">
                  More to come...Stay tunned :-)
                </h2>
              </div>
            </div>
          </card>
        </div>
      </div>

      <!-- FAQs Section -->
      <div id="event-faqs" class="row justify-content-center mt-5">
        <div class="col-md-10" v-if="item.event_faqs !== null">
          <h2 class="display-2 text-center">
            <i class="fa fa-question mr-2" />
            Frequently Asked Questions
          </h2>
          <card>
            <el-collapse accordion>
              <el-collapse-item
                v-for="(faq, idx) in item.event_faqs"
                :key="idx"
                :name="faq.name"
              >
                <template slot="title">
                  {{ faq.title }}
                </template>
                <div v-html="faq.content"></div>
              </el-collapse-item>
            </el-collapse>
          </card>
        </div>
      </div>
    </div>

    <!-- CTA Book Me In Scotty -->
    <div
      id="cta-booking-btn"
      class="col-md-4 offset-md-4"
      v-if="onlyBookButton"
    >
      <book-button-component
        :item="item"
        :modals="modals"
        @addTicket="addTicket($event)"
        :onlyBookButton="onlyBookButton"
      ></book-button-component>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Option,
  Select,
  Image,
  Carousel,
  CarouselItem,
  Tooltip,
  Skeleton,
  SkeletonItem,
  Collapse,
  CollapseItem,
  InputNumber,
  Steps,
  Step,
  Tabs,
  TabPane,
  Timeline,
  TimelineItem,
} from "element-ui";
import BaseVideoYouTubePlayer from "@/components/Media/BaseVideoYouTubePlayer.vue";
import BaseMapbox from "@/components/Mapbox/BaseMapbox.vue";
import Card from "../Cards/Card.vue";
import Badge from "@/components/Badge";
import { TimeLine, TimeLineItem, LoadingPanel } from "@/components";
import Vue from "vue";
import VueGtag from "vue-gtag";

import pdf from "vue-pdf";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import BaseSwitchVue from "../BaseSwitch.vue";
import BookButtonComponent from "./BookButtonComponent.vue";
import LineChart from "@/components/Charts/LineChart";

const moment = require("moment-timezone");

export default {
  name: "BaseEventLandingPage",
  components: {
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Image.name]: Image,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Tooltip.name]: Tooltip,
    [Skeleton.name]: Skeleton,
    [SkeletonItem.name]: SkeletonItem,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [InputNumber.name]: InputNumber,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [TimeLine.name]: TimeLine,
    [TimeLineItem.name]: TimeLineItem,
    [BaseSwitchVue]: BaseSwitchVue,
    BookButtonComponent,
    BaseVideoYouTubePlayer,
    LineChart,
    BaseMapbox,
    Badge,
    Card,
    pdf,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    modals: {
      type: Object,
      default: null,
    },
    hasBookButton: {
      type: Boolean,
      default: true,
    },
    onlyBookButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      freePersonalizedGuests: [],
      announcements: {
        message: "",
      },
      attendee: {
        name: "",
        email: "",
        special_requirements: this.hasDietaryRequirements() ? "" : undefined,
        phone_number: "",
        type: "attendees",
      },
      tags: [],
      venue: {},
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      textCollapsed: true,
      showSponsors: false,
      showSpeakers: false,
      showAnnouncement: false,
      heroImageLoaded: false,
      activeStep: 0,
      idx: 0,
      loading: false,
      activeName: null,
    };
  },
  mounted() {
    if (this.onlyBookButton) {
      this.modals.openBooking = true;
    }
  },
  created() {
    this.setItem();
    this.getTickets();
    this.getTags();
    this.displayLatestAnnouncement();

    this.items = {
      item: this.item,
      tags: this.tags,
      dates: [],
    };
  },
  computed: {
    user() {
      let me = null;
      try {
        // Get User Profile
        me = this.$store.getters["profile/me"];
      } catch (error) {
        // Notification
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong with your account!",
        });
      }
      // return user object
      return me;
    },
    logo() {
      return process.env.VUE_APP_DEFAULT_LOGO;
    },
    organizerPicture() {
      if (
        this.item?.user?.profile_image.includes("http://") ||
        this.item?.user?.profile_image.includes("https://")
      ) {
        return this.item?.user?.profile_image;
      }
      return this.item?.user?.profile_image
        ? process.env.VUE_APP_AWS_S3_URL + this.item?.user?.profile_image
        : null;
    },
    eventStartDateTime() {
      return moment(this.items.item.start_at).format("ddd, DD MMM YY");
    },
    eventStartDateTimeShort() {
      return moment(this.items.item.start_at).format("ddd, DD MMM");
    },
    eventEndDateTime() {
      return moment(this.items.item.end_at).format("ddd, DD MMM YY");
    },
    eventEndDateTimeShort() {
      return moment(this.items.item.end_at).format("ddd, DD MMM");
    },
    eventTotalDuration() {
      const startAt = moment(this.items.item.start_at);
      const endAt = moment(this.items.item.end_at);

      return endAt.diff(startAt, "days");
    },
    eventStartTime() {
      return (
        moment(this.items.item.start_at)
          // .tz(this.items.item.timezone)
          .format("LT")
      );
    },
    eventEndTime() {
      return (
        moment(this.items.item.end_at)
          // .tz(this.items.item.timezone)
          .format("LT")
      );
    },
    localTimezone() {
      const tz = this.timezone ?? this.user?.timezone;
      return (
        "The times for this event are shown in your own local timezone (" +
        tz +
        ")"
      );
    },
    originalTimezone() {
      return (
        "The times for this event are shown in venue's/event's original timezone (" +
        this.items.item.timezone +
        ")"
      );
    },
    isHybridSameTimezone() {
      return (
        this.items.item.event_type === "hybrid" &&
        this.items.item.timezone === this.timezone
      );
    },
    localStartTime() {
      const originalTime = moment.tz(
        this.items.item.start_at,
        this.items.item.timezone
      );
      return originalTime.clone().tz(this.timezone).format("LT");
    },
    localEndTime() {
      const originalTime = moment.tz(
        this.items.item.end_at,
        this.items.item.timezone
      );
      return originalTime.clone().tz(this.timezone).format("LT");
    },
    eventPublicURL() {
      const itemId = _.isEmpty(this.item.hash_code)
        ? this.item.id
        : this.item.hash_code;
      return process.env.VUE_APP_BASE_URL + "/event/" + itemId;
    },
    eventShortDescription() {
      return this.item.description
        ? this.item.description.substring(0, 140) + "..."
        : " ";
    },
    virtualLinkMessage() {
      if (this.item.virtual_link) {
        return (
          "You can attend event through this link: " +
          this.item.virtual_link +
          "%0D%0A" +
          "%0D%0A"
        );
      } else return "%0D%0A";
    },
  },
  methods: {
    setItem() {
      this.tags = this.item?.tags;
      // this.item.venues = [
      //   this.item.venues,
      //   {
      //     name: "H Mariahilferstra\u00dfe, Kaiserstra\u00dfe 1, Neubau, Vienna 1070, Austria",
      //     address_name:
      //       "H Mariahilferstra\u00dfe, Kaiserstra\u00dfe 1, Neubau, Vienna 1070, Austria",
      //     lat: "48.196489",
      //     long: "16.34167",
      //     category_id: 1,
      //     created_at: "2022-06-04 00:54:30",
      //     updated_at: "2022-09-27 15:00:36",
      //   },
      // ];
      if (this.item.venues?.length > 1) {
        this.venue = this.item.venues[0];
        this.activeName = this.item.venues[0].name;
      } else {
        this.venue = this.item.venues ?? {};
      }
    },
    addTicket(attendee) {
      this.$emit("addTicket", attendee);
    },
    getTickets() {
      // we used to return only available tickets
      // let availableTickets = [];
      // this.item?.event_tickets.forEach((ticket) => {
      //   if (
      //     moment().isBetween(
      //       ticket.metadata.start_sale_at,
      //       ticket.metadata.end_sale_at,
      //       "day",
      //       "[]"
      //     ) &&
      //     (ticket?.metadata?.sold_quantity <=
      //       ticket?.metadata?.total_quantity ||
      //       ticket?.metadata?.total_quantity === -1)
      //   ) {
      //     availableTickets.push(ticket);
      //   }
      // });
      // return availableTickets;
      return this.item?.event_tickets || [];
    },
    ticketIsAvailable(ticket) {
      return (
        moment().isBetween(
          ticket.metadata.start_sale_at,
          ticket.metadata.end_sale_at,
          "day",
          "[]"
        ) &&
        (ticket?.metadata?.sold_quantity <= ticket?.metadata?.total_quantity ||
          ticket?.metadata?.total_quantity === -1)
      );
    },
    ticketIsSoldOut(ticket) {
      return !(
        ticket?.metadata?.sold_quantity <= ticket?.metadata?.total_quantity ||
        ticket?.metadata?.total_quantity === -1
      );
    },
    ticketIsNotAvailableAnyMore(ticket) {
      return moment().isAfter(
        ticket.metadata.start_sale_at,
        ticket.metadata.end_sale_at,
        "day",
        "[]"
      );
    },
    ticketIsNotAvailableYet(ticket) {
      return moment().isBefore(
        ticket.metadata.start_sale_at,
        ticket.metadata.end_sale_at,
        "day",
        "[]"
      );
    },
    getTags() {
      return this.tags;
    },
    getAvailability(available) {
      return moment(available).format("Do MMM YYYY, h:mm:ss a");
    },
    getHeroImage() {
      if (
        this.items.item.hero_image.includes("http://") ||
        this.items.item.hero_image.includes("https://")
      ) {
        return this.items.item.hero_image;
      }
      return process.env.VUE_APP_AWS_S3_URL + this.items.item.hero_image;
    },
    getImages() {
      return this.items.item?.additional_images.map((image) => ({
        id: image.id,
        src: process.env.VUE_APP_AWS_S3_URL + image.additional_asset,
      }));
    },
    getDocuments() {
      return this.items.item?.additional_docs.map((doc) => ({
        id: doc.id,
        src: process.env.VUE_APP_AWS_S3_URL + doc.additional_asset,
        mimeType: doc.type,
      }));
    },
    isDocPDF(mime) {
      const regex = /pdf/i;

      if (regex.test(mime)) {
        console.log("String contains pdf");
        return true;
      } else {
        console.log("String does not contain pdf");
        return false;
      }
    },
    showDescriptionsExpand() {
      return (
        this.item?.description?.length > 1000 ||
        this.item?.additional_description?.length > 1000
      );
    },
    getHashtags() {
      const tagsName = ["bookmeinscotty"];
      this.tags.forEach((tag) => {
        tagsName.push(tag.name);
      });
      return tagsName.join();
    },
    getHashtagsForEmail() {
      const tagsName = [" #bookmeinscotty"];
      this.tags.forEach((tag) => {
        tagsName.push(" #" + tag.name);
      });
      return tagsName.join();
    },
    getVirtualLink(link) {
      const zoomPattern = /zoom/;
      if (zoomPattern.test(link)) {
        return "Zoom";
      }

      const meetPattern = /meet/;
      if (meetPattern.test(link)) {
        return "Google Meet";
      }

      const teamsPattern = /teams/;
      if (teamsPattern.test(link)) {
        return "Microsoft Teams";
      }

      return "Virtual Link";
    },
    getCalendarFile() {
      this.$ics.removeAllEvents();
      this.$ics.addEvent(
        navigator.language === "zz-ZZ" ? "en-AU" : navigator.language,
        this.item.name,
        this.item.description,
        this.item.venues ? this.item.venues.address_name : "",
        this.item.start_at,
        this.item.end_at,
        this.eventPublicURL,
        {},
        "",
        ""
      );
      this.$ics.download(this.item.name);
    },
    getMapLabel() {
      switch (this.item.event_type) {
        case "virtual":
          return "Virtual Location";
          break;
        case "physical":
          return "Venue Location";
          break;
        case "hybrid":
          return "Virtual and Venue Location";
          break;
        default:
          return "Where to find us?";
      }
    },
    getAgendaLabel(val) {
      const tabDate = val.date ?? val.start_at;
      return " ● " + moment(tabDate).format("ddd, D MMM @ HH:mm");
    },
    async copyURL() {
      try {
        await navigator.clipboard.writeText(this.eventPublicURL);
        swal.fire({
          title: "Copied - Public Event Link",
          html:
            "You can use this link to share it with a wide group of people, post it on your Social Media or Spread the Word<br></hr> " +
            "<br><b>Public Event Link:</b><br> <button type='button' class='btn-icon-clipboard text-center'>" +
            this.eventPublicURL +
            "</button> <br></hr>",
          type: "success",
          confirmButtonClass: "btn btn-outline-danger btn-fill",
          confirmButtonText: " Close",
          buttonsStyling: false,
          icon: "success",
        });
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    hasSpecialRequirements() {
      return true;
    },
    hasDietaryRequirements() {
      // return this.item?.category?.name === "Food & Drinks";
      return this.hasSpecialRequirements();
    },
    async displayLatestAnnouncement() {
      let params = {
        // ...{ sort: "-created_at" },
        filter: {
          ...{ item_id: this.item.id },
        },
      };
      await this.$store.dispatch("announcements/list", params).then(() => {
        const announcements = this.$store.getters["announcements/list"];
        if (announcements?.length > 0) {
          this.announcements = announcements[0];
        } else {
          this.announcements.message = "";
        }
      });
    },
    displayAnnouncementTime(date) {
      return moment.utc(date).fromNow();
    },
    setMapbox() {
      this.venue = this.item.venues[this.activeName];
    },
  },
};
</script>

<style scoped>
#mantra-banner {
  width: 60vw !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.el-tabs--card >>> .el-tabs__header {
  box-shadow: 0 0 0 0 !important;
  border: 0 !important;
  padding-right: 0;
  padding-left: 0;
  overflow: auto !important;
  white-space: nowrap !important;
}
</style>
