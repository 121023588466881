<template>
  <div id="app">
    <label class="form-control-label">
      {{ label }}</label
    >

    <file-pond
      ref="pond"
      :name="formData"
      :label-idle="helpMessage"
      :init="handleInitFiles"
      :allow-multiple="multiple"
      :allow-file-type-validation="fileTypesValidation"
      :accepted-file-types="fileTypes"
      :server="server"
      :files="files"
      :allow-drop="true"
      :image-preview-height="previewHeight"
      :allow-image-crop="true"
      image-crop-aspect-ratio="16:10"
      :allowFileSizeValidation="true"
      :max-file-size="fileSize"
      :max-files="maxUploadFiles"
      :allowImageResize="true"
      imageResizeTargetHeight="1800"
      imageResizeMode="cover"
      v-on:processfile="getNewImg"
    />
  </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import FilePond plugins - install these plugins separately

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
// Init FilePond component and add plugins
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImagePreview,
  FilePondPluginImageCrop,
  FilePondPluginImageResize
);

export default {
  name: "BaseFileInput",
  components: {
    FilePond,
  },
  props: {
    files: {
      type: Array,
      default: () => [],
      description: "Array with selected files to be uploaded",
    },
    multiple: {
      type: Boolean,
      default: false,
      description: "Enable single or multiple files to be uploaded",
    },
    fileTypes: {
      type: String,
      default: "image/jpg, image/jpeg, image/png",
      description: "Allowed types of files to be uploaded",
    },
    fileTypesValidation: {
      type: Boolean,
      default: true,
      description: "Enable or disable file type validation",
      },
    endpoint: {
      type: String,
      required: true,
      description: "Api or Back-end endpoint where files will be sent",
    },
    fileSize: {
      type: String,
      default: "5MB",
      description: "File size limit to be uploaded",
    },
    label: {
      type: String,
      default: "Images",
      description: "Label to be shown in the file input form",
    },
    helpMessage: {
      type: String,
      default: "Drop a file here..."
    },
    maxUploadFiles: {
      type: Number,
      default: null,
      description: "Add an expressive message to display on you users",
    },
    previewHeight: {
      type: String,
      default: "250",
      description: "Add an expressive message to display on you users",
    },
    totalImages: {
      type: Number,
      default: 0,
      description: "Existing number of images (total count)",
    },
    handleInitFiles: {
      type: String,
      default:
        "https://bmis-local.s3.ap-southeast-1.amazonaws.com/users/5af6b5a2-a375-49fd-a2a1-313cd9846f4d/profile/w9S8t6VOoIgVPBuxrvU8nnOCmjBbU2tnJOkLWfFC.jpg",
      description: "Default storage as fallback solution",
    },
  },
  computed: {
    formData() {
      return this.multiple === false ? "attachment" : "attachments";
    },
    headers() {
      return {
        Authorization:
          "Bearer " +
          localStorage.getItem("vue-authenticate.vueauth_access_token"),
      };
    },
    server() {
      return {
        url: this.endpoint,
        headers: this.headers,
      };
    },
  },
  methods: {
    getNewImg(er, file) {
      if (file && file.serverId) this.$emit("newImgUrl", file.serverId.slice(1,-1));
    },
  },
};
</script>

<style>
.filepond--credits {
  display: none;
}
</style>
