<template>
  <div>
    <!-- Subscription Plan -->
    <user-subscription-plans
      :user="user"
      :requiredFieldsMissing="requiredFieldsMissing"
    />
  </div>
</template>
<script>
import UserSubscriptionPlans from "@/views/Users/Profile/UserSubscriptionPlansCard.vue";
import formMixin from "@/mixins/form-mixin";
import { Select, Option, Tooltip } from "element-ui";

export default {
  name: "UserMetaData",

  components: {
    UserSubscriptionPlans,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
  },

  mixins: [formMixin],

  props: {
    user: Object,
    requiredFieldsMissing: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
