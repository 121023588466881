<template>
  <div>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-md-12">
          <!-- Fullcalendar -->
          <div class="card card-calendar">
            <!-- Card header -->
            <div class="card-header header-calendar">
              <!-- Title -->
              <h3>{{ calendarTitle }}</h3>
              <div>
                <a
                  href="#"
                  @click.prevent="prev"
                  class="fullcalendar-btn-prev btn btn-sm btn-primary mr--1"
                >
                  <i class="fas fa-angle-left"/>
                </a>
                <a
                  class="btn btn-sm btn-primary mr--1"
                  :class="{ active: defaultView === 'dayGridMonth' }"
                  @click="changeView('dayGridMonth')"
                >
                  <i class="fas fa-calendar-alt"></i> Month
                </a>
                <a
                  class="btn btn-sm btn-primary mr--1"
                  :class="{ active: defaultView === 'dayGridWeek' }"
                  @click="changeView('dayGridWeek')"
                >
                  <i class="fas fa-calendar-week"></i> Week
                </a>
                <a
                  class="btn btn-sm btn-primary"
                  :class="{ active: defaultView === 'listMonth' }"
                  @click="changeView('listMonth')"
                >
                  <i class="fas fa-calendar-check"></i> List
                </a>
                <a
                  href="#"
                  @click.prevent="next"
                  class="fullcalendar-btn-next btn btn-sm btn-primary ml--1"
                >
                  <i class="fas fa-angle-right"></i>
                </a>
              </div>
            </div>
            <!-- Card body -->
            <div class="card-body p-0 card-calendar-body ml-2 mr-2 mt-2 mb-2">
              <full-calendar
                  :time-zone="timezone"
                  :default-view="defaultView"
                  :event-sources="eventSources"
                  :plugins="calendarPlugins"
                  :theme-system="themeSystem"
                  :editable="false"
                  content-height="auto"
                  aspect-ratio="1.8"
                  update-size="true"
                  :theme="false"
                  :selectable="true"
                  :selectHelper="true"
                  ref="fullCalendar"
                  class="calendar"
                  @dateClick="onDateClick"
                  @eventClick="onEventClick"
                  @eventRender="eventCustomization"
                  progressive-event-rendering="true"
                  :google-calendar-api-key="googleApiKey"
              >
              </full-calendar>

              <div class="text-center">
                <small class="text-muted"> <el-tooltip
                    class="item"
                    style="border-radius: 0"
                    effect="light"
                    content="Your current timezone as is captured from your Browser"
                    placement="top"
                >
                  <i class="fas fa-globe"/>
                </el-tooltip>

                </small>
                <small class="text-muted ml-2">{{ timezone }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Event Details Modal -->
    <modal
      :show.sync="showEditModal"
      modal-classes="modal-secondary"
      :header-classes="'calendar-modal-header public'"
      size="lg"
    >
      <template slot="header">
        <h2 class="mt-2">G details</h2>
      </template>

      <div class="mt--5 mb-3">
        <el-image
            :src="getHeroImage(model.hero_image)"
            class="d-block shadow-1-strong rounded w-100"
            alt="1"
            @load="heroImageLoaded = true">

          <div slot="placeholder" class="image-slot">
            <el-skeleton :rows="6" animated>
              <template slot="template">
                <div style="padding: 14px">
                  <el-skeleton-item
                      variant="image"
                      style="width: 100%; height: 480px"
                  /><br />
                </div>
              </template>
            </el-skeleton>
          </div>
        </el-image>
      </div>

      <template slot="footer" v-if="!isPublic">
        <base-button size="sm" type="primary" @click="redirectToEvent()">
          <i class="far fa-edit mr-2" />Edit event
        </base-button>
      </template>

      <template slot="footer" v-if="isPublic">
        <base-button size="sm" type="primary" @click="redirectToLink(model.virtual_link)" v-if="model.virtual_link">
          <i class="fa fa-satellite-dish"/> Virtual link
        </base-button>      
        <base-button size="sm" type="primary" @click="redirectToLink(model.event_link)">
          <i class="fa fa-eye"/> Visit event
        </base-button>        
      </template>

      <div class="calendar-modal-section">
        <h3 class="text-muted text-sm font-weight-bold">Event</h3>
        <h3 class="mb-0 ml-2">{{ model.title }}</h3>
      </div>
      <div class="calendar-modal-section">
        <h3 class="text-muted text-sm font-weight-bold">Description</h3>
        <span class="mb-0 ml-2">{{ model.description }}
          <i v-if="isPublic && (model.event_type==='hybrid'
          || model.event_type==='virtual')" class="fa fa-satellite-dish" />
        </span>
      </div>
      <div class="calendar-modal-section" v-if="!isPublic">
        <div class="d-flex justify-content-between pt-1">
          <h3 class="text-muted text-sm font-weight-bold">When
            <small class="text-muted">
              <el-tooltip
                  class="item"
                  style="border-radius: 0"
                  effect="light"
                  :content="localTimezone()"
                  placement="top"
              >
                <i class="fa fa-info-circle" />
              </el-tooltip>
            </small>
          </h3>

          <div class="text-right">
            <small class="text-muted">
              <i class="fas fa-clock mr-1" />
              {{ displayModalDateFromNow(model.start, model.timezone) }}
            </small>
          </div>
        </div>

        <div class="ml-2">
          {{ localizeEventTime(model.start,  model.timezone) | humanFullTime }}
        </div>
      </div>

      <div v-if="isPublic" class="calendar-modal-section">
        <div class="d-flex justify-content-between pt-1">
          <h3 class="text-muted text-sm font-weight-bold">When
            <small class="text-muted">
              <el-tooltip
                  class="item"
                  style="border-radius: 0"
                  effect="light"
                  :content="localTimezone()"
                  placement="top"
              >
                <i class="fa fa-info-circle" />
              </el-tooltip>
            </small>
          </h3>
          <div class="text-right">
            <small class="text-muted">
              <i class="fas fa-clock mr-1" />
              {{ displayModalDateFromNow(model.start, model.timezone) }}
            </small>
          </div>
        </div>
        <div class="ml-3">
          {{ localizeEventTime(model.start,  model.timezone) | humanFullTime}}
        </div>
      </div>

      <div v-else class="calendar-modal-section divider">
        <h3 class="text-muted text-sm font-weight-bold">Category:</h3>
        <div class="badge badge-primary badge-pill ml-3">
          {{ model.category.name }}
        </div>
      </div>
      <div class="calendar-modal-section" v-if="!isPublic">
        <h3 class="text-muted text-sm font-weight-bold">Tags</h3>
        <div
          v-for="(tag, index) in model.tags"
          :key="index"
          class="badge badge-default ml-3"
          :style="{ backgroundColor: tag.color }"
        >
          {{ tag.name }}
        </div>
      </div>
    </modal>

  </div>
</template>
<script>
// packages
import {
  Image,
  Tooltip,
  Skeleton,
  SkeletonItem
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
// components
import Modal from "@/components/Modal";
import FullCalendar from "@fullcalendar/vue";
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import googleCalendarPlugin from "@fullcalendar/google-calendar";

const moment = require("moment-timezone");

export default {
  name: "base-calendar",
  components: {
    Modal,
    FullCalendar,
    [Image.name]: Image,
    [Tooltip.name]: Tooltip,
    [Skeleton.name]: Skeleton,
    [SkeletonItem.name]: SkeletonItem,
  },
  mixins: [formMixin],
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const today = new Date();
    const y = today.getFullYear();
    const m = today.getMonth();
    const d = today.getDate();
    let yearAndMonth = `${y}-${m + 1}`;

    return {
      calendarPlugins: [
        listPlugin,
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        momentTimezonePlugin,
        googleCalendarPlugin,
      ],
      defaultView: "dayGridMonth",
      height:'parent',
      contentHeight: 'auto',
      events: [],
      eventSources: [],
      themeSystem: "bootstrap",
      showAddModal: false,
      showEditModal: false,
      googleApiKey: process.env.VUE_APP_GOOGLE_API_KEY,
      model: {
        id: "",
        hash_id: "",
        title: "",
        className: "bg-default",
        description: "",
        hero_image: "",
        event_type: "",
        start: "",
        end: "",
        tags: [],
        category: {},
        timezone: "",
        virtual_link: null,
        event_link: ""
      },
      heroImageLoaded: false,
      eventColors: [
        "bg-info",
        "bg-orange",
        "bg-red",
        "bg-green",
        "bg-default",
        "bg-blue",
        "bg-purple",
        "bg-yellow",
      ],
      calendarTitle: "",
      previousDate: "",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
  },
  methods: {
    async fetchList() {
      this.start_at = moment(
        this.calendarApi().state.dateProfile.currentRange.start
      ).format("YYYY-MM-DD 00:00:00");
      this.end_at = moment(
        this.calendarApi().state.dateProfile.currentRange.end
      ).format("YYYY-MM-DD 00:00:00");

      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.start_at && this.end_at
              ? { start_at: [this.start_at, this.end_at] }
              : { start_at: [] }),
            ...{ user_id: 0 },
          },
          uuid: this.isPublic ? this.$route.params.id : undefined,
          include: this.isPublic ?"category,tags,venues":"category,tags",
        };
        // fire a GET request to load the items
        await this.$store.dispatch(this.isPublic ? "publicItems/list" : "items/list", params)
            .then(() => {
              this.items =
                this.$store.getters[
                  this.isPublic ? "publicItems/list" : "items/list"
                ];
            });

            this.events = [];
            // ToDo -  Revise the current initiation of the Timezones
            this.items.forEach((event) => {
              // convert current time to the timezone of event and compare dates
              const currentTimeInEventTimezone = moment.tz(
                moment(),
                event.timezone
              );
              const startTime = moment.tz(
                moment(event.start_at),
                event.timezone
              );

              let localStartingTime = this.localizeEventTime(event.start_at, event.timezone);
              let localEndingTime = this.localizeEventTime(event.end_at, event.timezone);

              // Fill the final events object for Calendar
              this.events.push({
                id: event.id,
                hash_id: event.hash_code,
                title: event.name,
                start: localStartingTime,
                end: localEndingTime,
                description: event.description,
                hero_image: event.hero_image,
                imageurl: event.hero_image,
                image_url: event.hero_image,
                tags: event.tags,
                category: event.category,
                className: currentTimeInEventTimezone.isBefore(startTime)
                  ? "bg-green text-white"
                  : "bg-red text-white",
                timezone: event.timezone,
                virtual_link: event.virtual_link,
                show_on_calendar: event.show_on_calendar,
                event_type: event.event_type,
                venues: this.isPublic ? event.venues : null
                // allDay: true,
              });
            });

            if (this.isPublic) {
              this.eventSources = [{
                events: this.events.filter(item => item.show_on_calendar === true),
              }]
            } else {
              this.eventSources = [
                {
                  events: this.events,
                },
                {
                  googleCalendarId:
                  process.env.VUE_APP_GOOGLE_CALENDAR_ID_AUS,
                  color: "#5e72e4",
                  textColor: "white",
                  display: 'background'
                },
              ];
            }
        this.loading = false;
      } catch (error) {
        console.log("Error", error);
      }
    },
    calendarApi() {
      return this.$refs.fullCalendar.getApi();
    },
    changeView(viewType) {
      this.defaultView = viewType;
      this.prevDate = moment(
        this.calendarApi().state.dateProfile.currentRange.start
      ).format("MMMM YYYY");
      this.calendarApi().changeView(viewType);
      this.setCalendarTitle();
      if (
        this.prevDate !==
        moment(this.calendarApi().state.dateProfile.currentRange.start).format(
          "MMMM YYYY"
        )
      ) {
        this.fetchList();
      }
    },
    next() {
      this.prevDate = moment(
        this.calendarApi().state.dateProfile.currentRange.start
      ).format("MMMM YYYY");
      this.calendarApi().next();
      this.setCalendarTitle();

      if (
        this.prevDate !==
        moment(this.calendarApi().state.dateProfile.currentRange.start).format(
          "MMMM YYYY"
        )
      ) {
        this.fetchList();
      }
    },
    prev() {
      this.prevDate = moment(
        this.calendarApi().state.dateProfile.currentRange.start
      ).format("MMMM YYYY");
      this.calendarApi().prev();
      this.setCalendarTitle();
      if (
        this.prevDate !==
        moment(this.calendarApi().state.dateProfile.currentRange.start).format(
          "MMMM YYYY"
        )
      ) {
        this.fetchList();
      }
    },
    onDateClick({ date }) {
      this.showAddModal = true;
      this.model.start = date;
      this.model.end = date;
    },
    onEventClick({ el, event }) {
      let description = event?.extendedProps?.description;
      if (this.isPublic && event.extendedProps){      
        if (event.extendedProps.event_type==='virtual'
            || event.extendedProps.event_type==='hybrid')
        {
          description = 'This event is available online '
        } else if (event.extendedProps.event_type==='physical')
        {
          //to change with event address
          description = event.extendedProps?.venues?.address_name
        }
        else {
          description = 'To-Be Announced Soon!'
        }
      }

      this.model = {
          id: event.id,
          title: event.title,
          className: event.classNames ? event.classNames.join(" ") : "",
          start: event.start,
          end: event.end,
          description: description,
          hero_image: event.extendedProps.hero_image,
          virtual_link: event.extendedProps ? event.extendedProps.virtual_link : null,
          event_type: event.extendedProps ? event.extendedProps.event_type : "",
          event_link: process.env.VUE_APP_BASE_URL + '/event/' + event.extendedProps.hash_id ?? event.id,
          tags: event.extendedProps ? event.extendedProps.tags : [],
          category: event.extendedProps ? event.extendedProps.category : {},
          timezone: event.extendedProps ? event.extendedProps.timezone : {},
        };

        this.showEditModal = true;
    },
    eventCustomization(item) {
      if (item.event.extendedProps.hero_image) {
        let img = document.createElement("img");
        img.src = this.getHeroImage(item.event.extendedProps.hero_image);
        img.classList.add("rounded");
        img.classList.add("w-100");
        img.classList.add("img-fluid");

        img.style.cssText = "border-bottom-right-radius: 0px !important";
        item.el.lastChild.appendChild(img);
      }
    },
    setCalendarTitle() {
      this.calendarTitle =
        this.defaultView === "timeGridDay"
          ? moment
              .utc(this.calendarApi().state.dateProfile.currentRange.start)
              .format("MMMM Do YYYY")
          : moment
              .utc(this.calendarApi().state.dateProfile.currentRange.start)
              .format("MMMM YYYY");
    },
    displayModalDateFromNow(originalDateTime, eventTimeZone) {
      const eventDateTime = moment.tz(
          originalDateTime,
          eventTimeZone
      );

      const happening = eventDateTime.clone().tz(this.timezone);

      return happening.fromNow();
    },
    localTimezone() {
      const tz = this.timezone
      return "The times for the events are shown in your own local timezone (" + tz + ")";
    },
    localizeEventTime(originalTime, eventTimeZone) {
      const eventTime = moment.tz(
          originalTime,
          eventTimeZone
      );
      return eventTime.clone().tz(this.timezone).format("YYYY-MM-DD hh:mm:ss");
    },
    redirectToEvent() {
      this.showEditModal = false;
      setTimeout(() => {
        this.$router.push({
          name: "Edit Event",
          params: { id: this.model.id },
        });
      });
    },
    redirectToLink(link) {
       window.open(link, "_blank");
    },
    editEvent() {
      let index = this.events.findIndex((e) => e.title === this.model.title);
      if (index !== -1) {
        this.events.splice(index, 1, this.model);
      }
      this.showEditModal = false;
    },
    closeEvent() {
      this.showEditModal = false;
    },
    getHeroImage(heroImage) {
      if (heroImage.includes("http://") || heroImage.includes("https://"))
      {
        return heroImage;
      }
      return process.env.VUE_APP_AWS_S3_URL + heroImage;
    },
    cleanCSS() {
      const listTheme =  document.querySelector('div.calendar.fc.fc-ltr.fc-unthemed');
      listTheme.classList.remove('fc-unthemed'); // Remove class
    }
  },
  mounted() {
    this.fetchList();
    this.setCalendarTitle();
    this.cleanCSS();
  },
};
</script>
<style lang="scss">
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
@import "~@fullcalendar/timegrid/main.css";
@import "../../assets/sass/core/vendors/_fullcalendar.scss";

.popper,
.tooltip {
  background: white;
  color: black;
  max-width: 200px;
  width: auto;
  font-size: 0.8rem;
  padding: 0.5em 1em;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: black;
  text-align: center;
  background-color: white;
  border-radius: 0.75rem;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: grey;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: white;
}

.style5 .tooltip .tooltip-arrow {
  border-color: gray;
}

</style>
