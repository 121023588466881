/**
 * Register global components here and use them as a plugin in your main Vue instance
 */
import BaseHeader from '@/components/BaseHeader';
import BaseNav from "@/components/Navbar/BaseNav";
import BasePublicNav from '@/components/Navbar/BasePublicNav';


import BaseTable from '@/components/BaseTable';
import BaseInput from '@/components/Inputs/BaseInput.vue';
import BaseHtmlInput from '@/components/Inputs/BaseHtmlInput.vue';
import BaseDropdown from '@/components/BaseDropdown.vue';
import Card from '@/components/Cards/Card.vue';
import Modal from '@/components/Modal.vue';
import BaseStatsCard from '@/components/Cards/StatsCard.vue';
import BaseButton from '@/components/BaseButton.vue';
import Badge from '@/components/Badge.vue';
import RouteBreadcrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue';
import BaseSwitch from '@/components/BaseSwitch.vue';
import BaseRadio from "@/components/Inputs/BaseRadio";
import BaseProgress from "@/components/BaseProgress";
import BasePagination from "@/components/BasePagination";
import BaseAlert from "@/components/BaseAlert";
import BaseLoadingPlaceholder from "@/components/Skeleton/BaseLoadingPlaceholder";
import BaseFileManager from "@/components/Files/BaseFileInput.vue";
import Tabs from '@/components/Tabs/Tabs';
import Tab from '@/components/Tabs/Tab';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Input, Tooltip, Popover } from 'element-ui';
import ICS from 'vue-ics'

/**
 * Register global components here and use them as a plugin in your main Vue instance
 */
import VueSocialSharing from 'vue-social-sharing';
import CoolLightBox from 'vue-cool-lightbox';

// import VuePlyr from '@skjnldsv/vue-plyr/dist';
// import VideoBackground from 'vue-responsive-video-background-player'
// import VueCompositionAPI from '@vue/composition-api';

// Custom package CSS styles
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
// import '@skjnldsv/vue-plyr/dist/vue-plyr.css'

// Fontawesome icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';

library.add(faUserSecret);
library.add(faXTwitter);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const GlobalComponents = {
  install(Vue) {
    // Vendors Packages
    // Vue.use(VueCompositionAPI);
    Vue.use(Tooltip);
    Vue.use(Popover);
    Vue.use(ICS)
    Vue.use(VueSocialSharing);
    Vue.use(CoolLightBox);
    // Vue.use(VuePlyr);
    // Base component (custom, pre-styled, common)
    Vue.component(Badge.name, Badge);
    Vue.component(BaseTable.name, BaseTable);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseHtmlInput.name, BaseHtmlInput);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BasePublicNav.name, BasePublicNav);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.component(BaseLoadingPlaceholder.name, BaseLoadingPlaceholder);
    Vue.component(BaseFileManager.name, BaseFileManager);
    Vue.component(Card.name, Card);
    Vue.component(Modal.name, Modal);
    Vue.component(BaseStatsCard.name, BaseStatsCard);
    Vue.component(RouteBreadcrumb.name, RouteBreadcrumb);
    Vue.component(Input.name, Input);
    Vue.component(Tabs.name, Tabs);
    Vue.component(Tab.name, Tab);
    // Generic Packages
    Vue.component('validation-provider', ValidationProvider)
    Vue.component('validation-observer', ValidationObserver)
    Vue.component('validation-observer', ValidationObserver)
    Vue.component('font-awesome-icon', FontAwesomeIcon)
    Vue.component('vue-cool-lightbox', CoolLightBox);
    // Vue.component('video-background', VideoBackground);
  }
};

export default GlobalComponents;
