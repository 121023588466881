import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth-module";
import announcements from "./modules/announcements-module";
import attendees from "./modules/attendees-module";
import categories from "./modules/categories-module";
import items from "./modules/items-module";
import profile from "./modules/profile-module";
import roles from "./modules/roles-module";
import tags from "./modules/tags-module";
import reset from "./modules/reset-module";
import tasks from "./modules/tasks-module";
import tickets from "./modules/tickets-module";
import users from "./modules/users-module";
import venues from "./modules/venues-module";
import publicItems from "./modules/public-items-module";
import subscription from "./modules/subscription-module";
import reports from "./modules/reports-module";
import vendors from "./modules/vendors-module";
import guests from "./modules/guests-module";
import surveys from "./modules/surveys-module";
import speakers from "./modules/speakers-module";
import sponsors from "./modules/sponsors-module";

Vue.use(Vuex);


export default new Vuex.Store({
  modules: {
    auth,
    announcements,
    attendees,
    categories,
    items,
    profile,
    reset,
    roles,
    tags,
    tasks,
    tickets,
    users,
    venues,
    publicItems,
    subscription,
    reports,
    vendors,
    guests,
    surveys,
    speakers,
    sponsors
  }
});
