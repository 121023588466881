import DashboardLayout from "@/views/App/DashboardLayout.vue";

import auth from "@/middleware/auth";

// Components pages
const ListAttendeesPage = () =>
    import(/* webpackChunkName: "components" */ "@/views/Attendees/ListAttendees.vue");

const ListTicketsPage = () =>
    import(/* webpackChunkName: "components" */ "@/views/Attendees/ListTickets.vue");

let attendeesNav = {
    path: "/manage",
    component: DashboardLayout,
    name: "Attendees",
    children: [
        {
            path: "attendees/list-attendees",
            name: "List Guests",
            components: { default: ListAttendeesPage },
            meta: { middleware: auth }
        },
                {
            path: "attendees/list-tickets",
            name: "List Tickets",
            components: { default: ListTicketsPage },
            meta: { middleware: auth }
        }
    ]
};

export default attendeesNav;
