import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };

  return axios.get(`${url}/announcements`, options)
    .then(response => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta
      };
    });
}

function get(id, params) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    },
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/announcements/${id}`, options)
    .then(response => {
      let announcements = jsona.deserialize(response.data);
      delete announcements.links;
      return announcements;
    });
}

function add(announcements) {
  const payload = jsona.serialize({
    stuff: announcements,
    includeNames: null
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/announcements`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(announcements) {
  const payload = jsona.serialize({
    stuff: announcements,
    includeNames: []
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.patch(`${url}/announcements/${announcements.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/announcements/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy
};

