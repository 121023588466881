<template>
  <div :class="{ 'premium-overlay': enabled }">
    <el-tooltip
      placement="top"
      :content="badgeMessage"
      v-if="enabled"
    >
      <div class="col-auto order-md-1 order-first">
        <badge :type="badgeStyle" tag="a" :href="getPaymentPortal()" size="lg">
          <slot name="icon"><i class="fa fa-unlock"></i> {{ badgeLabel }}</slot>
        </badge>
      </div>
    </el-tooltip>
    <div :class="{ 'blur-slot': enabled }">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "base-premium-overlay",
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    badgeLabel: {
      type: String,
      default: 'Premium',
    },
    badgeMessage: {
      type: String,
      default: 'Feature available upon upgrade',
    },
    badgeStyle: {
      type: String,
      default: 'base',
      description: "Available types ( primary|info|danger|default|warning|success )"
    }
  },
};
</script>
