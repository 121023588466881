<template>
  <div class="row">
    <!-- Event details -->
    <div class="col-md-6 order-xl-1">
      <!-- Basic Informations -->
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-8">
            <p class="mb-0">Event overview</p>
          </div>
        </div>
        <div class="card-body">
          <base-input label="Name *" v-model="item.name" name="Name" required />
          <validation-error :errors="apiValidationErrors.name" />

          <base-input label="Summary *" name="Summary">
            <textarea
              v-model="item.description"
              name="summary"
              placeholder="Few words what are you about to do..."
              class="form-control"
              rows="7"
              max-length="340"
              required
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.description" />

          <base-html-input
            id="full-description"
            label="Full Description"
            v-model="item.additional_description"
          />
        </div>
      </card>

      <!-- Event details -->
      <card>
        <div slot="header" class="row align-items-end">
          <div class="col-auto">
            <p class="mb-0">Event details</p>
          </div>
        </div>
        <div class="card-body">
          <!-- Agenda -->
          <base-premium-overlay :enabled="isFreeUser()">
            <base-input label="Detailed Agenda">
              <el-tabs
                v-model="dynamicTabs.editableTabsValue"
                id="dynamic-tabs"
                type="card"
                :stretch="true"
                editable
                @edit="handleDynamicTabs"
              >
                <el-tab-pane
                  v-for="(tab_, idx) in dynamicTabs.editableTabs"
                  :key="tab_.sort"
                  :label="tab_.title"
                  :name="tab_.name"
                  :lazy="true"
                  :disabled="isFreeUser()"
                >
                  <base-input label="Name *" v-model="tab_.title" required />
                  <base-html-input
                    :id="'dynamic-tab' + idx"
                    label="Summary *"
                    v-model="tab_.content"
                    :disabled="isFreeUser()"
                  />
                  <base-input label="When *" required>
                    <flat-picker
                      slot-scope="{ blur }"
                      @on-open="customFocus()"
                      @on-close="blur"
                      :config="{
                        allowInput: true,
                        enableTime: true,
                        minDate: 'today',
                      }"
                      class="form-control datepicker"
                      v-model="tab_.date"
                    >
                    </flat-picker>
                  </base-input>
                </el-tab-pane>
              </el-tabs>
            </base-input>
          </base-premium-overlay>

          <!-- FAQs -->
          <base-premium-overlay :enabled="isFreeUser()">
            <base-input label="Frequently Asked Question (FAQs)">
              <el-tabs
                v-model="dynamicTabs.faqEditableTabsValue"
                id="dynamic-tabs"
                type="card"
                :stretch="true"
                editable
                @edit="handleFaqTabs"
              >
                <el-tab-pane
                  v-for="(tab_, idx) in dynamicTabs.faqEditableTabs"
                  :key="tab_.sort"
                  :label="tab_.title"
                  :name="tab_.name"
                  :lazy="true"
                  :disabled="isFreeUser()"
                >
                  <base-input label="Name *" v-model="tab_.title" required />
                  <base-html-input
                    :id="'dynamic-tab' + idx"
                    label="Summary *"
                    v-model="tab_.content"
                    :disabled="isFreeUser()"
                  />
                </el-tab-pane>
              </el-tabs>
            </base-input>
          </base-premium-overlay>
        </div>
      </card>

      <!-- T&C or Refund Policy settings-->
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-md-12 order-md-0 order-last">
            <p class="mb-0">Privacy policy</p>
          </div>
        </div>
        <div class="card-body mt--2 ml--2">
          <base-html-input
            id="refund-policy"
            label="Terms & Policies (e.g refund, delayed payments, instalments etc.."
            v-model="item.refund_policy"
            placeholder="Provide any terms and conditions or refund policy which is related with your event attendees"
          />
        </div>
      </card>
    </div>

    <div class="col-md-6 order-xl-1">
      <!-- Timing details -->
      <card>
        <div slot="header" class="row align-items-end">
          <div class="col-auto">
            <p class="mb-0">Timing details</p>
          </div>
        </div>

        <div class="card-body">
          <base-input label="Timezone *">
            <el-select
              v-model="item.timezone"
              filterable
              prepend-icon="fas fa-user"
              placeholder="Select..."
            >
              <el-option
                v-for="item in allTimezones"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>

          <base-input label="Begin at *">
            <flat-picker
              slot-scope="{ blur }"
              @on-open="customFocus()"
              @on-close="blur"
              :config="{
                allowInput: true,
                enableTime: true,
              }"
              class="form-control datepicker"
              v-model="item.start_at"
            >
            </flat-picker>
          </base-input>
          <validation-error :errors="apiValidationErrors.start_at" />

          <base-input label="End at *">
            <flat-picker
              slot-scope="{ blur }"
              @on-open="customFocus()"
              @on-close="blur"
              :config="{
                allowInput: true,
                enableTime: true,
                defaultTime: item.start_at,
                minDate: item.start_at,
              }"
              class="form-control datepicker"
              v-model="item.end_at"
            >
            </flat-picker>
          </base-input>
          <validation-error :errors="apiValidationErrors.end_at" />
        </div>
      </card>

      <!-- Happening details -->
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-8">
            <p class="mb-0">Event information</p>
          </div>
        </div>

        <div class="card-body">
          <!-- Type -->
          <base-input label="Type *">
            <div class="row align-items-start mt-2">
              <base-radio
                name="physical"
                class="col-md-3 mb-2"
                v-model="item.event_type"
              >
                In-Person
              </base-radio>
              <base-radio
                name="virtual"
                class="col-md-3 mb-2"
                v-model="item.event_type"
              >
                Virtual
              </base-radio>
              <base-radio
                name="hybrid"
                class="col-md-3"
                v-model="item.event_type"
              >
                Hybrid
              </base-radio>
              <base-radio
                name="tba"
                class="col-md-3 ml--2 mb-2"
                v-model="item.event_type"
              >
                To-Be Announced
              </base-radio>
            </div>
          </base-input>

          <!-- Attendance-->
          <div class="mt-3">
            <base-input
              label="Unlimited Participants *"
              v-if="item.event_type === 'virtual'"
            >
              <base-switch
                class="mr-1"
                v-model="unlimitedAttendees"
                :description="{
                  show: true,
                  content: 'Host a digital event without guests limitation',
                }"
              />
            </base-input>

            <base-input
              v-show="
                !(
                  item.event_type === 'tba' ||
                  (item.event_type === 'virtual' && unlimitedAttendees)
                )
              "
              :label="'Attendance *'"
              prepend-icon="fas fa-users"
              placeholder="Add the maximum number of participants"
              v-model="item.total_capacity"
              type="number"
              name="Attendance"
              min="0"
              :required="
                !(
                  item.event_type === 'tba' ||
                  (item.event_type === 'virtual' && unlimitedAttendees)
                )
              "
            />
            <base-input
              v-show="
                item.event_type === 'virtual' || item.event_type === 'hybrid'
              "
              label="Link *"
              prepend-icon="fas fa-link"
              placeholder="Enter a link for your event"
              v-model="item.virtual_link"
              name="Link"
              :required="
                item.event_type === 'virtual' || item.event_type === 'hybrid'
              "
            />

            <base-input
                v-if="isDeveloper()"
              v-show="
                item.event_type === 'physical' || item.event_type === 'hybrid'
              "
              label="Enable multi-location for this event"
            >
              <base-switch
                class="mr-0"
                v-model="item.multiple_addresses"
                :description="{
                  show: false,
                  content:
                    'This event will take place in multiple location or even different countries during the same period',
                }"
                type="danger"
              />
            </base-input>

            <div v-show="!item.multiple_addresses">
              <base-mapbox
                @getCoordinatesValues="getCoordinates($event)"
                :long="item.venues.long"
                :lat="item.venues.lat"
                label="Venue Location"
                v-if="
                  item.venues &&
                  item.venues.long &&
                  item.venues.lat &&
                  (item.event_type === 'physical' ||
                    item.event_type === 'hybrid')
                "
              />
              <base-mapbox
                @getCoordinatesValues="getCoordinates($event)"
                label="Venue Location"
                v-if="
                  (!item.venues || !item.venues.long || !item.venues.lat) &&
                  (item.event_type === 'physical' ||
                    item.event_type === 'hybrid')
                "
              ></base-mapbox>
            </div>
            <base-input
              label="Venues *"
              v-if="
                (item.event_type === 'physical' ||
                  item.event_type === 'hybrid') &&
                item.multiple_addresses &&
                all_venues.length > 0
              "
            >
              <el-select
                v-model="venues"
                multiple
                placeholder="Select..."
                @change="venueSelected()"
              >
                <el-option
                  v-for="single_venue in all_venues"
                  :key="single_venue.id"
                  :value="single_venue.id"
                  :label="single_venue.name"
                >
                </el-option>
              </el-select>
            </base-input>
            <base-alert
              class="ml-3"
              type="base"
              icon="ni ni-like-2"
              v-show="item.event_type === 'tba'"
              ><i class="fa fa-bullhorn"></i>
              <strong> To-Be Announced:</strong>
              This event needs to be updated accordingly!
            </base-alert>
          </div>
        </div>
      </card>

      <!-- General details -->
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-8">
            <p class="mb-0">General details</p>
          </div>
        </div>

        <div class="card-body">
          <base-premium-overlay :enabled="isFreeUser()">
            <base-input
              label="Organizer *"
              name="Organizer"
              prepend-icon="fa fa-headset"
              v-model="item.organizer"
              required
              :disabled="isFreeUser()"
            />
          </base-premium-overlay>
          <base-input label="Tags">
            <el-select
              v-model="tags"
              multiple
              placeholder="Select..."
              @change="tagSelected()"
            >
              <el-option
                v-for="single_tag in all_tags"
                :key="single_tag.id"
                :value="single_tag.id"
                :label="single_tag.name"
              >
              </el-option>
            </el-select>
            <a @click="showAddModal = true" class="link-add-tag">
              <small style="font-weight: bolder">+ New Tag</small>
            </a>
          </base-input>

          <base-input label="Category">
            <el-select
              name="category"
              v-model="item.category.id"
              prepend-icon="fas fa-user"
            >
              <el-option
                v-for="single_category in all_categories"
                :key="single_category.id"
                :value="single_category.id"
                :label="single_category.name"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
      </card>
    </div>

    <!-- Add Tag - Modal -->
    <modal
      :show.sync="showAddModal"
      modal-classes="modal-secondary"
      header-classes="calendar-modal-header"
    >
      <template slot="footer">
        <base-button type="secondary" @click="showAddModal = false"
          >Close</base-button
        >
      </template>

      <div class="calendar-modal-section">
        <add-tag
          @tagAdded="tagAddedSuccessfully($event)"
          v-if="showAddModal"
        ></add-tag>
      </div>
    </modal>
    <!-- Clone Event - Modal -->
    <modal
      :show.sync="showCloneItemModal"
      modal-classes="modal-secondary"
      header-classes="calendar-modal-header"
    >
      <h6 slot="header" class="modal-title mt-1">
        <i class="fa fa-calendar-day" />
        When is this event happening?
      </h6>

      <div v-if="!loading" class="clone-modal-section">
        <base-input label="Begin at *">
          <flat-picker
            slot-scope="{ blur }"
            @on-open="customFocus()"
            @on-close="blur"
            :config="{
              allowInput: true,
              enableTime: true,
              minDate: 'today',
            }"
            class="form-control datepicker"
            v-model="item.start_at"
          >
          </flat-picker>
        </base-input>
        <validation-error :errors="apiValidationErrors.start_at" />

        <base-input label="End at *">
          <flat-picker
            slot-scope="{ blur }"
            @on-open="customFocus()"
            @on-close="blur"
            :config="{
              allowInput: true,
              enableTime: true,
              defaultTime: item.start_at,
              minDate: item.start_at,
            }"
            class="form-control datepicker"
            v-model="item.end_at"
          >
          </flat-picker>
        </base-input>
        <validation-error :errors="apiValidationErrors.end_at" />

        <base-alert type="base">
          <small class="text-muted text-white">
            <i class="fa fa-info-circle" />
            If you have attached ticket(s) for this Event please update the
            "Price" - "Total Quantity" - "On-sale dates" under the Ticketing
            section after saving this event ;-)
          </small>
        </base-alert>
      </div>
      <div v-else class="text-center mt-3">
        <slot name="loading">
          <i class="fas fa-spinner fa-spin"></i>
        </slot>
      </div>

      <template slot="footer">
        <base-button
          type="btn btn-outline-success"
          class="ml-auto"
          @click="showCloneItemModal = false"
          :disabled="loading"
        >
          Ready, continue editing
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {
  Select,
  Option,
  Tabs,
  TabPane,
  Collapse,
  CollapseItem,
  Timeline,
  TimelineItem,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
} from "element-ui";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseMapbox from "@/components/Mapbox/BaseMapbox.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import flatPicker from "vue-flatpickr-component";
import moment from "moment";
import AddTag from "@/components/AddTag/AddTag.vue";
import BasePremiumOverlay from "@/components/Premium/BasePremiumOverlay.vue";

// import { Slider } from "vue-color";
export default {
  name: "edit-event-details",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    ["flatPicker"]: flatPicker,
    ValidationError,
    BaseInput,
    BaseMapbox,
    AddTag,
    BasePremiumOverlay,
    // "color-picker": Slider,
  },
  mixins: [formMixin],
  data() {
    return {
      updatedItem: { id: null },
      unlimitedAttendees: false,
      tempSelectedTickets: null,
      itemToUpdate: null,
      modals: {
        openBooking: false,
        openVideo: false,
      },
      hover: false,
      itemId: 0,
      all_categories: [],
      all_tags: [],
      all_venues: [],
      all_tickets: [],
      selected_tickets: [],
      tags: [],
      venues: [],
      tickets: [],
      allTimezones: [],
      start_at: "",
      end_at: "",
      scheduled_at: "",
      status: "",
      location: null,
      actions: {
        uploadHero: false,
      },
      showAddModal: false,
      showCloneItemModal: false,
      showAddTicketModal: false,
      showPreviewModal: false,
      showSelectAnnouncementTimeModal: false,
      showEditAnnouncementTimeModal: false,
      cloneState: false,
      updateAndPreview: false,
      refundPolicy: null,
      color: {
        base: "#5e72e4ff",
      },
      all_policies: [
        {
          id: 1,
          label:
            "1 Day - Attendees can receive refunds up to 1 day before the event start date.",
        },
        {
          id: 2,
          label:
            "7 Days - Attendees can receive refunds up to 7 days before the event start date.",
        },
        {
          id: 3,
          label:
            "30 Days - Attendees can receive refunds up to 30 days before the event start date.",
        },
        {
          id: 4,
          label:
            "I don't want to specify my refund policy now. I will respond to attendee refund requests on case by case basis.",
        },
        { id: 5, label: "No refunds. We do not offer refunds." },
      ],
      announcements: [],
      newAnnouncement: null,
      announcementToEdit: {},
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    dynamicTabs: {
      type: Object,
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    activeName: {
      type: String,
      default: "",
    },
  },
  created() {
    this.getCategories();
    this.getTags();
    this.getVenues();
    this.allTimezones = moment.tz.names();
    this.cloneState = this.$route.query.clone === "true";
    this.showCloneItemModal = this.cloneState;
  },
  mounted() {
    this.start_at = this.item.start_at;
    this.end_at = this.item.end_at;

    if (this.item.total_capacity == -1) {
      this.item.total_capacity = undefined;
      this.unlimitedAttendees = true;
      this.unlimitedAttendeesChanged();
    }
  },
  watch: {
    unlimitedAttendees: {
      handler: function () {
        this.unlimitedAttendeesChanged();
      },
    },
  },
  methods: {
    async getCategories() {
      // let params = {
      //   filter: {
      //     ...(this.user ? { user_id: this.user.id } : { user_id: 0 }),
      //   },
      // };
      try {
        await this.$store.dispatch("categories/list");
        this.all_categories = this.$store.getters["categories/dropdown"];
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getTags() {
      let params = {
        filter: {
          ...(this.user ? { user_id: this.user.id } : { user_id: 0 }),
        },
      };
      try {
        await this.$store.dispatch("tags/list", params);
        this.all_tags = this.$store.getters["tags/dropdown"];
        this.item.tags.forEach((tag) => {
          this.tags.push(tag.id);
        });
        this.$emit("tagChanged", this.tags);
      } catch (error) {
        console.log(error);

        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    tagSelected() {
      this.$emit("tagChanged", this.tags);
    },
    async getVenues() {
      let params = {
        filter: {
          ...(this.user ? { user_id: this.user.id } : { user_id: 0 }),
        },
      };
      try {
        await this.$store.dispatch("venues/list", params);
        this.all_venues = this.$store.getters["venues/dropdown"];
        if (Array.isArray(this.item.venues)) {
          this.item.venues?.forEach((venue) => {
            this.venues.push(venue.id);
          });
        } else if (this.item.venues) {
          this.venues.push(this.item.venues.id);
        }
        this.$emit("venueChanged", this.venues);
      } catch (error) {
        console.log(error);

        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error?.response?.data?.errors);
      }
    },
    venueSelected() {
      this.$emit("venueChanged", this.venues);
    },
    customFocus() {
      setTimeout(function () {
        document.getElementsByClassName("flatpickr-calendar open")[0].focus();
      }, 100);
    },
    getCoordinates(values) {
      this.$emit("locationChanged", values);
    },
    unlimitedAttendeesChanged() {
      this.$emit("unlimitedAttendeesChanged", this.unlimitedAttendees);
    },
    handleDynamicTabs(tabName, action) {
      if (action === "add") {
        let newTabName = ++this.dynamicTabs.tabIndex + "";
        this.dynamicTabs.editableTabs.push({
          title: "New Agenda",
          name: newTabName,
          content: "New agenda content",
          date: null,
        });
        this.dynamicTabs.editableTabsValue = newTabName;
      }
      if (action === "remove") {
        let tabs = this.dynamicTabs.editableTabs;
        let activeName = this.dynamicTabs.editableTabsValue;

        if (activeName === tabName) {
          tabs.forEach((tab, index) => {
            if (tab.name === tabName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }

        this.dynamicTabs.editableTabsValue = activeName;
        this.dynamicTabs.editableTabs = tabs.filter(
          (tab) => tab.name !== tabName
        );
      }
    },
    handleFaqTabs(tabName, action) {
      if (action === "add") {
        let newTabName = ++this.dynamicTabs.faqTabIndex + "";
        this.dynamicTabs.faqEditableTabs.push({
          title: "New FAQ Section",
          name: newTabName,
          content: "New FAQ content",
        });
        this.dynamicTabs.faqEditableTabsValue = newTabName;
      }
      if (action === "remove") {
        let tabs = this.dynamicTabs.faqEditableTabs;
        let activeName = this.dynamicTabs.faqEditableTabsValue;

        if (activeName === tabName) {
          tabs.forEach((tab, index) => {
            if (tab.name === tabName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }

        this.dynamicTabs.faqEditableTabsValue = activeName;
        this.dynamicTabs.faqEditableTabs = tabs.filter(
          (tab) => tab.name !== tabName
        );
      }
    },
    tagAddedSuccessfully(newTag) {
      this.all_tags.push({
        color: newTag.color,
        name: newTag.name,
        id: newTag.id,
      });
      this.showAddModal = false;
    },
  },
};
</script>

<style lang="scss">
@import "./../../../node_modules/flatpickr/dist/flatpickr.min.css";

.quillWrapper {
  border-radius: 0.65rem !important;
}

.ql-editor {
  font-family: inherit !important;
  font-size: 14px !important;
  border: 0 !important;
}
</style>
