<template>
  <div class="container-fluid mt-3">
    <!-- Back  -->
    <!-- <el-page-header @back="goBack" style="color: #5e72e4;"/> -->
    <back-button @click="goBack" />

    <div class="row justify-content-center mt-3">
      <div class="col-xl-6 order-xl-1">
        <transition name="el-zoom-in-center">
          <card>
            <div slot="header" class="row align-items-center">
              <div class="col-md-8">
                <h3 class="mb-0">Begin the journey</h3>
              </div>
            </div>

            <!-- EVENT FORM -->
            <form ref="profile_form" @submit.prevent="handleSubmit">
              <div class="card-body">
                <!-- Name -->
                <base-input
                  label="Name *"
                  name="Name"
                  type="text"
                  placeholder="Name your event"
                  successMessage="Choose a unique and descriptive 'Name' for your event. This will help you stand out there."
                  v-model="item.name"
                  required
                />
                <validation-error :errors="apiValidationErrors.name" />

                <base-input
                  type="text"
                  label="Summary *"
                  success-message="Include an easy-to-read, short description for your event, so users can quickly understand if this is the right event for them. This caption is on prominent position of your event from social posts to the landing page etc. (You can always add a long description after you create this event)"
                >
                  <textarea
                    v-model="item.description"
                    placeholder="Few words what are you about to do..."
                    class="form-control"
                    rows="3"
                    name="Description"
                    required
                  >
                  </textarea>
                </base-input>
                <validation-error :errors="apiValidationErrors.description" />

                <div class="row">
                  <div class="col-md-6">
                    <base-input label="Timezone" required>
                      <el-select
                        v-model="item.timezone"
                        filterable
                        prepend-icon="fas fa-user"
                        placeholder="Select..."
                      >
                        <el-option
                          v-for="item in allTimezones"
                          :key="item"
                          :label="item"
                          :value="item"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>

                  <div class="col-md-6">
                    <base-input label="Begin at *">
                      <flat-picker
                        slot-scope="{ blur }"
                        @on-open="customFocus()"
                        @on-close="blur"
                        :config="{
                          allowInput: true,
                          enableTime: true,
                          minDate: 'today',
                        }"
                        class="form-control datepicker"
                        type="datetime-local" name="datetime"
                        v-model="item.start_at"
                      >
                      </flat-picker>
                    </base-input>
                    <validation-error :errors="apiValidationErrors.start_at" />
                  </div>
                </div>
                <base-input label="Category *">
                  <el-select
                    name="category"
                    v-model="item.category.id"
                    prepend-icon="fas fa-user"
                    placeholder="Select..."
                    :filterable="true"
                  >
                    <el-option
                      v-for="single_category in allCategories"
                      :key="'A' + single_category.id"
                      :value="single_category.id"
                      :label="single_category.name"
                    >
                    </el-option>
                  </el-select>
                </base-input>

                <base-input label="Tags *">
                  <el-select
                    v-model="tags"
                    multiple
                    placeholder="Select..."
                    :filterable="true"
                  >
                    <el-option
                      v-for="single_tag in allTags"
                      :key="'B' + single_tag.id"
                      :value="single_tag.id"
                      :label="single_tag.name"
                    >
                    </el-option>
                  </el-select>
                  <a @click="showAddModal = true" class="link-add-tag">
                    <small style="font-weight: bolder">+ New Tag</small>
                  </a>
                </base-input>

                <!-- Status -->
                <!-- <base-input label="Status *">
                  <base-radio
                    name="private"
                    class="mb-3"
                    v-model="item.status"
                    style="display: inline-block;"
                  >
                    Private
                  </base-radio>
                  <base-radio
                    name="draft"
                    class="mb-3"
                    v-model="item.status"
                    style="padding-left: 150px; display: inline-block;"
                  >
                    Draft
                  </base-radio>
                  <base-radio
                    name="public"
                    class="mb-3"
                    v-model="item.status"
                    style="padding-left: 140px; display: inline-block;"
                  >
                    Public
                  </base-radio>
                </base-input>
                <base-input label="Available to Public search?">
                  <base-switch
                    class="mr-1"
                    v-model="item.public_search"
                  ></base-switch>
                </base-input> -->
              </div>
            </form>
          </card>
        </transition>
      </div>

      <div class="col-xl-6 order-xl-1">
        <preview-event-item :event-item="items" v-on:saveEvent="handleSubmit" />
      </div>
    </div>
    <modal
      :show.sync="showAddModal"
      modal-classes="modal-secondary"
      header-classes="calendar-modal-header"
    >
      <template slot="footer">
        <base-button type="secondary" @click="showAddModal = false"
          >Close</base-button
        >
      </template>

      <div class="calendar-modal-section">
        <add-tag
          @tagAdded="tagAddedSuccessfully($event)"
          v-if="showAddModal"
        ></add-tag>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment-timezone";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option } from "element-ui";
import flatPicker from "vue-flatpickr-component";
// components
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseRadio from "@/components/Inputs/BaseRadio.vue";
import PreviewEventItem from "@/views/Events/Management/PreviewEventItem.vue";
import Modal from "@/components/Modal";
import AddTag from "@/components/AddTag/AddTag.vue";
import BackButton from "@/components/BackButton.vue";

export default {
  name: "AddEventItem",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseButton,
    //  BaseRadio,
    PreviewEventItem,
    ValidationError,
    flatPicker,
    BaseInput,
    Modal,
    AddTag,
    BackButton
  },
  mixins: [formMixin],
  data() {
    return {
      loading: false,
      file: null,
      allCategories: [],
      allTags: [],
      allTimezones: [],
      tags: [],
      item: {
        type: "items",
        name: "",
        description: "",
        status: "private",
        public_search: false,
        start_at: new Date(),
        end_at: null,
        category: {
          type: "categories",
          id: null,
        },
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        tags: [],
        relationshipNames: ["category", "tags"],
        user: null,
      },
      showAddModal: false,
    };
  },

  created() {
    this.auth();
    this.getCategories();
    this.getTags();
    this.allTimezones = moment.tz.names();
  },
  computed: {
    items() {
      return {
        item: this.item,
        tags: this.tags,
        dates: [],
        metadata: {
          allTags: this.allTags,
          allCategories: this.allCategories,
        },
        loading: false
      };
    },
  },
  methods: {
    customFocus() {
      setTimeout(function() {
        document.getElementsByClassName("flatpickr-calendar")[0].focus();
      }, 10);
    },
    async auth() {
      try {
        // Fetch User data
        // await this.$store.dispatch("profile/me");
        this.user = await { ...this.$store.getters["profile/me"] };
      } catch (error) {
        // Notification
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong with your account!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getCategories() {
      try {
        await this.$store.dispatch("categories/list");
        this.allCategories = await this.$store.getters["categories/dropdown"];

        if (this.allCategories && this.allCategories.length) {
          this.item.category.id = this.allCategories[0].id;
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async getTags() {
      let params = {
        filter: {
          ...{ user_id: this.user ? this.user.id : 0 },
        },
      };

      try {
        await this.$store.dispatch("tags/list", params);
        this.allTags = await this.$store.getters["tags/dropdown"];
        if (this.allTags && this.allTags.length) {
          this.tags.push(this.allTags[0].id);
          this.item.tags = [];

          this.tags.forEach((tag) => {
            this.item.tags.push({
              id: tag,
              type: "tags",
            });
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    tagAddedSuccessfully(newTag) {
      this.allTags.push({
        color: newTag.color,
        name: newTag.name,
        id: newTag.id,
      });
      newTag = undefined;
      this.showAddModal = false;
    },
    goBack() {
      this.$router.push({ name: "List Events" });
    },
    redirect(item) {
      this.$router.push({
        name: "Edit Event",
        params: { id: item.id },
      });
    },
    // quickPreview(values) {
    //   console.log("QuickPreviewValues:::Values", values);

    //   let duration = values?.duration ?? "0.30";
    //   let unit = values?.timeunit ?? "m";

    //   console.log("QuickPreviewValues:::Duration", parseFloat(duration));

    //   let unit_ = "minutes";
    //   if (unit === "h") {
    //     unit_ = "hours";
    //   }
    //   console.log("QuickPreviewValues:::Unit", unit);

    //   this.item.end_at = moment(this.item.start_at, "DD-MM-YYYY hh:mm:ss")
    //     .add(parseFloat(duration), unit_)
    //     .format("DD/MM/YYYY hh:mm:ss");
    // },
    async handleSubmit(values) {
      // if (this.user.roles[0].name === "member") {
      //   this.$notify({
      //     type: "danger",
      //     message: "Oops, you are not authorized to do this action.",
      //   });
      //   return;
      // }
      this.item.event_type = values.event_type;
      if (this.item.event_type === "physical") {
        delete this.item.link;
      }
      if (
        this.item.event_type === "physical" ||
        this.item.event_type === "hybrid"
      ) {
        this.item.venue = {
          address_name: values.location.address,
          long: values.location.center[0],
          lat: values.location.center[1],
        };
      }
      else if (this.item.event_type === "tba"){
        this.item.total_capacity = -1;
      }
      else if (this.item.event_type === "virtual" && values.unlimitedAttendees){
        this.item.total_capacity = -1;
      }

      this.item.description = this.item.description;
      this.item.start_at = moment(this.item.start_at).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.item.end_at = moment(this.item.start_at)
        .add(values.duration, values.timeunit)
        .format("YYYY-MM-DD HH:mm:ss");
      this.item.tags = [];
      this.tags.forEach((tag) => {
        this.item.tags.push({
          id: tag,
          type: "tags",
        });
      });
      this.items.loading = true;
      try {
        delete this.item.id;
        await this.$store.dispatch("items/add", this.item);
        this.item = await this.$store.getters["items/item"];
        this.items.loading = false;
        this.$notify({
          type: "success",
          message: "Item added successfully.",
        });

        this.redirect(this.item);
      } catch (error) {
        this.items.loading = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.item.id) {
          await this.$store.dispatch("items/destroy", this.item.id);
        }

        this.item.start_at = new Date();
      }
    },
  },
};
</script>

<style>
@import "./../../../../node_modules/flatpickr/dist/flatpickr.css";
</style>
