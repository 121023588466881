import Vue from "vue";
import store from "@/store";
import moment from "moment-timezone";

import { upperFirst } from "lodash";
import { setOptions, bootstrap } from 'vue-gtag'

/**
 * Global Mixins: This is a different type of mixin that is defined in the Main.js file of any
 * Vue project. It affects all Vue components in an application so the core Vue team advises that
 * it be used with caution and tested well before any changes are added or included
 */
Vue.mixin({
    methods: {
        isDeveloper() {
            return this.getUserRole() === 'developer' // only for devs;
        },
        isSuperUser() {
            const suRoles = ['admin', 'moderator'];
            const loggedRole = this.getUserRole(); //member

            return suRoles.includes(loggedRole);
        },
        isGuestUser() {
            const freemiumRoles = ['guest', 'member'];
            const loggedRole = this.getUserRole(); //member

            return freemiumRoles.includes(loggedRole);
        },
        isFreeUser() {
            const freemiumRoles = ['member', 'creator'];
            const loggedRole = this.getUserRole(); //creator

            return freemiumRoles.includes(loggedRole);
        },
        isFreemiumUser() {
            const freemiumRoles = ['creator-plus'];
            const loggedRole = this.getUserRole(); //creator-plus

            return freemiumRoles.includes(loggedRole);
        },
        isPremiumUser() {
            const premiumRoles = ['moderator', 'organizer', 'planner'];
            const loggedRole = this.getUserRole(); //organizer

            return premiumRoles.includes(loggedRole);
        },
        isProfessionalUser() {
            const premiumRoles = ['admin', 'planner'];
            const loggedRole = this.getUserRole(); //planner
            return premiumRoles.includes(loggedRole);
        },
        getUserRole: function (me = null) {
            try {
                me = store.getters["profile/me"];
            } catch (error) {
                console.error('Fetching user profile failed, please try to logout/login again -', error);
            }
            return me?.roles[0]?.name ?? 'guest';
        },
        getPaymentPortal: function () {
            return process.env.VUE_APP_BASE_URL + '/users/user-profile';
        },
        getCustomerPortal: function () {
            const me = store.getters["profile/me"];
            return process.env.VUE_APP_STRIPE_CUSTOMER_PORTAL + '?prefilled_email=' + me.email ?? '#';
        },
        getCountriesList() {
            const shortlistCountries = moment.tz.countries();
            const countryName = new Intl.DisplayNames(['en'], {type: 'region' });

            let fullListCountries= [];
            _.forEach(shortlistCountries, function(shortCountry) {
                fullListCountries.push(countryName.of(shortCountry));
            })
            return fullListCountries;
        },
        getEventFullStatusTypes(eventType) {
            switch(eventType) {
                case 'tba':
                    return "To-Be Announced"
                case 'physical':
                    return "In-Person"
                default:

                return upperFirst(eventType);
            }
        },
        setGoogleAnalytics: function (userKey) {
            if (userKey){
                // Users G-Accounts
                setOptions({
                    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS },
                    includes: [
                        { id: userKey}
                    ]
                })
            } else {
                // Global App G-Account
                setOptions({
                    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS }
                })
            }
        },
    }
});