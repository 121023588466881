import service from "@/store/services/reports-service";

const state = {
  list: {},
  report: {},
  meta: {}
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, report) => {
    state.report = report;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, {id, params}) {
    return service.get(id, params).then(role => {
      commit("SET_RESOURCE", role);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then(report => {
      commit("SET_RESOURCE", report);
    });
  },
  update({ commit, dispatch }, params) {
    return service.update(params).then(report => {
      commit("SET_RESOURCE", report);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  }
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  report: state => state.report,
  dropdown: state => {
    return state.list.map(report => ({
      id: report.id,
      name: report.name
    }));
  }
};

const reports = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default reports;
