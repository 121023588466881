import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const uuid = JSON.parse(JSON.stringify(params.uuid))
  delete params.uuid;
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };
  return axios.get(`${url}/public/items/${uuid}/calendar`, options).then(response => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta
    };
  });
}

function get(id, params) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    },
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/public/items/${id}`, options).then(response => {
      return jsona.deserialize(response.data);
    });
}

function getAddress(center, access_token) { 
  return axios
    .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${center[0]},${center[1]}.json?access_token=${access_token}`)
    .then(response => {return response.data});
}

export default {
  list,
  get, 
  getAddress
};
