import service from "@/store/services/announcements-service";

const state = {
  list: {},
  announcements: {},
  meta: {}
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, announcements) => {
    state.announcements = announcements;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, {id, params}) {
    return service.get(id, params).then(role => {
      commit("SET_RESOURCE", role);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then(announcements => {
      commit("SET_RESOURCE", announcements);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then(announcements => {
      commit("SET_RESOURCE", announcements);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  }
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  announcements: state => state.announcements,
  dropdown: state => {
    return state.list.map(announcements => ({
      id: announcements.id,
      name: announcements.name
    }));
  }
};

const announcements = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default announcements;
