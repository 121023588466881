<template>
  <div class="card-body">
    <form @submit.prevent="handleSubmit">
      <base-input label="Name *" prepend-icon="fas fa-user" v-model="ticket.name" />
      <validation-error :errors="apiValidationErrors.name" />    
      <base-input label="Description *" prepend-icon="fas fa-user" v-model="ticket.description" />
      <validation-error :errors="apiValidationErrors.description" />
      <div class="my-4">
        <base-button
          type="button"
          class="btn btn-sm btn-primary"
          native-type="submit"
          :disabled="!ticket.name || !ticket.description"
        >
          <i class="fa fa-save"/> Create Ticket
        </base-button>
      </div>
    </form>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
export default {
  name: "add-ticket",
  mixins: [formMixin],
  components: {
    ValidationError
  },
  data() {
    return {
      ticket: {
        type: "tickets",
        name: null,
        description: null
      }
    };
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$store.dispatch("tickets/add", this.ticket)
          .then(() => (this.ticket = this.$store.getters["tickets/ticket"]));
        this.$notify({
          type: "success",
          message: "Ticket added successfully.",
        });
        this.$emit("ticketAdded", this.ticket);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    }
  },
};
</script>
