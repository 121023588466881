import service from '@/store/services/public-items-service';

const state = {
  list: {},
  item: {},
  included: [],
  meta: {},
  url: null,
  address: null
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, item) => {
    state.item = item;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  SET_ADDRESS: (state, address) => {
    state.address = address;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, {id, params}) {
    return service.get(id, params)
      .then((item) => {
        commit('SET_RESOURCE', item);
      });
  },

  getAddress({commit, dispatch}, {center, access_token}) {
    return service.getAddress(center, access_token)
      .then((address) => {
        commit('SET_ADDRESS', address);
      });
  }  
};

const getters = {
  list: state       => state.list,
  listTotal: state  => state.meta.page.total,
  item: state       => state.item,
  url: state        => state.url,
  address: state    => state.address
};

const publicItems = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default publicItems;
