// Fontawesome 6.1.x Icons Collection
import { library } from '@fortawesome/fontawesome-svg-core';

import { faUserSecret} from '@fortawesome/free-solid-svg-icons';
import { faShareSquare } from '@fortawesome/free-regular-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';

library.add(faUserSecret);
library.add(faShareSquare);
library.add(faXTwitter);

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const GlobalIcons = {
    install(Vue) {
        Vue.component('font-awesome-icon', FontAwesomeIcon)
    }
};

export default GlobalIcons;
