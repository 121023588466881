<template>
  <div>
    <transition v-if="items.item && !(items.item.name && items.item.description)"
                name="el-zoom-in-center">
      <card header-classes="bg-transparent">
        <h3 slot="header" class="mb-0">Start typing to preview</h3>
        <h5 class="transition-box text-center mb-2">
          Please fill up all the necessary fields to see a Quick Preview of your next event :-)
        </h5>
        <template>
          <el-skeleton :rows="6" animated>
            <template slot="template">
              <div style="padding: 14px;">
                  <el-skeleton-item variant="text" style="margin-right: 0;width: 50%;" /><br>
                  <el-skeleton-item variant="text" style="margin-right: 0;width: 30%;" /><br>
                  <el-skeleton-item variant="text" style="margin-right: 0;width: 40%;" /><br>
                  <el-skeleton-item variant="text" style="margin-right: 0;width: 100%;" /><br>
                  <el-skeleton-item variant="text" style="margin-right: 0;width: 100%;" /><br>
                  <el-skeleton-item variant="text" style="margin-right: 0;width: 30%;" /><br>
                  <el-skeleton-item variant="text" style="margin-right: 0;width: 40%;" /><br>
                  <el-skeleton-item variant="text" style="margin-right: 0;width: 30%;" /><br>
                  <el-skeleton-item variant="text" style="margin-right: 1%;width: 32%;" />
                  <el-skeleton-item variant="text" style="margin-right: 1%;width: 32%;" />
                  <el-skeleton-item variant="text" style="margin-right: 1%;width: 32%;" />
                <el-skeleton-item variant="text" style="margin-right: 0;width: 40%;" /><br>
                <el-skeleton-item variant="text" style="margin-right: 0;width: 40%;" /><br>
                <el-skeleton-item variant="text" style="margin-right: 0;width: 100%;" /><br>
                <el-skeleton-item variant="text" style="margin-right: 0;width: 100%;" /><br>
              </div>
            </template>
          </el-skeleton>
        </template>
      </card>
    </transition>

    <div v-else>
      <card header-classes="bg-transparent" name="details">
        <div slot="header" class="row align-items-center">
          <div class="col-md-8">
            <h3 class="mb-0">Provide more details</h3>
          </div>
        </div>
        <div class="card-body">
          <!-- Duration-->
          <div class="row">
            <!-- Unit-->
            <div class="col-md-4">
              <base-input label="Unit *">
                <el-select
                    @change="item.duration = 0"
                    name="timeunit"
                    v-model="item.timeunit"
                    prepend-icon="fas fa-user"
                    placeholder="Select..."
                >
                  <el-option key="m" value="m" label="Minutes"></el-option>
                  <el-option key="h" value="h" label="Hours"></el-option>
                  <el-option key="d" value="d" label="Days"></el-option>
                </el-select>
              </base-input>
            </div>
            <!-- Duration-->
            <div class="col-md-8">
              <base-input label="Duration *" name="Duration" required>
                <el-select
                    v-if="item.timeunit === 'h'"
                    name="Duration"
                    v-model="item.duration"
                    prepend-icon="fas fa-user"
                    placeholder="How long it will last approx."
                    :filterable="true"
                ><el-option v-for="val in hourOptions" :key="val" :value="val" :label="val"></el-option>
                  <!-- <el-option key="1" value="1" label="1"></el-option>
                  <el-option key="2" value="2" label="2"></el-option>
                  <el-option key="3" value="3" label="3"></el-option>
                  <el-option key="4" value="4" label="4"></el-option>
                  <el-option key="5" value="5" label="5"></el-option>
                  <el-option key="6" value="6" label="6"></el-option>
                  <el-option key="7" value="7" label="7"></el-option>
                  <el-option key="8" value="8" label="8"></el-option>
                  <el-option key="9" value="9" label="9"></el-option>
                  <el-option key="10" value="10" label="10"></el-option>
                  <el-option key="11" value="11" label="11"></el-option>
                  <el-option key="12" value="12" label="12"></el-option> -->
                </el-select>

                <el-select
                    v-else-if="item.timeunit === 'm'"
                    name="timeunit"
                    v-model="item.duration"
                    prepend-icon="fas fa-user"
                    placeholder="How long it will last"
                    :filterable="true"
                >
                  <el-option key="15" value="15" label="15"></el-option>
                  <el-option key="30" value="30" label="30"></el-option>
                  <el-option key="45" value="45" label="45"></el-option>
                  <el-option key="60" value="60" label="60"></el-option>
                  <el-option key="75" value="75" label="75"></el-option>
                  <el-option key="90" value="90" label="90"></el-option>
                </el-select>

                <el-select
                    v-else-if="item.timeunit === 'd'"
                    name="timeunit"
                    v-model="item.duration"
                    prepend-icon="fas fa-user"
                    placeholder="How long it will last"
                    :filterable="true"
                >
                  <el-option key="1" value="1" label="1"></el-option>
                  <el-option key="2" value="2" label="2"></el-option>
                  <el-option key="3" value="3" label="3"></el-option>
                  <el-option key="4" value="4" label="4"></el-option>
                  <el-option key="5" value="5" label="5"></el-option>
                  <el-option key="6" value="6" label="6"></el-option>
                  <el-option key="7" value="7" label="7"></el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <!-- Type Form-->
          <base-input label="Type *">
            <div class="row align-content-between radio-group ml-1">
              <base-radio
                  name="physical"
                  class="custom-control custom-radio col-md-3 mb-2"
                  v-model="item.event_type"
              >
                In-Person
              </base-radio>
              <base-radio
                  name="virtual"
                  class="custom-control custom-radio col-md-3 mb-2"
                  v-model="item.event_type"
              >
                Virtual
              </base-radio>
              <base-radio
                  name="hybrid"
                  class="custom-control custom-radio col-md-3 mb-2"
                  v-model="item.event_type"
              >
                Hybrid
              </base-radio>
              <base-radio
                  name="tba"
                  class="custom-control custom-radio col-md-3 mb-2"
                  v-model="item.event_type"
              >
                To-Be Announced
              </base-radio>
            </div>
          </base-input>

          <!-- Attendance-->
          <div class="mt-3">
            <base-input label="Unlimited Participants *"
                        v-if="item.event_type === 'virtual'">
                  <base-switch
                    class="mr-1"
                    v-model="item.unlimitedAttendees"
                    :description="{
                              show: true,
                              content:
                                'Host a digital event without guests limitation',
                            }"
                  ></base-switch>
                </base-input>
            <base-input
                v-show="!(item.event_type === 'tba' || (item.event_type === 'virtual' && item.unlimitedAttendees))"
                :label="'Attendance *'"
                prepend-icon="fas fa-users"
                placeholder="Add the maximum number of participants"
                v-model="items.item.total_capacity"
                type="number"
                name="Attendance"
                min="0"
                :required="true"
            />

            <base-input
                v-show="item.event_type === 'virtual' || item.event_type === 'hybrid'"
                label="Link *"
                prepend-icon="fas fa-link"
                placeholder="Enter a link for your event"
                v-model="items.item.virtual_link"
                name="Link"
                :required="item.event_type === 'virtual' || item.event_type === 'hybrid'"
            />

            <base-mapbox
                @getCoordinatesValues="getCoordinates($event)"
                label="Venue Location"
                v-if="item.event_type === 'physical' || item.event_type === 'hybrid'"
            ></base-mapbox>

            <base-alert type="base" icon="fas fa-bullhorn" v-show="item.event_type === 'tba'">
              <strong>To-Be Announced:</strong>
              This event needs to be updated accordingly!
            </base-alert>
          </div>
        </div>
      </card>

      <card header-classes="bg-transparent" name="preview">
        <h3 slot="header" class="mb-0">Preview before creation</h3>
        <time-line class="transition-box mb-4" type="one-side">
          <time-line-item badge-type="success" badge-icon="fas fa-calendar-day">

            <div class="row align-content-between">
              <div class="col ">
                <small class="text-muted font-weight-bold"
                >Upcoming event:
                </small>
              </div>
              <div class="col-auto">
                <badge rounded type="base" size="md">{{ upcomingTime }}</badge>
              </div>
            </div>


            <h5 class=" mt-3 mb-0">{{ dateTime }}</h5>
            <h3 class=" mt-3 mb-0">{{ items.item.name }}</h3>
            <p class=" text-sm mt-1 mb-0">{{ items.item.description }}</p>


            <div class="mt-3">
              <h5 class="mt-3 mb-0">Category</h5>
              <badge rounded type="primary" size="md">
                {{ getCategory }}
              </badge>
            </div>

            <div class="mt-3">
              <h5 class="mt-3 mb-0">Official tags</h5>
              <div
                  v-for="(tag, index) in getTags"
                  :key="index"
                  class="badge badge-default badge-pill mr-2"
                  :style="{ backgroundColor: tag.color }"
              >
                {{ tag.name }}
              </div>
            </div>
          </time-line-item>

          <time-line-item badge-type="success" badge-icon="fas fa-info">
            <small class="text-muted mt-1 mb-0">
              Once you save this event, you will be able to customize it and add more information (e.g tickets, images etc.)
            </small>
          </time-line-item>
        </time-line>
      </card>

      <!-- Sticky buttons -->
      <div id="sticky-buttons" class="my-4 text-right">
        <card type="frame">
          <div class="mt--3 mb--3">
            <base-button
                type="button"
                class="btn btn-primary"
                @click="saveEvent"
                :disabled="formInValid()"
                v-if="!items.loading"
                data-testid="btn-create-item"
            >
              <i class="fa fa-save"/> Create Event
            </base-button>
            <div class="text-center" v-if="items.loading">
                  <slot name="loading">
                    <i                 
                      class="fas fa-spinner fa-spin"
                    ></i>
                  </slot>
                </div>
          </div>
        </card>
      </div>

    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import moment from "moment";

//components
import BaseMapbox from "@/components/Mapbox/BaseMapbox.vue";
import Badge from "@/components/Badge";
import { TimeLine, TimeLineItem } from "@/components";
import { Select, Option, Skeleton, SkeletonItem } from "element-ui";

export default {
  name: "PreviewEventItem",
  components: {
    Badge,
    TimeLine,
    TimeLineItem,
    BaseMapbox,
    // BaseSwitch,
    [Select.name]: Select,
    [Option.name]: Option,
    [Skeleton.name]: Skeleton,
    [SkeletonItem.name]: SkeletonItem,
  },
  props: {
    eventItem: {
      type: Object,
      default: () => ({}),
    },
    // eventType: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      item: {
        duration: "",
        timeunit: "h",
        event_type: "virtual",
        virtual_link: "",
        total_capacity: -1,
        location: {},
        unlimitedAttendees: false        
      },
      inputted: "",
      loading: false,
      hourOptions:['1','1.5','2','2.5','3','3.5','4','4.5','5','5.5','6','6.5','7','7.5','8','8.5','9','9.5','10','10.5','11','11.5','12']
    };
  },
  computed: {
    items() {
      return this.eventItem;
    },
    upcomingTime() {
      return moment(this.items.item.start_at).fromNow();
    },
    dateTime() {
      return moment(this.items.item.start_at).format('MMMM Do YYYY, h:mm:ss a');
    },
    getTags() {
      const tagsIds = this.items.tags;
      const tags = [];
      this.items.metadata.allTags.forEach((tempTag) => {
        tagsIds.forEach((element) => {
          if (element === tempTag.id) {
            tags.push(tempTag);
          }
        });
      });
      return tags;
    },
    getCategory() {
      const categoryId = this.items.item.category.id;

      const category = _.find(this.items.metadata.allCategories, function(cat) {
        return cat.id == categoryId.toString();
      });

      return category.name;
    },
  },
  // watch: {
  //   item: {
  //     deep: true, // This will let Vue know to look inside the array
  //     // We have to move our method to a handler field
  //     handler(value) {
  //       console.log(value)
  //       this.$emit("quickPreviewValues", value);
  //       // console.log('The values of this Event has changed!', value);
  //     },
  //   },
  // },
  methods: {
    saveEvent() {
      this.$emit("saveEvent", this.item);
    },
    formInValid() {
      return (
        this.items.tags.length === 0 ||
        !this.item.duration ||
        this.item.duration === 0 ||
        (((!this.items.item.total_capacity || this.items.item.total_capacity<0)) && !(this.item.event_type === "tba" ||
          (this.item.event_type === "virtual" && this.item.unlimitedAttendees))) ||
        ((this.item.event_type === "physical" || this.item.event_type === "hybrid") &&
          this.item.location &&
          !this.item.location.address) ||
        ((this.item.event_type === "virtual" || this.item.event_type === "hybrid") &&
          !this.items.item.virtual_link)
      );
    },
    getCoordinates(values) {
      this.item.location = values;
    },
  },
};
</script>
<style>
.timeline-one-side .timeline-content {
  max-width: 100%;
}
</style>
