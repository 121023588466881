import DashboardLayout from "@/views/App/DashboardLayout.vue";

import auth from "@/middleware/auth";

// Components pages
const ListTicketPage = () =>
    import(/* webpackChunkName: "components" */ "@/views/Ticket/Management/ListTicketItems.vue");
const AddTicketPage = () =>
    import(/* webpackChunkName: "components" */ "@/views/Ticket/Management/AddTicketItem.vue");
const EditTicketPage = () =>
    import(/* webpackChunkName: "components" */ "@/views/Ticket/Management/EditTicketItem.vue");

let ticketsNav = {
    path: "/manage",
    component: DashboardLayout,
    name: "Tickets",
    children: [
        {
            path: "tickets/list-tickets",
            name: "List Tickets",
            components: { default: ListTicketPage },
            meta: { middleware: auth }
        },
        {
            path: "tickets/add-ticket",
            name: "Add Ticket",
            components: { default: AddTicketPage },
            meta: { middleware: auth }
        },
        {
            path: "tickets/edit-ticket/:id",
            name: "Edit Ticket",
            components: { default: EditTicketPage },
            meta: { middleware: auth }
        }
    ]
};

export default ticketsNav;
