<template>
  <div class key="container-fluid">
    <div
      id="sponsor-details"
      class="row justify-content-center mt-4"
      style="padding-bottom: 10px !important"
    >
      <div class="col-md-10 order-md-0 order-1">
        <div class="card">
          <!-- Card body -->
          <div class="card-body d-flex align-items-center">
            <div
              v-if="item.logo"
              data-toggle="tooltip"
              
            >
            
              <img 
               alt="Image placeholder" 
               class="avatar avatar-xxl rounded-circle mb-3"
               :src="getSponsorLogo(item.logo)" />
            </div>
            
         
            <div class="ml-3">
              
              <h2 class="text-left text-base">
                {{ item.name }}
              </h2>
              <h4>{{ item.label }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Sponsor Details -->
    <div
      id="sponsor-details"
      class="row justify-content-center mt-4"
      style="padding-bottom: 10px !important"
    >
      <!-- Happening details' WHEN-WHERE -->
      <div class="col-md-6 order-md-0 order-0">
        <!-- About this speaker section-->
        <div class="card">
          <!-- Card body -->
          <div class="card-body">
            <h3 class="inline col-md-10 text-base">
              <i class="fa fa-clipboard-list mr-2" />About this sponsor
            </h3>
            <h4 class="mb--2 text-center">{{ item.summary }}</h4>
            <hr />
            <div class="mt-5">
              <h3 class="inline col-md-10 text-base" style="margin-top: 1em">
                <i class="fa fa-tags mr-2" />Available for
              </h3>
              <div class="badge badge-default badge-pill mr-3">
                <span v-if="item.physical"
                  ><i class="fas fa-check"></i> Physical</span
                >
              </div>
              <div class="badge badge-default badge-pill mr-3">
                <span v-if="item.virtual"
                  ><i class="fas fa-check"></i> Virtual</span
                >
              </div>
              <div class="badge badge-default badge-pill mr-3">
                <span v-if="item.hybrid"
                  ><i class="fas fa-check"></i> Hybrid</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 order-md-1 order-3">
       
          <!-- Card body -->
         <!-- Social share and Happening section-->
      <div class="col-md-8 order-md-1 order-2">
        <div class="card">
        
          <div class="card-body">
            
            <h3
              class="col-md-8 text-left text-base"
              style="display: inline"
              v-if="tags"
            >
              <i class="fas fa-share mr-2" />Share this sponsor on

              
              <div class="row align-content-around mb--5">
                <div class="col-md-12 mb-1">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Copy this Sponsor's public link"
                    placement="bottom"
                  >
                    <base-button
                      round
                      @click="copyURL()"
                      class="btn-base btn-icon-only pb-1 pt-1 mt-2 mb-2"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fa fa-link"
                      /></span>
                    </base-button>
                  </el-tooltip>

                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Share this Sponsor via e-mail"
                    placement="bottom"
                  >
                    <a
                      :href="
                        'mailto:' +
                        '' +
                        '?subject=' +
                        '[Sponsor] ' +
                        item.name +
                        ' (by Book Me In Scotty)' +
                        '&body=' +
                        '%0D%0A' +
                        'Hey,' +
                        '%0D%0A' +
                        'I found this amazing sponsor - visit here for more: ' +
                        sponsorPublicURL +
                        '%0D%0A' +
                        '%0D%0A' 
                      "
                    >
                      <base-button round class="btn btn-icon-only bg-danger">
                        <span class="btn-inner--icon">
                          <i class="fa fa-envelope" aria-hidden="true" />
                        </span>
                      </base-button>
                    </a>
                  </el-tooltip>

                  <share-network
                    network="facebook"
                    :url="sponsorPublicURL"
                    :title="item.name"
                    :description="item.summary"
                    image="https://www.uniconexed.org/wp-content/uploads/canstockphoto21211673-online-event-770x662.jpg"
                    :quote="item.name"
                    :hashtags="getHashtags()"
                    class="mr-2"
                  >
                    <base-button
                      type="facebook"
                      class="btn-icon-only rounded-circle"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fab fa-facebook"></i
                      ></span>
                    </base-button>
                  </share-network>

                  <share-network
                    network="twitter"
                    :url="sponsorPublicURL"
                    :title="item.name"
                    :description="item.summary"
                    :quote="item.name"
                    :hashtags="getHashtags()"
                    class="mr-2"
                  >
                    <base-button
                      type="twitter"
                      class="btn-icon-only rounded-circle"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fab fa-twitter"></i
                      ></span>
                    </base-button>
                  </share-network>

                  <share-network
                    network="linkedin"
                    :url="sponsorPublicURL"
                    :title="item.name"
                    :description="item.summary"
                    :quote="item.name"
                    :hashtags="getHashtags()"
                    class="mr-2"
                  >
                    <base-button
                      type="linkedin"
                      class="btn-icon-only rounded-circle"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fab fa-linkedin"></i
                      ></span>
                    </base-button>
                  </share-network>
                </div>
              </div>
            </h3>
          </div>
        </div>
      </div>
        
      </div>
    
    </div>
  </div>
</template>
<script>
import {
  Button,
  Option,
  Select,
  Image,
  Carousel,
  CarouselItem,
  Tooltip,
  Skeleton,
  SkeletonItem,
  Collapse,
  CollapseItem,
  InputNumber,
  Steps,
  Step,
  Tabs,
  TabPane,
} from "element-ui";
import { TimeLine, TimeLineItem, LoadingPanel } from "@/components";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import BaseSwitchVue from "../BaseSwitch.vue";

const moment = require("moment-timezone");

export default {
  name: "BaseSponsorPage",
  components: {
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Image.name]: Image,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Tooltip.name]: Tooltip,
    [Skeleton.name]: Skeleton,
    [SkeletonItem.name]: SkeletonItem,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [InputNumber.name]: InputNumber,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [TimeLine.name]: TimeLine,
    [TimeLineItem.name]: TimeLineItem,
    [BaseSwitchVue]: BaseSwitchVue,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    modals: {
      type: Object,
      default: null,
    },
    hasBookButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sponsor: {
        type: "sponsors",
        name: "",
        email: ""
        
      },
      tags: [],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      textCollapsed: true,
      showSponsors: false,
      showSpeakers: false,
      heroImageLoaded: false,
      activeStep: 0,
      idx: 0,
      loading: false,
    };
  },
  computed: {
    user() {
      let me = null;
      try {
        // Get User Profile
        me = this.$store.getters["profile/me"];
      } catch (error) {
        // Notification
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong with your account!",
        });
      }
      // return user object
      return me;
    },
    // logo() {
    //   return process.env.VUE_APP_DEFAULT_LOGO;
    // },
    sponsorPublicURL() {
      const itemId = _.isEmpty(this.item.hash_code)
        ? this.item.id
        : this.item.hash_code;
      return process.env.VUE_APP_BASE_URL + "/sponsors/" + itemId;
      
    },
    
    sponsorShortDescription() {
      return this.item.summary
        ? this.item.summary.substring(0, 140) + "..."
        : " ";
    },
  },
  methods: {
    getHeroImage() {
      if (
        this.item.hero_image.includes("http://") ||
        this.item.hero_image.includes("https://")
      ) {
        return this.item.hero_image;
      }
      return process.env.VUE_APP_AWS_S3_URL + this.item.hero_image;
    },
    getSponsorLogo(logoUrl) {
     return process.env.VUE_APP_AWS_S3_URL + logoUrl;
    },
    getHashtags() {
      const tagsName = ["bookmeinscotty"];
      this.tags.forEach((tag) => {
        tagsName.push(tag.name);
      });
      return tagsName.join();
    },
    getHashtagsForEmail() {
      const tagsName = [" #bookmeinscotty"];
      this.tags.forEach((tag) => {
        tagsName.push(" #" + tag.name);
      });
      return tagsName.join();
    },
    getAgendaLabel(val) {
      const tabDate = val.date ?? val.start_at;
      return " ● " + moment(tabDate).format("ddd, D MMM @ HH:mm");
    },
    async copyURL() {
      try {
        await navigator.clipboard.writeText(this.sponsorPublicURL);
        swal.fire({
          title: "Copied - Public Sponsor Link",
          html:
            "You can use this link to share it with a wide group of people, post it on your Social Media or Spread the Word<br></hr> " +
            "<br><b>Public Speaker Link:</b><br> <button type='button' class='btn-icon-clipboard text-center'>" +
            this.sponsorPublicURL +
            "</button> <br></hr>",
          type: "success",
          confirmButtonClass: "btn btn-outline-danger btn-fill",
          confirmButtonText: " Close",
          buttonsStyling: false,
          icon: "success",
        });
      } catch ($e) {
        alert("Cannot copy");
      }
    },
  },
};
</script>
<style scoped>
#mantra-banner {
  width: 60vw !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.el-tabs--card >>> .el-tabs__header {
  box-shadow: 0 0 0 0 !important;
  border: 0 !important;
  padding-right: 0;
  padding-left: 0;
  overflow: auto !important;
  white-space: nowrap !important;
}
</style>