import  DashboardLayout from "@/views/App/DashboardLayout.vue";

// Components pages
const ListSponsorPage = () =>
    import(/* webpackChunkName: "sponsors" */ "@/views/Sponsors/ListSponsorItems.vue");
const AddSponsorPage = () =>
    import(/* webpackChunkName: "sponsors" */ "@/views/Sponsors/AddSponsorItem.vue");
const EditSponsorPage = () =>
    import(/* webpackChunkName: "sponsors" */ "@/views/Sponsors/EditSponsorItem.vue");
const PreviewSponsorPage = () =>
    import(/* webpackChunkName: "sponsors" */ "@/views/Sponsors/PreviewSponsorItem.vue");
import auth from "@/middleware/auth";

let sponsorsNav = {
    path: "/manage",
    component: DashboardLayout,
    name: "Sponsors",
    children: [
        {
            path: "sponsors/list-sponsors",
            name: "List Sponsors",
            components: { default: ListSponsorPage },
            meta: { middleware: auth }
        },
        {
            path: "sponsors/add-sponsor",
            name: "Add Sponsor",
            components: { default: AddSponsorPage },
            meta: { middleware: auth }
        },
        {
            path: "sponsors/edit-sponsor/:id",
            name: "Edit Sponsor",
            components: { default: EditSponsorPage },
            meta: { middleware: auth }
        },
        ,
        {
            path: "sponsors/:id",
            name: "Sponsor Preview",
            components: { default: PreviewSponsorPage },
        }
    ]
}

export default sponsorsNav;
 
