<template>
  <div class="row">
    <!-- Latest Announcements -->
    <div class="col-md-6">
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-md-9 order-md-0 order-last">
            <p class="mb-0">Share announcements with your attendees</p>
          </div>
        </div>

        <div class="col-md-auto">
          <base-input group class="mb-3">
            <textarea
              v-model="newAnnouncement"
              name="announcement"
              placeholder="Stay in touch with your attendees before, after or during this Event - share anything to inform your Attendees"
              class="form-control"
              rows="7"
              max-length="500"
            />
          </base-input>
        </div>

        <div class="col-md-auto justify-content-end input-group-append">
          <el-tooltip
            v-if="!isFreeUser()"
            content="Schedule for later"
            placement="top"
            class="announcements-dropdown"
          >
            <el-dropdown
              class="dropdown"
              trigger="click"
              :disabled="!newAnnouncement || loading"
            >
              <base-button type="btn btn-primary text-white" size="md">
                <slot name="icon">
                  <i class="fas fa-stopwatch mr-2" />
                  <span class="ml--2 mr--2"> Scheduled </span>
                  <i class="el-icon-arrow-down el-icon--right" />
                </slot>
              </base-button>

              <el-dropdown-menu slot="dropdown">
                <div style="display: flex; flex-direction: column">
                  <el-dropdown-item>
                    <base-button
                      v-show="isValidAnnouncementDate(1)"
                      type="button"
                      class="btn btn-sm btn-base mr-0"
                      @click="sendAnnouncement(1)"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                      append
                      block
                    >
                      1 day before of event
                    </base-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <base-button
                      v-show="isValidAnnouncementDate(3)"
                      type="button"
                      class="btn btn-sm btn-base mt-2 mr-0"
                      @click="sendAnnouncement(3)"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                      append
                      block
                    >
                      3 days before of event
                    </base-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <base-button
                      v-show="isValidAnnouncementDate(7)"
                      type="button"
                      class="btn btn-sm btn-base mt-2 mr-0"
                      @click="sendAnnouncement(7)"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                      append
                      block
                    >
                      7 days before event
                    </base-button>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <base-button
                      v-show="isValidAnnouncementDate(14)"
                      type="button"
                      class="btn btn-sm btn-base mt-2 mb-1 mr-0"
                      @click="sendAnnouncement(14)"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                      append
                      block
                    >
                      14 days before event
                    </base-button>
                  </el-dropdown-item>
                  <el-dropdown-item divided>
                    <base-button
                      type="button"
                      class="btn btn-sm btn-base mt-2 mr-0"
                      @click="showSelectAnnouncementTimeModal = true"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                      append
                    >
                      Custom date and time
                    </base-button>
                  </el-dropdown-item>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>

          <base-premium-overlay :enabled="isFreeUser()" v-else>
            <base-button type="btn btn-primary text-white" size="md">
              <slot name="icon">
                <i class="fas fa-stopwatch mr-2" />
                <span class="ml--2 mr--2"> Scheduled </span>
                <i class="el-icon-arrow-down el-icon--right" />
              </slot>
            </base-button>
          </base-premium-overlay>

          <button
            id="announcement"
            @click="sendAnnouncement()"
            class="btn btn-primary mr-1"
            style="cursor: pointer"
            type="button"
            :disabled="!newAnnouncement || loading"
          >
            <slot name="icon">
              <i class="el-icon-s-promotion mr-1" /> Send now
            </slot>
          </button>
        </div>

        <div class="text-center mt-2" v-if="loading">
          <slot name="loading">
            <i class="fas fa-spinner fa-spin"></i>
          </slot>
        </div>

        <div
          class="mr-3 ml-4 timeline timeline-one-side mt-5"
          data-timeline-content="axis"
          data-timeline-axis-style="dashed"
        >
          <div
            v-for="announcement in announcements"
            :key="announcement.id"
            class="timeline-block"
          >
            <span
              class="timeline-step"
              :class="
                announcement.delivered === 1 ? `badge-success` : 'badge-warning'
              "
            >
              <i :class="announcement.icon || 'ni ni-notification-70'"></i>
            </span>
            <div class="timeline-content">
              <div class="d-flex justify-content-between pt-1">
                <div>
                  <span class="text-muted text-sm font-weight-bold">{{
                    announcement.title || "Message"
                  }}</span>
                </div>
                <div class="text-right">
                  <small
                    class="text-muted"
                    v-if="announcement.scheduled_at !== null"
                  >
                    <i
                      class="fas fa-clock mr-1 text-base"
                      v-if="announcement.delivered === 0"
                    />
                    <i class="fas fa-check-double text-base" v-else />
                    {{ displayAnnouncementTime(announcement.scheduled_at) }}
                    {{ announcement.delivered === 1 ? `(sent)` : `(pending)` }}
                  </small>

                  <small class="text-muted" v-else>
                    <i
                      class="fas fa-clock mr-1 text-base"
                      v-if="announcement.delivered === 0"
                    />
                    <i class="fas fa-check-double mr-1 text-base" v-else />
                    {{ displayAnnouncementTime(announcement.updated_at) }}
                    <span v-if="announcement.delivered === 1"> (sent) </span>
                    <span v-else> (sending) </span>
                  </small>
                </div>
              </div>
              <div class="d-flex justify-content-between pt-2">
                <h6 class="text-sm mt-1 mb-0">
                  {{ announcement.message }}
                </h6>
                <base-button
                  v-if="
                    announcement.delivered === 0 &&
                    announcement.scheduled_at !== null
                  "
                  size="sm"
                  outline
                  type="primary"
                  @click="
                    showEditAnnouncementTimeModal = true;
                    announcementToEdit = announcement;
                  "
                >
                  <i class="far fa-edit" />Edit details
                </base-button>
              </div>

              <!--Latest Announcement based on priority selection-->
              <base-alert type="secondary" class="mt-4">
                <base-input label="Importance *">
                  <div
                    class="row align-items-around ml-4 mt-3"
                    @change="updateAnnouncement(announcement)"
                  >
                    <base-radio
                      name="normal"
                      class="col-md-4"
                      v-model="announcement.importance_type"
                    >
                      Normal
                    </base-radio>
                    <base-radio
                      name="medium"
                      class="col-md-4"
                      v-model="announcement.importance_type"
                    >
                      Medium
                    </base-radio>
                    <base-radio
                      name="high"
                      class="col-md-4"
                      v-model="announcement.importance_type"
                    >
                      High
                    </base-radio>
                  </div>
                </base-input>
              </base-alert>
            </div>
          </div>
        </div>
      </card>
    </div>

    <!--Social sharing-->
    <div class="col-md-6">
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-md-9 order-md-0 order-last">
            <p class="mb-0">Instant event creation in your Social Networks</p>
          </div>
          <div class="col-md-2 order-md-1 order-first">
            <badge rounded type="warning">Coming soon</badge>
          </div>
        </div>
        <div class="card-body blur-content-light">
          <div class="mb-4">
            <share-network
              network="facebook"
              :url="publicURL"
              :title="item.name"
              :description="item.description"
              image="https://www.uniconexed.org/wp-content/uploads/canstockphoto21211673-online-event-770x662.jpg"
              :quote="item.name"
              :hashtags="getHashtags()"
              class="mr-2"
            >
              <base-button type="facebook" icon>
                <span class="btn-inner--icon"
                  ><i class="fab fa-facebook"></i
                ></span>
                <span class="btn-inner--text">Facebook</span>
              </base-button>
            </share-network>

            <share-network
              network="twitter"
              :url="publicURL"
              :title="item.name"
              :description="item.description"
              :quote="item.name"
              :hashtags="getHashtags()"
              class="mr-2"
            >
              <base-button type="twitter" icon>
                <span class="btn-inner--icon"
                  ><i class="fab fa-twitter"></i
                ></span>
                <span class="btn-inner--text">Twitter</span>
              </base-button>
            </share-network>

            <share-network
              network="linkedin"
              :url="publicURL"
              :title="item.name"
              :description="item.description"
              :quote="item.name"
              :hashtags="getHashtags()"
              class="mr-2"
            >
              <base-button type="vimeo" icon>
                <span class="btn-inner--icon"
                  ><i class="fab fa-linkedin"></i
                ></span>
                <span class="btn-inner--text">Linked In</span>
              </base-button>
            </share-network>
          </div>
        </div>
      </card>
    </div>
    <div class="col-md-6">
      <!-- Surveys -->
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-md-9 order-md-0 order-last">
            <p class="mb-0">Send a survey at your attendees</p>
          </div>
        </div>
        <base-premium-overlay :enabled="!isDeveloper()"
                              badge-label="Coming Soon"
                              badge-style="warning"
                              badge-message="This feature will be released soon, feature is under development">
          <div class="card-body">
            <base-input label="Surveys">
              <el-select
                v-model="survey"
                filterable
                prepend-icon="fas fa-user"
                placeholder="Select..."
              >
                <el-option
                  v-for="item in surveys"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-md-auto justify-content-end input-group-append">
            <button
              id="survey"
              @click="sendSurvey(survey)"
              class="btn btn-primary mr-1"
              style="cursor: pointer"
              type="button"
              :disabled="!survey || loading"
            >
              <slot name="icon">
                <i class="el-icon-s-promotion mr-1" /> Send now
              </slot>
            </button>
          </div>
        </base-premium-overlay>
      </card>
    </div>
    <!-- Announcements Modal-->
    <modal
      :show.sync="showSelectAnnouncementTimeModal"
      modal-classes="modal-secondary"
      header-classes="calendar-modal-header"
      style="z-index: 3000"
    >
      <template slot="footer">
        <base-button
          type="primary"
          @click="sendAnnouncement()"
          v-if="scheduled_at && scheduled_at.length > 0"
        >
          Schedule
        </base-button>

        <base-button
          style="margin-bottom: 0.5rem"
          type="secondary"
          @click="
            showSelectAnnouncementTimeModal = false;
            scheduled_at = '';
          "
          >Close</base-button
        >
      </template>

      <base-input label="Pick date and time for sending the announcement">
        <flat-picker
          slot-scope="{ blur }"
          @on-open="customFocus()"
          @on-close="blur"
          :config="{
            allowInput: true,
            enableTime: true,
            minDate: 'today',
          }"
          class="form-control datepicker"
          v-model="scheduled_at"
        >
        </flat-picker>
      </base-input>
    </modal>

    <!-- Edit Announcements Modal-->
    <modal
      :show.sync="showEditAnnouncementTimeModal"
      modal-classes="modal-secondary"
      header-classes="calendar-modal-header"
      style="z-index: 3000"
    >
      <template slot="footer">
        <base-button
          type="primary"
          @click="editAnnouncement()"
          v-if="announcementToEdit.message"
        >
          Edit
        </base-button>

        <base-button
          style="margin-bottom: 0.5rem"
          type="secondary"
          @click="
            showEditAnnouncementTimeModal = false;
            announcementToEdit = {};
          "
          >Close</base-button
        >
      </template>
      <base-input label="Announcement message">
        <textarea
          v-model="announcementToEdit.message"
          name="edit-announcement"
          class="form-control"
          rows="7"
          max-length="500"
        />
      </base-input>

      <base-input label="Select new date and time">
        <flat-picker
          slot-scope="{ blur }"
          @on-open="customFocus()"
          @on-close="blur"
          :config="{
            allowInput: true,
            enableTime: true,
            minDate: 'today',
          }"
          class="form-control datepicker"
          v-model="announcementToEdit.scheduled_at"
        >
        </flat-picker>
      </base-input>
    </modal>
  </div>
</template>
<script>
import {
  Select,
  Option,
  Tabs,
  TabPane,
  Collapse,
  CollapseItem,
  Timeline,
  TimelineItem,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
} from "element-ui";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import moment from "moment";
import flatPicker from "vue-flatpickr-component";
import BasePremiumOverlay from "@/components/Premium/BasePremiumOverlay.vue";

// import { Slider } from "vue-color";
export default {
  name: "edit-event-actions",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    ["flatPicker"]: flatPicker,
    BaseInput,
    BaseButton,
    BasePremiumOverlay,
  },
  mixins: [formMixin],
  data() {
    return {
      loading: false,
      tags: [],
      scheduled_at: "",
      location: null,
      actions: {
        uploadHero: false,
      },
      showSelectAnnouncementTimeModal: false,
      showEditAnnouncementTimeModal: false,
      cloneState: false,
      announcements: [],
      surveys: [],
      newAnnouncement: null,
      announcementToEdit: {},
      survey: null,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    this.getAnnouncements();
    this.getSurveys();
    this.cloneState = this.$route.query.clone === "true";
  },
  computed: {
    publicURL() {
      return process.env.VUE_APP_BASE_URL + "/event/" + this.item.id;
    },
  },
  methods: {
    customFocus() {
      setTimeout(function () {
        document.getElementsByClassName("flatpickr-calendar open")[0].focus();
      }, 100);
    },
    isValidAnnouncementDate(days) {
      const dateToSendAnnouncement = moment(this.item?.start_at)
        .subtract(days, "d")
        .format("YYYY-MM-DD HH:mm:ss");
      return moment().isBefore(dateToSendAnnouncement);
    },
    displayAnnouncementTime(date) {
      return moment.utc(date).fromNow();
    },
    async getAnnouncements() {
      let params = {
        ...{ sort: "-created_at" },
        filter: {
          ...(this.item
            ? { item_id: this.item.id }
            : { item_id: this.$route.params.id }),
        },
        page: {
          number: 1,
          size: 3,
        },
      };
      try {
        await this.$store
          .dispatch("announcements/list", params)
          .then(
            () =>
              (this.announcements = this.$store.getters["announcements/list"])
          );
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },
    async sendAnnouncement(days) {
      this.showSelectAnnouncementTimeModal = false;
      this.loading = true;
      let scheduled_at;
      if (days) {
        scheduled_at = moment(this.item?.start_at)
          .subtract(days, "d")
          .format("YYYY-MM-DD HH:mm:ss");
      } else if (this.scheduled_at?.length > 0) {
        scheduled_at = moment(this.scheduled_at).format("YYYY-MM-DD HH:mm:ss");
      }
      try {
        await this.$store
          .dispatch("announcements/add", {
            item_id: this.item ? this.item.id : this.$route.params.id,
            message: this.newAnnouncement,
            delivered: 0,
            type: "announcements",
            scheduled_at: scheduled_at,
          })
          .then(() => this.getAnnouncements());
        this.$notify({
          type: "success",
          message: "Announcements sent successfully.",
        });
        this.newAnnouncement = null;
        this.scheduled_at = "";
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async editAnnouncement() {
      this.showEditAnnouncementTimeModal = false;
      this.loading = true;
      try {
        await this.$store
          .dispatch("announcements/update", {
            item_id: this.item ? this.item.id : this.$route.params.id,
            id: this.announcementToEdit.id,
            message: this.announcementToEdit.message,
            delivered: 0,
            type: "announcements",
            scheduled_at:
              this.announcementToEdit.scheduled_at?.length > 0
                ? moment(this.announcementToEdit.scheduled_at).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                : undefined,
          })
          .then(() => this.getAnnouncements());
        this.$notify({
          type: "success",
          message: "Announcements updated successfully.",
        });
        this.announcementToEdit = {};
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async updateAnnouncement(annons) {
      try {
        await this.$store.dispatch("announcements/update", annons);
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    getHashtags() {
      const tagsName = ["bookmeinscotty", "seizethemoment"];
      this.item.tags.forEach((tag) => {
        tagsName.push(tag.name);
      });
      return tagsName.join("#");
    },
    async getSurveys() {
      let params = {
        filter: { ...{ user_id: 0 } },
      };
      try {
        await this.$store.dispatch("surveys/list", params).then(() => {
          this.surveys = this.$store.getters["surveys/list"];
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
        this.loading = false;
      }
    },
    async sendSurvey(surveyId) {
      this.loading = true;
      try {
        await this.$store.dispatch("surveys/send", {
          item_id: this.item ? this.item.id : this.$route.params.id,
          id: surveyId,
          type: "surveys",
        });
        this.$notify({
          type: "success",
          message: "Survey sent successfully.",
        });
        this.survey = null;
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
