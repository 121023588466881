<template>
  <div class="container-fluid mt-4">
    <!-- Navigation -->
    <base-nav
      id="event-main-navigation"
      container-classes="container-fluid"
      class="navbar fixed-top navbar-horizontal navbar-expand-lg navbar-dark"
      type="gradient-base"
      v-model="showMenu"
    >
      <a slot="brand" class="navbar-brand" href="#">
        <img :src="logo" />
      </a>
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <router-link to="/">
              <img :src="logo" />
            </router-link>
          </div>

          <div class="col-6 collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              @click="showMenu = false"
            >
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav ml-lg-auto">
        <li class="nav-item">
          <a class="nav-link nav-link-icon" href="#event-details">
            <i class="far fa-comment-alt"></i>
            Details
          </a>
        </li>

        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="#event-gallery-media"
            id="navbar-default_dropdown_1"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-image"></i>
            Gallery
          </a>
        </li>

        <li>
          <a
            class="nav-link nav-link-icon"
            href="#event-happening"
            id="navbar-default_dropdown_1"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-map"></i>
            Location
          </a>
        </li>

        <li class="nav-item dropdown mt-2">
          <!-- ToDo - Develop Mobile Navigation-->
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbar-default_dropdown_1"
          >
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
          </div>
        </li>
      </ul>
    </base-nav>
    <div
      class="mb-3 mt--2"
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <!-- Back  -->
      <back-button @click="goBack" />

      <div style="justify-content: end">
        <base-button size="sm" outline type="primary" @click="editEvent()">
          <i class="far fa-edit" />Edit Event
        </base-button>
        <base-button
          size="sm"
          type="primary"
          @click="copyPublicLink()"
          class="mt-2"
        >
          <i class="fa fa-link" />Public Link
        </base-button>

        <base-button
          size="sm"
          type="primary"
          @click="copyIframeButtonLink()"
          class="mt-2"
        >
          <i class="fa fa-link" /> Ticketing Widget
        </base-button>
      </div>
    </div>

    <base-alert dismissible type="primary">
      <strong> <i class="fa fa-info-circle" /> Preview Event Page - </strong>
      This page cannot be accessed from other users, in order to share this
      event with audience please use the 'Public Link'.
    </base-alert>
    <div
      v-if="skeletonLoader"
      class="container-fluid"
      style="max-width: 83.33333333%"
    >
      <el-skeleton :rows="6" animated class="mt-4">
        <template slot="template">
          <div style="padding: 14px">
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; height: 100px"
            /><br /><br />
            <el-skeleton-item
              variant="image"
              style="width: 100%; height: 480px"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; width: 50%"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; width: 30%"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; width: 40%"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; width: 100%"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; width: 100%"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; width: 30%"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; width: 40%"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; width: 30%"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 1%; width: 32%"
            />
            <el-skeleton-item
              variant="text"
              style="margin-right: 1%; width: 32%"
            />
            <el-skeleton-item
              variant="text"
              style="margin-right: 1%; width: 32%"
            />
          </div>
        </template>
      </el-skeleton>
    </div>

    <!-- Themes and Layouts-->
    <base-event-landing-page
        v-if="item.theme === 'base' && !skeletonLoader"
        :item="item"
        :modals="modals"
        @addTicket="ticketModal($event)"
    />

    <tabloid-event-landing-page
        v-else-if="item.theme === 'tabloid' && !skeletonLoader"
        :item="item"
        :modals="modals"
        @addTicket="ticketModal($event)"
    />

    <eventalk-landing-page 
        v-else-if="item.theme === 'eventalk' && !skeletonLoader"
        :item="item"
        :modals="modals"
        @addTickets="ticketModal($event)"
    />

    <!-- Footer-->
    <hr />
    <div class="row justify-content-between">
      <div class="col">
        <div class="copyright text-left">
          <small class="text-muted"> Host your events at no-cost with </small>
          <a href="http://www.bookmeinscotty.com">
            <img
                :src="logo"
              style="max-width: 25%; max-height: 2rem"
              class="mr-1"
            />™
          </a>
        </div>
      </div>
      <div class="col-auto">
        <div class="copyright text-center">
          <small class="text-muted mr-4"
            >© {{ copyrightYear }} | All Rights Reserved</small
          >
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import {
  Option,
  Select,
  Image,
  Carousel,
  CarouselItem,
  Skeleton,
  SkeletonItem,
} from "element-ui";
import swal from "sweetalert2";
import BackButton from "@/components/BackButton.vue";
import BaseEventLandingPage from "@/components/Events/BaseEventLandingPage.vue";
import TabloidEventLandingPage from "@/components/Events/TabloidEventLandingPage.vue";
import EventalkLandingPage from '@/components/Events/EventalkLandingPage.vue';

export default {
  name: "PreviewEventPage",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Image.name]: Image,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Skeleton.name]: Skeleton,
    [SkeletonItem.name]: SkeletonItem,
    BackButton,
    BaseEventLandingPage,
    TabloidEventLandingPage,
    EventalkLandingPage
  },
  props: {
    logo: {
      type: String,
      default: process.env.VUE_APP_DEFAULT_FULL_LOGO,
      description: "Sidebar app logo",
    },
    logomark: {
      type: String,
      default: process.env.VUE_APP_DEFAULT_LOGO,
      description: "Sidebar app logo",
    },
  },
  data() {
    return {
      item: {
        id: null,
      },
      organizer: {},
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      modals: {
        openBooking: false,
        openVideo: false,
      },
      showMenu: false,
      attendee: { name: "", email: "", type: "attendees" },
      snipcartToken: null,
      snipcartScript: null,
      skeletonLoader: false,
      copyrightYear: new Date().getFullYear(),
    };
  },
  computed: {
    user() {
      return this.$store.getters["profile/me"];
    },
  },
  async created() {
    // Data fetched and ready to manipulate on this component
    // Get Snipcart Token key dynamically according to user
    this.snipcartScript = document.getElementById("snipcart");

    await this.fetchItem()
      .then(() => {
        this.snipcartToken =
          this.organizer.snipcart_token ?? process.env.VUE_APP_SNIPCART_KEY;
      })
      .then(() => {
        // Set Snipcart Token to attributes

        this.snipcartScript.setAttribute("data-api-key", this.snipcartToken);
      });
  },
  async updated() {
    // Set Snipcart script
    this.snipcartScript.setAttribute(
      "src",
      "https://cdn.snipcart.com/scripts/2.0/snipcart.js"
    );
  },
  methods: {
    async fetchItem() {
      this.skeletonLoader = true;
      try {
        const id = (this.item.id = this.$route.params.id);
        let params = {
          filter: {
            ...(this.user ? { user_id: this.user.id } : { user_id: 0 }),
          },
          include: "user,category,tags,tickets,venues",
        };

        await this.$store
          .dispatch("items/get", { id, params })
          .then(() => (this.organizer = this.user))
          .then(() => {
            this.item = this.$store.getters["items/item"];
            setTimeout(() => {
              this.skeletonLoader = false;
            }, "1000");
          });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async ticketModal(attendee) {
      attendee.type = "attendees";
      attendee.personalized_ticket =
        attendee.guests.length > 1 && attendee.guests[0].quantity === 1 ? 1 : 0;
      try {
        await this.$store
          .dispatch("attendees/add", attendee)
          .then(
            () => (this.attendee = this.$store.getters["attendees/attendee"])
          );
        // .then(() => (this.tag = this.$store.getters["tags/tag"]));
        const ticketStatus = this.attendee.guests[0].is_paid
          ? "Paid"
          : "Payment pending";
        // const ticketPrice = this.attendee.guests[0].final_price
        //   ? this.attendee.guests[0].final_price
        //   : this.attendee.guests[0].price;

        const totalTicketPrice = this.attendee.guests
          .map(
            (ticket) => ticket.quantity * (ticket.final_price || ticket.price)
          )
          .reduce((total, price) => total + price, 0);
        const ticketNumbers = this.attendee.guests.map(
          ({ ticket_number }) => ticket_number
        );
        const ticketNumer =
          ticketNumbers.length > 1
            ? ticketNumbers.join(", ")
            : ticketNumbers[0];
        const confirmation = await swal.fire({
          title: `Success`,
          // text: "A few words about this sweet alert ...",
          html:
            "Your ticket has been booked, please save your Booking Number(s)<br> " +
            "Booking No(s): " +
            ticketNumer +
            "<br>" +
            "Total Ticket Price: " +
            totalTicketPrice +
            "<br>" +
            "Booking Status: " +
            ticketStatus +
            "<br>" +
            "You will receive soon a verification email with all the booking details",
          buttonsStyling: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          customClass: {
            confirmButton: "btn btn-success",
          },
          icon: "success",
        });
        try {
          // Ticket booking is successful
          if (confirmation.value === true) {
            this.modals.openBooking = false;
          }
        } catch (error) {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong, please try again!",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.error);
      }
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    goBack() {
      this.$router.push({ name: "List Events" });
    },
    editEvent() {
      this.$router.push({
        name: "Edit Event",
        params: { id: this.item.id },
      });
    },
    eventPublicURL() {
      const itemId = _.isEmpty(this.item.hash_code)
        ? this.item.id
        : this.item.hash_code;
      return process.env.VUE_APP_BASE_URL + "/event/" + itemId;
    },
    bookButtonPublicURL() {
      return this.eventPublicURL() + "?btnOnly=true";
    },
    copyPublicLink() {
      if (this.user) {
        try {
          navigator.clipboard.writeText(this.eventPublicURL());
          swal.fire({
            title: "Copied - Public Event Link",
            html:
              "You can use this link to share it with a wide group of people, post it on your Social Media or Spread the Word<br></hr> " +
              "<br><b>Public Event Link:</b><br> <button type='button' class='btn-icon-clipboard text-center'>" +
              this.eventPublicURL() +
              "</button> <br></hr>",
            type: "success",
            confirmButtonClass: "btn btn-outline-danger btn-fill",
            confirmButtonText: " Close",
            buttonsStyling: false,
            icon: "success",
          });
        } catch ($e) {
          alert("Cannot copy");
        }
      }
    },
    async copyIframeButtonLink() {
      if (this.user) {
        try {
          const publicURL = this.bookButtonPublicURL();
          const iFrameCodeblock =
            "<iframe src=" +
            publicURL +
            " width=100%; height=100%; style=border:none;" +
            "></iframe>";
          navigator.clipboard.writeText(iFrameCodeblock).then(
            function () {
              alert("Embeddable Ticket Widget - Copied succesfully!");
            },
            function () {
              alert("Booking Ticket iFrame link copy failed!");
            }
          );
          swal.fire({
            title: "Copied - Public Book Me In Scotty Ticket Link",
            html:
              "You can use the below iframe to embedd the Book Me In Scotty ticket popup of your event to any website, your social media or as web widget etc.<br></hr> " +
              "<br><b>as embed iFrame:</b><br> <button type='button' class='btn-icon-clipboard text-center'>" +
              "<'" +
              "iframe src='https://app.bookmeinscotty.com/...' width='100%' height='100%' style='border:none;'" +
              "><" +
              "'/iframe'" +
              ">" +
              "</button> <br></hr>",
            // text: this.iFrameCodeblock,
            type: "success",
            confirmButtonClass: "btn btn-outline-danger btn-fill",
            confirmButtonText: " Close",
            buttonsStyling: false,
            icon: "success",
          });
        } catch ($e) {
          alert("Cannot copy");
        }
      }
    },

    // buttonClicked() {
    //   this.$gtag.event('google-analytics-click', {
    //     'event_category':'documentation' ,
    //     'event_label' : 'Google Analytics',
    //     'value': 1
    //   })
    // }
  },
};
</script>
