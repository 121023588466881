<template>
  <div class="row align-content-end">
    <div class="col">
<!--      <label :class="switchClass"></label>-->
      <badge v-if="notice" rounded :type="notice.badge" class="ml-1 mt--1">
        {{ notice.content }}
      </badge>
      <base-alert v-else-if="infobox" type="base" class="mt--1">
        <strong> {{ infobox.label }}:</strong>
        A$ {{ infobox.content }}
      </base-alert>
      <span v-else-if="description" class="text-sm text-muted mb-0 pt--4">
        {{ description.content }}
      </span>
    </div>
    <div class="col-auto mr--3">
      <label class="custom-toggle">
        <input type="checkbox" v-model="model" :disabled="disabled">
        <span class="custom-toggle-slider rounded-circle"
              :data-label-off="offText"
              :data-label-on="onText">
          </span>
      </label>
    </div>

  </div>
</template>
<script>
  export default {
    name: 'base-switch',
    props: {
      value: [Array, Boolean, String],
      type: String,
      onText: {
        type: String,
        default: 'Yes'
      },
      offText: {
        type: String,
        default: 'No'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      notice: {
        type: Object,
        default: () => {},
        required: false,
        content: '',
        badge: 'base'
      },
      description: {
        type: Object,
        default: () => {},
        required: false,
        content: '',
      },
      infobox :{
        type: Object,
        default: () => {},
        required: false,
        label: '',
        content: ''
      }
    },
    computed: {
      switchClass() {
        let baseClass = 'custom-toggle-';
        if (this.type) {
          return baseClass + this.type
        }
        return ''
      },
      model: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      }
    },
    methods: {
      triggerToggle() {
        this.model = !this.model;
      }
    }
  };
</script>
<style></style>
