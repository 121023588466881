<template>
  <div class="row">

    <div class="col-md-6 order-md-1">
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-8">
            <p class="mb-0">Media and content</p>
          </div>
        </div>

        <div class="card-body">
          <!-- Hero Images -->
          <template>
            <label class="form-control-label"> Hero Image </label>

            <div
                class="card bg-dark text-white border-0"
                v-show="!actions.uploadHero"
            >
              <img class="card-img" :src="heroImage" alt="Event Hero Image" />
              <div class="card-img-overlay d-flex align-items-center">
                <div>
                  <base-button
                      type="primary"
                      @click="actions.uploadHero = true"
                  >
                    <i class="fa fa-upload mr-2" />Upload new Image</base-button
                  >
                </div>
              </div>
            </div>

            <base-file-input
                help-message="<i class='fa fa-upload'></i> Select your best Hero to upload! </br> <small class='filepond--label-action text-muted'>Drop or Browse files</small></br> "
                :endpoint="uploadAssetUrl('hero', item.hero_image)"
                preview-height="400"
                v-show="actions.uploadHero"
            />
          </template>
          <hr />
          <!-- Additional Images -->
          <div class="form-group">
            <template>
              <base-premium-overlay
                  :enabled="isFreeUser() && (item.additional_images && item.additional_images.length < 3)"
              >
                <base-file-input
                    label="Additional Images"
                    help-message="<i class='fa fa-upload'></i> Cherry-pick your best Images to upload! </br> <small class='filepond--label-action text-muted'>Drop or Browse files</small></br> "
                    :multiple="true"
                    :endpoint="uploadAssetUrl('images')"
                    preview-height="100"
                    :max-upload-files="
                    isFreeUser()
                      ? item.additional_images
                        ? 3 - item.additional_images.length
                        : 3
                      : null
                  "
                    :total-images="
                    item.additional_images && item.additional_images.length
                      ? item.additional_images.length
                      : 0
                  "
                />
              </base-premium-overlay>

              <div id="additional-assets" class="row">
                <div
                    class="col-md-4"
                    v-for="(image, index) in additionalImageList"
                    :key="index"
                    :id="`asset-img-${image.id}`"
                >
                  <!-- Doc Viewer -->
                  <base-stats-card
                      title="Total ticket orders"
                      type="gradient-base"
                      class="ml-0 mr-0"
                  >
                    <div class="text-center mt-2 mb--3">
                      <img
                          class="card-img-top"
                          :src="image.url"
                          alt="Additional Event Image"
                      />
                      <br><hr class="mt-2 mb-0">
                      <base-button type="button"
                                   size="sm"
                                   outline
                                   class="btn-sm btn-danger mt-2 mb-0 ml-2"
                                   @click="removeAsset(image.id, 'img')">
                        <i class="far fa-trash-alt"/> Delete
                      </base-button>
                    </div>
                  </base-stats-card>
                </div>
              </div>

            </template>
          </div>
        </div>
      </card>

      <card>
        <template>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <p class="mb-0">Additional Attachment</p>
            </div>
          </div>

          <div class="card-body">
            <base-file-input
                label="Add Documents"
                help-message="<i class='fa fa-upload'></i> Select your documents to upload! </br> <small class='filepond--label-action text-muted'>Drop or Browse files</small></br> "
                :endpoint="uploadAssetUrl('documents')"
                :multiple="true"
                preview-height="500"
                file-types="application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.pr, application/vnd.ms-excel"
                file-size="50MB"
            />
          </div>

          <div class="row justify-content-center">
            <div class="col-md-4"
                v-for="(doc, idx) in additionalDocumentsList"
                :key="idx"
                :id="`asset-doc-${doc.id}`"
            >
              <!-- Doc Viewer -->
              <base-stats-card
                  title="Total ticket orders"
                  type="gradient-base"
              >
                <div v-if="isDocPDF(doc.mimeType)"  class="text-center">
                  <pdf :src="doc.url" class="card-img-top" :page="1" />
                </div>

                <img v-else class="card-img-top"  alt="Image placeholder" src="/img/placeholders/bmis-content-asset-docs.svg">

                <div class="text-center mt-2 mb--3" >
                  <small class="text-muted text-center">
                    <badge type="base" tag="a" target="_blank" :href="doc.url" size="lg">
                      <slot name="icon"
                      ><i class="fa fa-download text-base mr-1"/> Preview Asset
                      </slot>
                    </badge>
                    <br><hr class="mt-2 mb-0">
                    <base-button type="button"
                                 size="sm"
                                 outline
                                 class="btn-sm btn-danger mt-2 mb-0 ml-2"
                                 @click="removeAsset(doc.id, 'doc')">
                      <i class="far fa-trash-alt"/> Delete
                    </base-button>
                  </small>
                </div>

              </base-stats-card>
            </div>
          </div>

        </template>
      </card>
    </div>

    <div class="col-md-6 order-md-1">
      <!-- Landing Layout Theme -->
      <base-premium-overlay :enabled="isFreeUser()">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <p class="mb-0">Event layouts</p>
            </div>
          </div>

          <div class="card-body">
            <!-- Type -->
            <base-input label="Type *">
              <div class="row align-items-around ml-0">
                <base-radio
                    name="base"
                    class="col-md-3 mr-1 ml-1"
                    v-model="item.theme"
                >
                  Default Theme
                </base-radio>

                <base-premium-overlay :enabled="isFreeUser()">
                  <base-radio
                      name="eventalk"
                      class="col-md-4 mr-1 ml-1"
                      v-model="item.theme"
                  >
                    Eventalk Theme
                  </base-radio>
                </base-premium-overlay>

                <base-premium-overlay :enabled="!isDeveloper()"
                                      badge-label="Coming Soon"
                                      badge-style="warning"
                                      badge-message="This new modern theme is under development">

                  <base-radio
                      name="tabloid"
                      class="col-md-5 mb-2"
                      v-model="item.theme"
                  >
                    Tabloid Theme
                  </base-radio>
                </base-premium-overlay>

              </div>
              <div class="row col-md-12 mt-5">
                <base-premium-overlay :enabled="!isDeveloper()"
                                      badge-label="Coming Soon"
                                      badge-style="warning"
                                      badge-message="This new feature is under development">
                  <div class="card-body mt--5">
                    <base-input label="Set a custom color palette">
                      <base-switch
                          class="mr-0"
                          v-model="item.custom_brand_colors"
                          :description="{
                            show: false,
                            content: 'Chose your color palette to create a specific aesthetic and emotional impact in your Event Page design in conveying themes, moods, and brand identities'
                          }"
                          type="danger"
                      />
                    </base-input>
                  </div>
                </base-premium-overlay>

              </div>
            </base-input>
          </div>
        </card>
      </base-premium-overlay>
      
      <card>
        <div slot="header" class="row align-items-center">
          <div class="col-8">
            <p class="mb-0">Video and promo</p>
          </div>
        </div>

        <div class="card-body">
          <form ref="elements_form" @submit.prevent="handleSubmit">
            <div class="form-group">
              <base-input
                label="YouTube Video URL"
                placeholder="https://www.youtube.com/watch?v=..."
                v-model="item.video_url"
              />

              <base-video-you-tube-player
                v-if="item.video_url"
                :yt-video="item.video_url"
                class="custom-form-radius"
              />

              <div v-else class="col-md-12 mb-4">
                <img
                    class="card-img-top"
                  src="/videos/bmis-video-placeholder.jpg"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
          </form>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {
  Select,
  Option,
  Tabs,
  TabPane,
  Collapse,
  CollapseItem,
  Timeline,
  TimelineItem,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
} from "element-ui";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import formMixin from "@/mixins/form-mixin";
import BaseVideoYouTubePlayer from "@/components/Media/BaseVideoYouTubePlayer.vue";
import BasePremiumOverlay from "@/components/Premium/BasePremiumOverlay.vue";
import pdf from 'vue-pdf';
import axios from "axios";

// import { Slider } from "vue-color";
export default {
  name: "edit-event-elements",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    BaseInput,
    BaseVideoYouTubePlayer,
    BasePremiumOverlay,
    pdf
  },
  mixins: [formMixin],
  data() {
    return {
      loading: false,
      activeName: "details",
      updatedItem: { id: null },
      unlimitedAttendees: false,
      tempSelectedTickets: null,
      itemToUpdate: null,
      modals: {
        openBooking: false,
        openVideo: false,
      },
      hover: false,
      itemId: 0,
      all_categories: [],
      all_tags: [],
      all_tickets: [],
      selected_tickets: [],
      tags: [],
      tickets: [],
      allTimezones: [],
      start_at: "",
      end_at: "",
      scheduled_at: "",
      status: "",
      location: null,
      actions: {
        uploadHero: false,
      },
      showAddModal: false,
      showCloneItemModal: false,
      showAddTicketModal: false,
      showPreviewModal: false,
      showSelectAnnouncementTimeModal: false,
      showEditAnnouncementTimeModal: false,
      cloneState: false,
      updateAndPreview: false,
      refundPolicy: null,
      color: {
        base: "#5e72e4ff",
      },
      all_policies: [
        {
          id: 1,
          label:
            "1 Day - Attendees can receive refunds up to 1 day before the event start date.",
        },
        {
          id: 2,
          label:
            "7 Days - Attendees can receive refunds up to 7 days before the event start date.",
        },
        {
          id: 3,
          label:
            "30 Days - Attendees can receive refunds up to 30 days before the event start date.",
        },
        {
          id: 4,
          label:
            "I don't want to specify my refund policy now. I will respond to attendee refund requests on case by case basis.",
        },
        { id: 5, label: "No refunds. We do not offer refunds." },
      ],
      announcements: [],
      newAnnouncement: null,
      announcementToEdit: {},
      dynamicTabs: {
        editableTabsValue: "",
        editableTabs: [
          {
            title: "Agenda",
            name: "1",
            date: null,
            content: "",
          },
        ],
        tabIndex: 1,
        faqEditableTabsValue: "",
        faqEditableTabs: [
          {
            title: "New FAQ Section",
            name: "1",
            content: "",
          },
        ],
        faqTabIndex: 1,
      },
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    this.cloneState = this.$route.query.clone === "true";
    this.showCloneItemModal = this.cloneState;
  },
  computed: {
    heroImage() {
      if (!_.isEmpty(this.item.hero_image)) {
        if (
          this.item.hero_image.includes("http://") ||
          this.item.hero_image.includes("https://")
        ) {
          return this.item.hero_image;
        }
        return process.env.VUE_APP_AWS_S3_URL + this.item.hero_image;
      }

      return (
        process.env.VUE_APP_BASE_URL +
        "/img/brand/bmis-default-asset-placeholder.png"
      );
    },
    // eslint-disable-next-line vue/return-in-computed-property
    additionalImageList() {
      if (!_.isEmpty(this.item.additional_images)) {
        const imgs = [];
        this.item.additional_images.forEach((img) => {
          imgs.push({
            id  : img.id,
            url : process.env.VUE_APP_AWS_S3_URL + img.additional_asset,
            mimeType: img.type,
          });
        });
        return imgs;
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    additionalDocumentsList() {
      if (!_.isEmpty(this.item.additional_docs)) {
        const docs = [];
        this.item.additional_docs.forEach((doc) => {
          docs.push({
            id  : doc.id,
            url : process.env.VUE_APP_AWS_S3_URL + doc.additional_asset,
            mimeType: doc.type,
          });
        });
        return docs;
      }
    },
  },
  methods: {
    uploadAssetUrl(resource, previousImage = null) {
      let assetURL = process.env.VUE_APP_API_BASE_URL +
          "/uploads/items/" +
          this.$route.params.id +
          "/" +
          resource;

      if( !_.isEmpty(previousImage) ) {
        assetURL + "?previousImg=" + previousImage;
      }

      return assetURL;
    },
    async removeAsset(id, type) {
      const url = process.env.VUE_APP_API_BASE_URL;

      const resp = await axios.delete(`${url}/assets/${id}`)

      if(resp.status === 200) {
        setTimeout(() => {
          // final id shall be resolved based on asset type 'asset-img-33
          let elementId = 'asset-' + type + '-' + id;

          document.getElementById(elementId).remove();
        }, 1000);
      }
    },
    isDocPDF(mime) {
      const regex = /pdf/i;

      if(regex.test(mime)) {
        console.log("String contains pdf");
        return true;
      } else {
        console.log("String does not contain pdf");
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./../../../node_modules/flatpickr/dist/flatpickr.min.css";
.custom-form-radius {
  border-radius: calc(0.75rem - 1px) !important;
}
</style>
