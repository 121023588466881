import DashboardLayout from "@/views/App/DashboardLayout.vue";

// Components pages
const ListSurveyPage = () =>
    import(/* webpackChunkName: "components" */ "@/views/Survey/ListSurveyItems.vue");
const AddSurveyPage = () =>
    import(/* webpackChunkName: "components" */ "@/views/Survey/AddSurveyItem.vue");
const EditSurveyPage = () =>
    import(/* webpackChunkName: "components" */ "@/views/Survey/EditSurveyItem.vue");
const CompleteSurveyPage = () =>
    import(/* webpackChunkName: "components" */ "@/views/Survey/CompleteSurveyItem.vue");
import auth from "@/middleware/auth";

let surveyNav = {
    path: "/manage",
    component: DashboardLayout,
    name: "Survey",
    children: [
        {
            path: "survey/list-surveys",
            name: "List Surveys",
            components: { default: ListSurveyPage },
            meta: { middleware: auth }
        },
        {
            path: "survey/add-survey",
            name: "Add Survey",
            components: { default: AddSurveyPage },
            meta: { middleware: auth }
        },
        {
            path: "survey/edit-survey/:id",
            name: "Edit Survey",
            components: { default: EditSurveyPage },
            meta: { middleware: auth }
        },
        {
            path: "survey/complete-survey/:id",
            name: "Complete Survey",
            components: { default: CompleteSurveyPage },
            meta: { middleware: auth }
        },
    ]
}

export default surveyNav;
