<template>
  <div id="bmis-eventalk-layout">

    <!---High Priority announcement-->
    <div class="row align-content-center no-gutters py-0 mb--4">
      <card
          v-if="
            item?.show_latest_announcement &&
            announcements?.importance_type === 'high' &&
            announcements?.message.length > 0
          "
          class="col"
          style="border-style: solid; border-color: #5e72e4; border-width: 2px"
      >
        <div class="row align-items-center">
          <div class="col-auto ml-2">
              <span class="h6 surtitle text-primary ml-3">
                <i class="ni ni-notification-70" />
                EARLY BIRD OFFER
              </span>
          </div>
          <!--            <div class="col text-right">-->
          <!--              <small class="text-muted">-->
          <!--                <i class="fas fa-clock mr-1" />-->
          <!--                {{ displayAnnouncementTime(announcements.updated_at) }}-->
          <!--              </small>-->
          <!--            </div>-->
        </div>
        <div class="col-auto ml-2">
          <h3>{{ announcements.message }}</h3>
        </div>
      </card>
    </div>

    <!---Hero Section-->
    <div id="event-hero-section" class="row align-content-center no-gutters mb--4">

      <div class="card col-md-12 bg-white border-0 shadow-none no-gutters">
            <div class="card-body">
              <div class="row align-content-center bg-indigo" style="border-radius: 0.75rem;">
                <!-- Hero Image Section-->
                <div id="event-hero-image" class="col-md-9 mx-0 my-0 px-0 py-0">
                  <el-image
                      :src="getHeroImage()"
                      @load="heroImageLoaded = true"
                      fit="cover"
                      alt="1"
                      class="d-block rounded"
                      lazy
                  >
                    <div slot="placeholder" class="image-slot">
                      <el-skeleton :rows="7" animated>
                        <template slot="template">
                          <div style="padding: 18px">
                            <el-skeleton-item
                              variant="image"
                              style="width: 100%; height: 720px"
                            /><br />
                          </div>
                        </template>
                      </el-skeleton>
                    </div>
                  </el-image>
                </div>

                <!-- Hero Countdown Section-->
                <card id="event-hero-details" class="col-md-3 bg-transparent border-0 shadow-none">

                    <div class="mb-4">
                      <h2 class="display-2 text-primary">
                        {{ item.name }}
                      </h2>
                      <h4>
                        <i class="fas fa-street-view mr-3"/> {{ getEventFullStatusTypes(item.event_type) }} Event
                      </h4>
                      <h4 >
                        <i class="fa fa-calendar-day mr-3" />  {{ eventStartDateTimeShort }} - {{ eventEndDateTimeShort }}
                      </h4>
                      <h4 >
                        <i class="fa fa-map-marked-alt mr-3"/>
                        <span v-if="venue.name !== venue.address_name">{{ venue.name }} - </span>
                        {{ venue.address_name }}
                      </h4>
                    </div>

                    <!--Event Countdown-->
                    <card gradient="base" hover="info" class="border-0 mt-4 mb--2">
                      <vue-countdown
                          :time="time"
                          :interval="100"
                          v-slot="{ days, hours, minutes, seconds }"
                      >
                      <div class="row">
                        <div class="col order-xs-2">

                          <div class="d-flex justify-content-between">
                            <h1 class="card-title text-uppercase text-muted mb-0 text-white">{{ days }} :
                              <span class="d-block h6 font-weight-bold mb-0 text-white">Days</span>
                            </h1>

                            <h1 class="card-title text-uppercase text-muted mb-0 text-white">{{ hours }} :
                              <br/>
                              <span class="d-block h6 font-weight-bold mb-0 text-white">Hours</span>
                            </h1>

                            <h1 class="card-title text-uppercase text-muted mb-0 text-white">{{ minutes }} :
                              <br/>
                              <span class="d-block h6 font-weight-bold mb-0 text-white">Min</span>
                            </h1>

                            <h1 class="card-title text-uppercase text-muted mb-0 text-white">{{ seconds }}
                              <br/>
                              <span class="d-block h6 font-weight-bold mb-0 text-white">Sec</span>
                            </h1>

                          </div>
                        </div>

      <!--                  <div class="col-auto">-->
      <!--                    <div class="icon icon-shape bg-white text-dark rounded-circle shadow ml-5 mr-1">-->
      <!--                      <i class="fa fa-stopwatch"/>-->
      <!--                    </div>-->
      <!--                  </div>-->

                      </div>
                      </vue-countdown>
                    </card>

                    <!--Event Organizer-->
                    <card>
                      <div class="row align-items-center">
                        <div class="col ml-1">
                            <span class="h6 surtitle text-muted"
                            ><i class="fa fa-headset"></i>
                              ORGANIZED BY
                            </span>
                          <span class="d-block h3">{{ item.organizer }}</span>
                        </div>

                        <template>
                          <div class="col-auto mt-1">
                            <div
                                class="icon icon-shape bg-gradient-base text-white rounded-circle shadow"
                            >
                              <img
                                  v-if="organizerPicture !== null"
                                  class="avatar avatar-md rounded-circle bg-gradient-primary"
                                  style="height: 42px; width: 42px"
                                  :src="organizerPicture"
                              />
                              <i v-else class="fa fa-shapes" />
                            </div>
                          </div>
                        </template>
                      </div>
                    </card>

                    <!--Social share section-->
                    <div class="row">
                      <div class="col mt-4">
                        <div class="d-flex justify-content-between">
                          <h3 class="text-base m1-2" v-if="tags">
                            <i class="fas fa-share mr-1 ml-1 mt-1" /> Share with audience

                            <!-- Card body -->
                            <div class="row align-content-around">
                              <div class="col-md-12 mb-1">

                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="Share this Event via e-mail"
                                    placement="bottom"
                                >
                                  <a
                                      :href="
                                    'mailto:' +
                                    '' +
                                    '?subject=' +
                                    '[Event] ' +
                                    item.name +
                                    ' (by Book Me In Scotty)' +
                                    '&body=' +
                                    '%0D%0A' +
                                    'Hey,' +
                                    '%0D%0A' +
                                    'I found this amazing event - visit here for more: ' +
                                    eventPublicURL +
                                    '%0D%0A' +
                                    virtualLinkMessage +
                                    'Short description:' +
                                    '%0D%0A' +
                                    eventShortDescription +
                                    '%0D%0A' +
                                    '%0D%0A' +
                                    'Event Tags: ' +
                                    getHashtagsForEmail()
                                  "
                                  >
                                    <base-button round class="btn btn-icon-only bg-danger">
                                    <span class="btn-inner--icon">
                                      <i class="fa fa-envelope" aria-hidden="true" />
                                    </span>
                                    </base-button>
                                  </a>
                                </el-tooltip>

                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="Save this Event in your Calendar"
                                    placement="bottom"
                                >
                                  <base-button
                                      round
                                      @click="getCalendarFile()"
                                      class="btn btn-icon-only bg-info ml-2"
                                  >
                                  <span class="btn-inner--icon"
                                  ><i class="fa fa-calendar"
                                  /></span>
                                  </base-button>
                                </el-tooltip>

                                <share-network
                                    network="twitter"
                                    :url="eventPublicURL"
                                    :title="item.name"
                                    :description="item.description"
                                    :quote="item.name"
                                    :hashtags="getHashtags()"
                                    class="mr-2"
                                >
                                  <base-button class="btn-icon-only rounded-circle bg-dark">
                                  <span class="btn-inner--icon"
                                  ><font-awesome-icon icon="fab fa-x-twitter" />
                                  </span>
                                  </base-button>
                                </share-network>

                                <share-network
                                    network="facebook"
                                    :url="eventPublicURL"
                                    :title="item.name"
                                    :description="item.description"
                                    image="https://www.uniconexed.org/wp-content/uploads/canstockphoto21211673-online-event-770x662.jpg"
                                    :quote="item.name"
                                    :hashtags="getHashtags()"
                                    class="mr-2"
                                >
                                  <base-button
                                      type="facebook"
                                      class="btn-icon-only rounded-circle"
                                  >
                                  <span class="btn-inner--icon"
                                  ><i class="fab fa-facebook"></i
                                  ></span>
                                  </base-button>
                                </share-network>

                                <share-network
                                    network="linkedin"
                                    :url="eventPublicURL"
                                    :title="item.name"
                                    :description="item.description"
                                    :quote="item.name"
                                    :hashtags="getHashtags()"
                                    class="mr-2"
                                >
                                  <base-button
                                      type="linkedin"
                                      class="btn-icon-only rounded-circle"
                                  >
                                      <span class="btn-inner--icon"
                                      ><i class="fab fa-linkedin"></i
                                      ></span>
                                  </base-button>
                                </share-network>

                                <share-network
                                    network="whatsapp"
                                    :url="eventPublicURL"
                                    :title="item.name"
                                    :description="item.description"
                                    :quote="item.name"
                                    class="mr-2"
                                >
                                  <base-button
                                      type="whatsapp"
                                      class="btn-icon-only rounded-circle bg-success"
                                  >
                                  <span class="btn-inner--icon"
                                  ><i class="fab fa-whatsapp text-white" />
                                  </span>
                                  </base-button>
                                </share-network>
                              </div>
                            </div>
                          </h3>
                        </div>
                      </div>
                    </div>

                </card>

            </div>
        </div>
      </div>

    </div>

    <!-- Sticky CTA Booking Buttons -->
    <div id="sticky-buttons-top"
      class="row justify-content-center mt-0"
      v-if="hasBookButton"
    >
      <div class="col-md-12 mb--4">
        <card id="cta" type="frame">
          <div class="row align-content-end mt--2 mb-1">

            <div class="col-md-4 mb-2">
              <h6 class="surtitle text-muted mt-3" v-if="item.book_promotion_label !== '#'">
                <i class="fas fa-shopping-basket mr-1"/>
                <span v-if="item.custom_book_button_label && item.book_promotion_label">
                  {{ item.book_promotion_label }}
                </span>
                <span v-else>
                  Book your ticket now with
                </span>
              </h6>
            </div>
            <div class="col-md-4 offset-md-4">
              <book-button-component
                :item="item"
                :modals="modals"
                @addTicket="addTicket($event)"
              ></book-button-component>
            </div>
          </div>
        </card>
      </div>
    </div>

    <!-- Multi-Tabs Section -->
    <div id="event-media-location" class="row align-content-center no-gutters">
      <div id="event-theme-card"  class="card border-0 bg-white shadow-none col">

        <div class="card-body pl-0 pr-0">
          <el-tabs id="event-theme-tabs" v-model="activeTab">

            <!-- Event Details section-->
            <el-tab-pane id="event-details" name="event-details">
              <span slot="label">
                <h2 class="display-6 text-center mt-2 mb-2 text-capitalize">
                  <i class="fa fa-clipboard-list mr-2" />
                  Event Details
                </h2>
              </span>

              <div class="row justify-content-center mt-3">
                <!-- Happening section-->
                <div class="col order-md-0 mt-0">
                  <div class="card bg-white shadow-none no-gutters">
                    <!-- Card body -->
                    <div class="card-body">
                      <h3 class="inline col-md-10 text-base">
                        <i class="fa fa-clipboard-list mr-2" />About this event
                      </h3>
                      <h1 class="mb-2">{{ item.name }}</h1>

                      <span
                        id="expandable-description"
                        :class="{
                          'collapsed-text':
                            textCollapsed && showDescriptionsExpand(),
                        }"
                      >
                        <p class="text-left" style="margin-bottom: 0.5em">
                          {{ item.description }}
                        </p>
                        <div
                          style="margin-bottom: 0.5em"
                          v-html="item.additional_description"
                        ></div>
                      </span>

                      <a
                        @click="textCollapsed = !textCollapsed"
                        class="mt-2 link-view-more float-right"
                        v-if="showDescriptionsExpand()"
                      >
                        <badge type="base" tag="a" size="md">
                          <small style="font-weight: bolder">
                            <i v-if="textCollapsed" class="el-icon-sort-down" />
                            <i v-else class="el-icon-sort-up" />
                            {{
                              textCollapsed
                                ? " Read more details"
                                : " Read less details"
                            }}
                          </small>
                        </badge>
                      </a>

                      <div class="mt-5">
                        <h3
                          class="inline col-md-10 text-base"
                          style="margin-top: 1em"
                        >
                          <i class="fa fa-tags mr-2" />Official hashtags
                        </h3>
                        <div
                          v-for="(tag, index) in getTags()"
                          :key="index"
                          class="badge badge-default badge-pill mr-3"
                          :style="{ backgroundColor: tag.color }"
                        >
                          #{{ tag.name.toUpperCase() }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Happening details-->
                <div class="col-auto order-md-0 order-1">
                  <div class="card bg-white shadow-none no-gutters">
                    <div class="card-body">
                      <h3 class="inline col-md-10 text-base mt-0 mb-3">
                        <i class="fa fa-calendar mr-2" /> Happening details
                      </h3>

                      <div class="d-flex justify-content-between pt-1">
                        <div>
                          <span class="h6 surtitle text-muted">
                            <i class="fas fa-clock" /> WHEN
                          </span>
                        </div>

                      </div>

                      <template>
                        <h2 v-if="eventIsSingleDay">
                          <div class="card card-profile border-0 bg-white shadow-none">
                            <div class="card-body py-0 px-0 ">
                              <div class="row">
                                <div class="col">
                                  <div class="card-profile-stats d-flex justify-content-center">
                                    <div>
                                        <span class="heading" >
                                          {{ eventStartDateTimeShort }}
                                        </span>
                                      <span class="description text-capitalize">from</span>
                                      <span class="heading">
                                        {{ localStartTime }} <small class="text-muted text-capitalize"> to </small> {{ localEndTime }}
                                        </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </h2>

                        <p v-else class="card-text">
                          <strong >

                            <div class="card card-profile border-0 bg-white shadow-none">
                              <div class="card-body py-0 px-0 mt--4">
                                <div class="row">
                                  <div class="col">
                                    <div class="card-profile-stats d-flex justify-content-center">
                                      <div>
                                        <span class="heading text-capitalize" >
                                          {{ eventStartDateTimeShort }}
                                        </span>
                                        <span class="description">from</span>
                                        <span class="heading text-sm">
                                          {{ localStartTime }}
                                        </span>
                                      </div>
                                      <div>
                                        <span class="description">&nbsp;</span>
                                        <span class="heading">
                                          -
                                        </span>
                                      </div>
                                      <div>
                                        <span class="heading text-capitalize" >
                                          {{ eventEndDateTimeShort }}
                                        </span>
                                        <span class="description">until</span>
                                        <span class="heading text-sm ">
                                          {{ localEndTime }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </strong>

                        </p>
                      </template>

                      <div class="row">
                        <div class="col">
                          <span class="h6 surtitle text-muted"
                            ><i class="ni ni-pin-3 mr-1"/> WHERE
                          </span>

                          <div v-if="item.event_type === 'physical'" class="text-center mt-2">
                            <span class="heading" v-if="venue.name !== venue.address_name">{{ venue.name }} <br/> </span>
                            <span class="heading">{{ venue.address_name }}</span>
                          </div>

                          <div v-else-if="item.event_type === 'hybrid'">
                            <span class="d-block h3">{{
                              venue.address_name
                            }}
                            </span>

                            <hr>
                            <base-alert type="base">
                              <strong>
                                <i class="fa fa-satellite-dish mr-4" />
                                This {{ item.event_type }} event will be
                                {{
                                  item.event_type === "hybrid" ? "also" : "only"
                                }}
                                available online
                              </strong>
                            </base-alert>

                          </div>

                          <div v-else-if="item.event_type === 'virtual'">
                            <base-alert type="secondary" class="ml--2">
                              <p class="text-muted text-wrap mt--4 mb--2">
                                This event is
                                {{
                                  item.event_type === "hybrid" ? "also" : "only"
                                }}
                                available online
                                <i class="fa fa-satellite-dish" />
                              </p>
                            </base-alert>
                          </div>

                          <div v-else-if="item.event_type === 'tba'">
                            <h4>To-Be Announced Soon!</h4>
                          </div>

                        </div>

                        <!--Normal Announcement-->
                        <div class="col-md-12 order-md-0 mt-2">
                          <card
                            v-if="
                              item?.show_latest_announcement &&
                              announcements?.importance_type === 'normal' &&
                              announcements?.message.length > 0
                            "
                            style="
                              border-style: solid;
                              border-color: #5e72e4;
                              border-width: 2px;
                            "
                          >
                            <div class="row align-items-center">
                              <div class="col-auto ml-2">
                                <span class="h6 surtitle text-muted">
                                  <i class="ni ni-notification-70" />
                                  Announcement
                                </span>
                              </div>
                              <div class="col text-right">
                                <small class="text-muted">
                                  <i class="fas fa-clock mr-1" />
                                  {{
                                    displayAnnouncementTime(
                                      announcements.updated_at
                                    )
                                  }}
                                </small>
                              </div>
                            </div>
                            <div class="col-auto ml-2">
                              <h3>{{ announcements.message }}</h3>
                            </div>
                          </card>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>

                <!-- Content Section -->
                <div id="event-document-asset" class="col-md-12 border-0 bg-white py-0 px-0"
                     v-show="getDocuments().length > 0"
                >
                  <div class="row justify-content-center ml-0 mr-0  ">
                    <div
                        class="col"
                        v-for="(doc, idx) in getDocuments()"
                        :key="idx"
                    >
                      <!-- PDF Viewer -->
                      <base-stats-card
                          title="Total ticket orders"
                          type="gradient-base"
                      >
                        <div v-if="isDocPDF(doc.mimeType)"  class="text-center">
                          <pdf :src="doc.src" class="card-img-top" :page="1" style="width: 100%"/>
                        </div>

                        <img v-else
                             class="card-img-top"
                             alt="Image placeholder"
                             src="/img/placeholders/bmis-content-asset-docs.svg"
                        />
                        <div class="text-center mt-2 mb--3">
                          <small class="text-muted text-center">
                            <badge type="base" tag="a" :href="doc.src" size="lg">
                              <slot name="icon"
                              ><i class="fa fa-download text-base mr-1" />
                                Download
                              </slot>
                            </badge>
                          </small>
                        </div>
                      </base-stats-card>
                      <!-- ./ PDF Viewer -->
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>

            <!--Event Agenda section--->
            <el-tab-pane id="event-agenda" name="event-agenda"
                         v-if="item.event_agenda"
            >
              <span slot="label">
                <h2 class="display-7 text-center mt-2 mb-1 text-capitalize">
                  <i class="el-icon-s-management mr-1" />
                  Detailed agenda
                </h2>
              </span>

              <div class="card bg-white border-0 shadow-none no-gutters">
                <!-- Card body -->
                <div class="card-body px-0 mt--4">
                  <div class="row justify-content-center">
                    <div class="col">
    <!--                  <h3 class="card-title inline text-base ml-3 text-capitalize">-->
    <!--                    <i class="el-icon-s-management mr-1" />Agenda Summary-->
    <!--                  </h3>-->
                      <el-tabs type="card">
                        <el-tab-pane
                            v-for="(_item, idx) in item?.event_agenda"
                            :key="_item + idx"
                        >
                      <span slot="label">
                        <h3 class="card-title mb--2 mt-2">{{ _item.title }}</h3>
                        <small class="mt-0">
                          {{ getAgendaLabel(_item) }}
                        </small>
                      </span>

                          <div class="d-flex text-wrap">
                            <div class="card-text" v-html="_item.content"></div>
                          </div>
                        </el-tab-pane>
                      </el-tabs>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>

            <!--Event Speakers section--->
            <el-tab-pane id="event-speakers" name="event-speakers"
                         v-if="isDeveloper()"
            >
              <span slot="label">
                <h2 class="display-7 text-center mt-2 mb-2 text-capitalize">
                  <i class="fa fa-microphone-alt mr-1"/>
                  Speakers Lineup
                </h2>
              </span>
              <div class="row justify-content-center mt-3">
                <div class="col">

                  <!-- Speakers -->
                  <div class="row">

                    <div class="col">
                      <div class="card card-profile">
                        <el-image
                            :src="getHeroImage()"
                            @load="heroImageLoaded = true"
                            alt="1"
                            class="card-img-top rounded w-100"/>

                        <div class="row justify-content-center">
                          <div class="col-lg-3 order-lg-2">
                            <div class="card-profile-image">
                              <a href="#">
                                <img src="/img/theme/team-4.jpg" class="rounded-circle">
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                          <div class="d-flex justify-content-between">
                            <!--              <a href="#" class="btn btn-sm btn-info mr-4">Connect</a>-->
                            <!--              <a href="#" class="btn btn-sm btn-default float-right">Message</a>-->
                          </div>
                        </div>

                        <div class="card-body pt-0">
                          <div class="row">

                            <div class="col">
                              <div class="card-profile-stats d-flex justify-content-center">

                                <div class="text-center">
                                  <h5 class="h1 text-uppercase">
                                    Jessica Jones
                                  </h5>
                                  <div class="h3 mt-2">
                                    <i class="fas fa-business-time text-base mr-1"/> Co-Founder & CEO
                                  </div>
                                  <div>
                                    <i class="fa fa-building text-base mr-1"/> Book Me In Scotty PTY LtD
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="card card-profile">
                        <el-image
                            :src="getHeroImage()"
                            @load="heroImageLoaded = true"
                            alt="1"
                            class="card-img-top rounded w-100"/>

                        <div class="row justify-content-center">
                          <div class="col-lg-3 order-lg-2">
                            <div class="card-profile-image">
                              <a href="#">
                                <img src="/img/theme/team-4.jpg" class="rounded-circle">
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                          <div class="d-flex justify-content-between">
                            <!--              <a href="#" class="btn btn-sm btn-info mr-4">Connect</a>-->
                            <!--              <a href="#" class="btn btn-sm btn-default float-right">Message</a>-->
                          </div>
                        </div>

                        <div class="card-body pt-0">
                          <div class="row">

                            <div class="col">
                              <div class="card-profile-stats d-flex justify-content-center">

                                <div class="text-center">
                                  <h5 class="h1 text-uppercase">
                                    Jessica Jones
                                  </h5>
                                  <div class="h3 mt-2">
                                    <i class="fas fa-business-time text-base mr-1"/> Co-Founder & CEO
                                  </div>
                                  <div>
                                    <i class="fa fa-building text-base mr-1"/> Book Me In Scotty PTY LtD
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="card card-profile">
                        <el-image
                            :src="getHeroImage()"
                            @load="heroImageLoaded = true"
                            alt="1"
                            class="card-img-top rounded w-100"/>

                        <div class="row justify-content-center">
                          <div class="col-lg-3 order-lg-2">
                            <div class="card-profile-image">
                              <a href="#">
                                <img src="/img/theme/team-4.jpg" class="rounded-circle">
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                          <div class="d-flex justify-content-between">
                            <!--              <a href="#" class="btn btn-sm btn-info mr-4">Connect</a>-->
                            <!--              <a href="#" class="btn btn-sm btn-default float-right">Message</a>-->
                          </div>
                        </div>

                        <div class="card-body pt-0">
                          <div class="row">

                            <div class="col">
                              <div class="card-profile-stats d-flex justify-content-center">

                                <div class="text-center">
                                  <h5 class="h1 text-uppercase">
                                    Jessica Jones
                                  </h5>
                                  <div class="h3 mt-2">
                                    <i class="fas fa-business-time text-base mr-1"/> Co-Founder & CEO
                                  </div>
                                  <div>
                                    <i class="fa fa-building text-base mr-1"/> Book Me In Scotty PTY LtD
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="card card-profile">
                        <el-image
                            :src="getHeroImage()"
                            @load="heroImageLoaded = true"
                            alt="1"
                            class="card-img-top rounded w-100"/>

                        <div class="row justify-content-center">
                          <div class="col-lg-3 order-lg-2">
                            <div class="card-profile-image">
                              <a href="#">
                                <img src="/img/theme/team-4.jpg" class="rounded-circle">
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                          <div class="d-flex justify-content-between">
                            <!--              <a href="#" class="btn btn-sm btn-info mr-4">Connect</a>-->
                            <!--              <a href="#" class="btn btn-sm btn-default float-right">Message</a>-->
                          </div>
                        </div>

                        <div class="card-body pt-0">
                          <div class="row">

                            <div class="col">
                              <div class="card-profile-stats d-flex justify-content-center">

                                <div class="text-center">
                                  <h5 class="h1 text-uppercase">
                                    Jessica Jones
                                  </h5>
                                  <div class="h3 mt-2">
                                    <i class="fas fa-business-time text-base mr-1"/> Co-Founder & CEO
                                  </div>
                                  <div>
                                    <i class="fa fa-building text-base mr-1"/> Book Me In Scotty PTY LtD
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="card card-profile">
                        <el-image
                            :src="getHeroImage()"
                            @load="heroImageLoaded = true"
                            alt="1"
                            class="card-img-top rounded w-100"/>

                        <div class="row justify-content-center">
                          <div class="col-lg-3 order-lg-2">
                            <div class="card-profile-image">
                              <a href="#">
                                <img src="/img/theme/team-4.jpg" class="rounded-circle">
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                          <div class="d-flex justify-content-between">
                            <!--              <a href="#" class="btn btn-sm btn-info mr-4">Connect</a>-->
                            <!--              <a href="#" class="btn btn-sm btn-default float-right">Message</a>-->
                          </div>
                        </div>

                        <div class="card-body pt-0">
                          <div class="row">

                            <div class="col">
                              <div class="card-profile-stats d-flex justify-content-center">

                                <div class="text-center">
                                  <h5 class="h1 text-uppercase">
                                    Jessica Jones
                                  </h5>
                                  <div class="h3 mt-2">
                                    <i class="fas fa-business-time text-base mr-1"/> Co-Founder & CEO
                                  </div>
                                  <div>
                                    <i class="fa fa-building text-base mr-1"/> Book Me In Scotty PTY LtD
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="card card-profile">
                        <el-image
                            :src="getHeroImage()"
                            @load="heroImageLoaded = true"
                            alt="1"
                            class="card-img-top rounded w-100"/>

                        <div class="row justify-content-center">
                          <div class="col-lg-3 order-lg-2">
                            <div class="card-profile-image">
                              <a href="#">
                                <img src="/img/theme/team-4.jpg" class="rounded-circle">
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                          <div class="d-flex justify-content-between">
                            <!--              <a href="#" class="btn btn-sm btn-info mr-4">Connect</a>-->
                            <!--              <a href="#" class="btn btn-sm btn-default float-right">Message</a>-->
                          </div>
                        </div>

                        <div class="card-body pt-0">
                          <div class="row">

                            <div class="col">
                              <div class="card-profile-stats d-flex justify-content-center">

                                <div class="text-center">
                                  <h5 class="h1 text-uppercase">
                                    Jessica Jones
                                  </h5>
                                  <div class="h3 mt-2">
                                    <i class="fas fa-business-time text-base mr-1"/> Co-Founder & CEO
                                  </div>
                                  <div>
                                    <i class="fa fa-building text-base mr-1"/> Book Me In Scotty PTY LtD
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="card card-profile">
                        <el-image
                            :src="getHeroImage()"
                            @load="heroImageLoaded = true"
                            alt="1"
                            class="card-img-top rounded w-100"/>

                        <div class="row justify-content-center">
                          <div class="col-lg-3 order-lg-2">
                            <div class="card-profile-image">
                              <a href="#">
                                <img src="/img/theme/team-4.jpg" class="rounded-circle">
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                          <div class="d-flex justify-content-between">
                            <!--              <a href="#" class="btn btn-sm btn-info mr-4">Connect</a>-->
                            <!--              <a href="#" class="btn btn-sm btn-default float-right">Message</a>-->
                          </div>
                        </div>

                        <div class="card-body pt-0">
                          <div class="row">

                            <div class="col">
                              <div class="card-profile-stats d-flex justify-content-center">

                                <div class="text-center">
                                  <h5 class="h1 text-uppercase">
                                    Jessica Jones
                                  </h5>
                                  <div class="h3 mt-2">
                                    <i class="fas fa-business-time text-base mr-1"/> Co-Founder & CEO
                                  </div>
                                  <div>
                                    <i class="fa fa-building text-base mr-1"/> Book Me In Scotty PTY LtD
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="card card-profile">
                        <el-image
                            :src="getHeroImage()"
                            @load="heroImageLoaded = true"
                            alt="1"
                            class="card-img-top rounded w-100"/>

                        <div class="row justify-content-center">
                          <div class="col-lg-3 order-lg-2">
                            <div class="card-profile-image">
                              <a href="#">
                                <img src="/img/theme/team-4.jpg" class="rounded-circle">
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                          <div class="d-flex justify-content-between">
                            <!--              <a href="#" class="btn btn-sm btn-info mr-4">Connect</a>-->
                            <!--              <a href="#" class="btn btn-sm btn-default float-right">Message</a>-->
                          </div>
                        </div>

                        <div class="card-body pt-0">
                          <div class="row">

                            <div class="col">
                              <div class="card-profile-stats d-flex justify-content-center">

                                <div class="text-center">
                                  <h5 class="h1 text-uppercase">
                                    Jessica Jones
                                  </h5>
                                  <div class="h3 mt-2">
                                    <i class="fas fa-business-time text-base mr-1"/> Co-Founder & CEO
                                  </div>
                                  <div>
                                    <i class="fa fa-building text-base mr-1"/> Book Me In Scotty PTY LtD
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </el-tab-pane>

            <!--Media Gallery section--->
            <el-tab-pane id="event-media-gallery" name="event-media-gallery"
                         v-if="getImages().length > 0"
            >
              <span slot="label">
                <h2 class="display-6 text-center mt-2 mb-2 text-capitalize">
                  <i class="fa fa-video mr-1" />
                  Media gallery
                </h2>
              </span>

              <div class="row justify-content-center">
              <!-- Video Trailer-->
                <div class="col-md-6 order-md-0 mt-0" v-if="item.video_url">
                  <base-video-you-tube-player
                      :yt-video="item.video_url"
                      :height="'720'"
                      :width="'1280'"
                      class="custom-form-radius"
                  />
                </div>

                <!--Gallery Section -->
                <div :class=" !item.video_url ? 'col-md-11' : 'col-md-6'" class="mt-4" v-show="getImages().length > 0">

                  <div class="row justify-content-center">
                    <div class="col">
                      <el-carousel :interval="4000" arrow="always" height="680px">
                        <el-carousel-item
                            v-for="(image, imgIdx) in getImages()"
                            :key="imgIdx"
                        >
                          <el-image
                              @click="idx = imgIdx"
                              :src="image.src"
                              :alt="image.id"
                              fit="scale-down"
                          >
                            <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                          </el-image>
                        </el-carousel-item>
                      </el-carousel>

                      <!-- Image viewer -->
                      <vue-cool-lightbox
                          :items="getImages()"
                          :index="idx"
                          @close="idx = null"
                      />
                    </div>
                  </div>

                </div>
                <!-- Video Trailer -->
              </div>

            </el-tab-pane>

            <!--Content Hub section--->
            <el-tab-pane id="event-content-hub" name="event-content-hub"
                         v-if="getDocuments().length > 0 && isDeveloper()"
            >
              <span slot="label">
                <h2 class="display-6 text-center mt-2 mb-2 text-capitalize">
                  <i class="fa fa-folder mr-2" />
                  Content hub
                </h2>
              </span>

              <!-- Content Section -->
              <div id="event-document-asset" v-show="getDocuments().length > 0">
                <div class="row justify-content-center ml-0 mr-0  ">
                  <div
                    class="col"
                    v-for="(doc, idx) in getDocuments()"
                    :key="idx"
                  >
                    <!-- PDF Viewer -->
                    <base-stats-card
                      title="Total ticket orders"
                      type="gradient-base"
                    >
                      <div v-if="isDocPDF(doc.mimeType)"  class="text-center">
                        <pdf :src="doc.src" class="card-img-top" :page="1" style="width: 100%"/>
                      </div>

                      <img v-else
                        class="card-img-top"
                        alt="Image placeholder"
                        src="/img/placeholders/bmis-content-asset-docs.svg"
                      />
                      <div class="text-center mt-2 mb--3">
                        <small class="text-muted text-center">
                          <badge type="base" tag="a" :href="doc.src" size="lg">
                            <slot name="icon"
                              ><i class="fa fa-download text-base mr-1" />
                              Download
                            </slot>
                          </badge>
                        </small>
                      </div>
                    </base-stats-card>
                    <!-- ./ PDF Viewer -->
                  </div>
                </div>
              </div>
            </el-tab-pane>

            <!--Venue Location section--->
            <el-tab-pane id="event-location" name="event-location">
              <span slot="label">
                <h2 class="display-7 text-center mt-2 mb-2 text-capitalize">
                  <i class="fa fa-map-marked-alt mr-1" />
                  {{ getMapLabel() }}
                </h2>
              </span>

              <div
                  id="event-happening"
                  class="col-md-12 px-0"
                  v-if="
                  item.event_type === 'physical' ||
                  item.event_type === 'hybrid' ||
                  item.event_type === 'virtual'
                "
              >
                <card
                    v-if="
                    item.event_type === 'hybrid' || item.event_type === 'virtual'
                  "
                >
                  <div class="row align-items-">
                    <div class="col">
                      <span class="h6 surtitle text-muted">
                        <i class="fa fa-satellite-dish" />
                        Online Event
                      </span>
                      <h3 class="display-3 mb-2 ml-4">
                        Join the online event via
                      </h3>
                    </div>

                    <div class="col-auto mt-3">
                      <a
                          :href="item.virtual_link"
                          target="_blank"
                          class="text-white"
                      >
                        <base-button block type="base" :disabled=" item.virtual_link ==='#' " target="_blank" class="mr-5">
                          <i class="fa fa-satellite-dish ml-1 mr-2" />
                          {{ getVirtualLink(item.virtual_link) }}
                        </base-button>
                      </a>
                    </div>
                  </div>
                </card>
                <base-mapbox
                    :long="venue.long"
                    :lat="venue.lat"
                    label="Event Location"
                    :search="false"
                    :draggable-pin="false"
                    :enable-shortcuts="true"
                    :scrollable="true"
                    :navigation-btn="true"
                    :horizontal-layout="true"
                    v-if="
                    venue.long &&
                    venue.lat &&
                    (item.event_type === 'physical' ||
                      item.event_type === 'hybrid' ||
                      item.event_type === 'Location')
                  "
                />

                <div
                    class="col-md-12 mt--5"
                    v-else-if="item.event_type === 'tba'"
                >
                  <card>
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <span class="h6 surtitle text-muted"
                        ><i class="fas fa-bullhorn"></i>
                          To-Be Announced
                        </span>
                        <h2 class="display-2 text-center">
                          More to come...Stay tunned :-)
                        </h2>
                      </div>
                    </div>
                  </card>
                </div>
              </div>

            </el-tab-pane>

          </el-tabs>
        </div>

      </div>
    </div>


    <!-- BMIS Banner -->
<!--    <div class="row justify-content-center mt&#45;&#45;1" id="bmis-cta-banner">-->
<!--      <div class="col-md-12">-->
<!--        <card class="bg-gradient-primary">-->
<!--          &lt;!&ndash; Card body &ndash;&gt;-->
<!--          <div class="row">-->
<!--            <div class="col">-->
<!--              <h5 class="card-title text-uppercase text-muted text-white">-->
<!--                Seize The Moment-->
<!--              </h5>-->
<!--            </div>-->
<!--            <div class="col-auto mt-1">-->
<!--              <img :src="logo" width="28" height="45" />-->
<!--            </div>-->
<!--          </div>-->
<!--          <p class="text-sm mb&#45;&#45;2">-->
<!--            <span class="text-nowrap text-light"-->
<!--              >Don't miss out, book your slot now!-->
<!--            </span>-->
<!--          </p>-->
<!--        </card>-->
<!--      </div>-->
<!--    </div>-->

    <!--Medium  Announcement-->
    <div class="row justify-content-center" id="event-latest-high-announcement">
      <div class="col-md-10 mt-1">
        <card
          v-if="
            item?.show_latest_announcement &&
            announcements?.importance_type === 'medium' &&
            announcements?.message.length > 0
          "
          style="border-style: solid; border-color: #5e72e4; border-width: 2px"
        >
          <div class="row align-items-center">
            <div class="col-auto ml-2">
              <span class="h6 surtitle text-muted">
                <i class="ni ni-notification-70" />
                Announcement
              </span>
            </div>
            <div class="col text-right">
              <small class="text-muted">
                <i class="fas fa-clock mr-1" />
                {{ displayAnnouncementTime(announcements.updated_at) }}
              </small>
            </div>
          </div>
          <div class="col-auto ml-2">
            <h3>{{ announcements.message }}</h3>
          </div>
        </card>
      </div>
    </div>

    <div id="event-faqs" class="row justify-content-center mr-1 ml-1 mt-5">
      <div class="col-md-12" v-if="item.event_faqs !== null">
        <h1 class="display-4 text-center">
          <i class="far fa-question-circle mr-2" />
          FAQs
        </h1>

        <el-collapse accordion>
          <el-collapse-item
            v-for="(faq, idx) in item.event_faqs"
            :key="idx"
            :name="faq.name"
          >
            <template slot="title">
              {{ faq.title }}
            </template>
            <div v-html="faq.content"></div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

  </div>
</template>

<script>
import {
  Button,
  Option,
  Select,
  Image,
  Carousel,
  CarouselItem,
  Tooltip,
  Skeleton,
  SkeletonItem,
  Collapse,
  CollapseItem,
  InputNumber,
  Steps,
  Step,
  Tabs,
  TabPane,
  Timeline,
  TimelineItem,
} from "element-ui";
import BaseVideoYouTubePlayer from "@/components/Media/BaseVideoYouTubePlayer.vue";
import { TimeLine, TimeLineItem, LoadingPanel } from "@/components";
import BaseSwitchVue from "../BaseSwitch.vue";
import BookButtonComponent from "./BookButtonComponent.vue";
import Card from "../Cards/Card.vue";
import BaseMapbox from "@/components/Mapbox/BaseMapbox.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
// import BarChart from "@/components/Charts/BarChart";
import pdf from "vue-pdf";

const moment = require("moment-timezone");

export default {
  name: "eventalk-landing-page",
  components: {
    pdf,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Image.name]: Image,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Tooltip.name]: Tooltip,
    [Skeleton.name]: Skeleton,
    [SkeletonItem.name]: SkeletonItem,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [InputNumber.name]: InputNumber,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [TimeLine.name]: TimeLine,
    [TimeLineItem.name]: TimeLineItem,
    [BaseSwitchVue]: BaseSwitchVue,
    BaseMapbox,
    BaseVideoYouTubePlayer,
    VueCountdown,
    Card,
    BookButtonComponent,
  },

  props: {
    item: {
      type: Object,
      default: null,
    },
    modals: {
      type: Object,
      default: null,
    },
    hasBookButton: {
      type: Boolean,
      default: true,
    },
    onlyBookButton: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: String,
      default: process.env.VUE_APP_DEFAULT_LOGO,
      description: "Scotty full logo"
    },
  },
  data() {
    return {
      activeTab: "event-details",
      freePersonalizedGuests: [],
      announcements: {
        message: "",
      },
      attendee: {
        name: "",
        email: "",
        special_requirements: this.hasDietaryRequirements() ? "" : undefined,
        type: "attendees",
      },
      tags: [],
      venue: {},
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      textCollapsed: true,
      showSponsors: false,
      showSpeakers: false,
      showAnnouncement: false,
      heroImageLoaded: false,
      activeStep: 0,
      idx: 0,
      loading: false,
    };
  },
  created() {
    this.setItem();
    this.getTickets();
    this.getTags();
    this.displayLatestAnnouncement();

    this.items = {
      item: this.item,
      tags: this.tags,
      dates: [],
    };
  },
  computed: {
    user() {
      let me = null;
      try {
        // Get User Profile
        me = this.$store.getters["profile/me"];
      } catch (error) {
        // Notification
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong with your account!",
        });
      }
      // return user object
      return me;
    },
    time() {
      const now = new Date();
      const beginAt = moment(this.items.item.start_at).toDate();

      return beginAt - now;
    },
    organizerPicture() {
      if (
        this.item?.user?.profile_image.includes("http://") ||
        this.item?.user?.profile_image.includes("https://")
      ) {
        return this.item?.user?.profile_image;
      }
      return this.item?.user?.profile_image
        ? process.env.VUE_APP_AWS_S3_URL + this.item?.user?.profile_image
        : null;
    },

    eventStartDateTime() {
      return moment(this.items.item.start_at).format("ddd, DD MMM YY");
    },
    eventStartDateTimeShort() {
      return moment(this.items.item.start_at).format(" ddd, Do MMM ");
    },
    eventEndDateTime() {
      return moment(this.items.item.end_at).format("YYYY");
    },
    eventEndDateTimeShort() {
      return moment(this.items.item.end_at).format("ddd, Do MMM ");
    },
    eventTotalDuration() {
      const startAt = moment(this.items.item.start_at);
      const endAt = moment(this.items.item.end_at);

      return endAt.diff(startAt, "days");
    },
    eventIsSingleDay() {
      const startAt = moment(this.items.item.start_at);
      const endAt = moment(this.items.item.end_at);

      return endAt.isSame(startAt, "day");
    },
    eventStartTime() {
      return (
        moment(this.items.item.start_at)
          // .tz(this.items.item.timezone)
          .format("LT")
      );
    },
    eventEndTime() {
      return (
        moment(this.items.item.end_at)
          // .tz(this.items.item.timezone)
          .format("LT")
      );
    },
    localTimezone() {
      const tz = this.timezone ?? this.user?.timezone;
      return (
        "The times for the events are shown in your own local timezone (" +
        tz +
        ")"
      );
    },
    originalTimezone() {
      return (
        "The times for the events are shown in your own local timezone (" +
        this.items.item.timezone +
        ")"
      );
    },
    isHybridSameTimezone() {
      return (
        this.items.item.event_type === "hybrid" &&
        this.items.item.timezone === this.timezone
      );
    },
    localStartTime() {
      const originalTime = moment.tz(
        this.items.item.start_at,
        this.items.item.timezone
      );
      return originalTime.clone().tz(this.timezone).format("LT");
    },
    localEndTime() {
      const originalTime = moment.tz(
        this.items.item.end_at,
        this.items.item.timezone
      );
      return originalTime.clone().tz(this.timezone).format("LT");
    },
    eventPublicURL() {
      const itemId = _.isEmpty(this.item.hash_code)
        ? this.item.id
        : this.item.hash_code;
      return process.env.VUE_APP_BASE_URL + "/event/" + itemId;
    },
    eventShortDescription() {
      return this.item.description
        ? this.item.description.substring(0, 140) + "..."
        : " ";
    },
    virtualLinkMessage() {
      if (this.item.virtual_link) {
        return (
          "You can attend event through this link: " +
          this.item.virtual_link +
          "%0D%0A" +
          "%0D%0A"
        );
      } else return "%0D%0A";
    },
  },
  methods: {
    setItem() {
      this.tags = this.item?.tags;
      this.venue = this.item.venues ?? {};
    },
    addTicket(attendee) {
      this.$emit("addTicket", attendee);
    },
    resizeMap() {
      this.$emit("flightNight", 'Hello World');
    },
    setTicketToAttendee(ticket) {
      this.attendee.item_id = this.item.id;
      if (ticket?.selectedQuantity) {
        this.attendee.ticket_id = ticket.id;
        this.attendee.price = ticket.metadata.price;
        this.attendee.final_price = ticket.metadata.final_price;
        this.attendee.quantity = ticket.selectedQuantity;
        this.attendee.ticket_name = ticket.name;
        this.attendee.personalized_ticket = ticket.metadata.personalized_ticket;
        if (
          ticket.metadata.personalized_ticket === 1 &&
          (ticket.metadata.final_price === 0 || ticket.metadata.price === 0)
        ) {
          this.freePersonalizedGuests = [];
          for (let i = 1; i <= ticket.selectedQuantity; i++) {
            this.freePersonalizedGuests.push(
              JSON.parse(JSON.stringify(this.attendee))
            );
          }
        } else {
          this.freePersonalizedGuests = [];
        }
      } else {
        this.attendee.ticket_id = undefined;
        this.attendee.price = undefined;
        this.attendee.final_price = undefined;
        this.attendee.quantity = undefined;
        this.attendee.ticket_name = undefined;
        this.attendee.personalized_ticket = undefined;
        this.freePersonalizedGuests = [];
      }
    },
    getTickets() {
      // we used to return only available tickets
      // let availableTickets = [];
      // this.item?.event_tickets.forEach((ticket) => {
      //   if (
      //     moment().isBetween(
      //       ticket.metadata.start_sale_at,
      //       ticket.metadata.end_sale_at,
      //       "day",
      //       "[]"
      //     ) &&
      //     (ticket?.metadata?.sold_quantity <=
      //       ticket?.metadata?.total_quantity ||
      //       ticket?.metadata?.total_quantity === -1)
      //   ) {
      //     availableTickets.push(ticket);
      //   }
      // });
      // return availableTickets;
      return this.item?.event_tickets || [];
    },
    ticketIsAvailable(ticket) {
      return (
        moment().isBetween(
          ticket.metadata.start_sale_at,
          ticket.metadata.end_sale_at,
          "day",
          "[]"
        ) &&
        (ticket?.metadata?.sold_quantity <= ticket?.metadata?.total_quantity ||
          ticket?.metadata?.total_quantity === -1)
      );
    },
    ticketIsSoldOut(ticket) {
      return !(
        ticket?.metadata?.sold_quantity <= ticket?.metadata?.total_quantity ||
        ticket?.metadata?.total_quantity === -1
      );
    },
    ticketIsNotAvailableAnyMore(ticket) {
      return moment().isAfter(
        ticket.metadata.start_sale_at,
        ticket.metadata.end_sale_at,
        "day",
        "[]"
      );
    },
    ticketIsNotAvailableYet(ticket) {
      return moment().isBefore(
        ticket.metadata.start_sale_at,
        ticket.metadata.end_sale_at,
        "day",
        "[]"
      );
    },
    getTags() {
      return this.tags;
    },
    getAvailability(available) {
      return moment(available).format("Do MMM YYYY, h:mm:ss a");
    },
    getHeroImage() {
      if (
        this.items?.item?.hero_image.includes("http://") ||
        this.items?.item?.hero_image.includes("https://")
      ) {
        return this.items?.item?.hero_image;
      }
      return process.env.VUE_APP_AWS_S3_URL + this.items?.item?.hero_image;
    },
    getImages() {
      console.log('Images', this.items.item?.additional_images)
      return this.items.item?.additional_images.map((image) => ({
        id: image.id,
        src: process.env.VUE_APP_AWS_S3_URL + image.additional_asset,
      }));
    },
    getSpeakers() {
      return [];
    },
    openModal() {
      try {
        // Send action to Google Analytics
        this.$gtag.event("BMIS-cta-open-ticket-modal", {
          eventId: this.item.id,
        });
      } catch (error) {
        // Notification
        this.$notify({
          type: "danger",
          message:
            "Oops, something went wrong with this action, we will handle it soon!",
        });
      }

      // reset step to 0
      this.activeStep = 0;

      // reset selectedQuantity to 0
      this.getTickets().forEach((ticket) => {
        ticket.selectedQuantity = 0;
      });
      this.freePersonalizedGuests = [];
      this.attendee = {
        name: "",
        email: "",
        special_requirements: this.hasDietaryRequirements() ? "" : undefined,
        type: "attendees",
      };
      this.loading = false;
      this.modals.openBooking = true;
    },
    getDocuments() {
      return this.items.item?.additional_docs.map((doc) => ({
        id: doc.id,
        src: process.env.VUE_APP_AWS_S3_URL + doc.additional_asset,
        mimeType: doc.type,
      }));
    },
    isDocPDF(mime) {
      const regex = /pdf/i;

      if (regex.test(mime)) {
        console.log("String contains pdf");
        return true;
      } else {
        console.log("String does not contain pdf");
        return false;
      }
    },
    showDescriptionsExpand() {
      return (
        this.item?.description?.length > 1000 ||
        this.item?.additional_description?.length > 1000
      );
    },
    getHashtags() {
      const tagsName = ["bookmeinscotty"];
      this.tags.forEach((tag) => {
        tagsName.push(tag.name);
      });
      return tagsName.join();
    },
    getHashtagsForEmail() {
      const tagsName = [" #bookmeinscotty"];
      this.tags.forEach((tag) => {
        tagsName.push(" #" + tag.name);
      });
      return tagsName.join();
    },
    getVirtualLink(link) {
      const zoomPattern = /zoom/;
      if (zoomPattern.test(link)) {
        return "Zoom";
      }

      const meetPattern = /meet/;
      if (meetPattern.test(link)) {
        return "Google Meet";
      }

      const teamsPattern = /teams/;
      if (teamsPattern.test(link)) {
        return "Microsoft Teams";
      }

      return "Virtual Link";
    },
    getCalendarFile() {
      this.$ics.removeAllEvents();
      this.$ics.addEvent(
        navigator.language === "zz-ZZ" ? "en-AU" : navigator.language,
        this.item.name,
        this.item.description,
        this.item.venues ? this.item.venues.address_name : "",
        this.item.start_at,
        this.item.end_at,
        this.eventPublicURL,
        {},
        "",
        ""
      );
      this.$ics.download(this.item.name);
    },
    getMapLabel() {
      switch (this.item.event_type) {
        case "virtual":
          return "Virtual Location";
          break;
        case "physical":
          return "Venue Location";
          break;
        case "hybrid":
          return "Virtual and Venue Location";
          break;
        default:
          return "Where to find us?";
      }
    },
    getAgendaLabel(val) {
      const tabDate = val.date ?? val.start_at;
      return " ● " + moment(tabDate).format("ddd, D MMM @ HH:mm");
    },
    async copyURL() {
      try {
        await navigator.clipboard.writeText(this.eventPublicURL);
        swal.fire({
          title: "Copied - Public Event Link",
          html:
            "You can use this link to share it with a wide group of people, post it on your Social Media or Spread the Word<br></hr> " +
            "<br><b>Public Event Link:</b><br> <button type='button' class='btn-icon-clipboard text-center'>" +
            this.eventPublicURL +
            "</button> <br></hr>",
          type: "success",
          confirmButtonClass: "btn btn-outline-danger btn-fill",
          confirmButtonText: " Close",
          buttonsStyling: false,
          icon: "success",
        });
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    hasSpecialRequirements() {
      return true;
    },
    hasDietaryRequirements() {
      // return this.item?.category?.name === "Food & Drinks";
      return this.hasSpecialRequirements();
    },
    async displayLatestAnnouncement() {
      let params = {
        // ...{ sort: "-created_at" },
        filter: {
          ...{ item_id: this.item.id },
        },
      };
      await this.$store.dispatch("announcements/list", params).then(() => {
        const announcements = this.$store.getters["announcements/list"];
        if (announcements?.length > 0) {
          this.announcements = announcements[0];
        } else {
          this.announcements.message = "";
        }
      });
    },
    displayAnnouncementTime(date) {
      return moment.utc(date).fromNow();
    },
  },
};
</script>

<style lang="scss">

#bmis-eventalk-layout {
  padding-right:0 !important;
  padding-left:0 !important;
  margin-right:auto !important;
  margin-left:auto !important;
}

#mantra-banner {
  width: 60vw !important;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

#event-theme-tabs .el-tabs__header .el-tabs__nav-wrap {
  padding: 0.9em 2em 0.9em 2em;
  margin-bottom: 20px;
  box-shadow: 0 0 0 0 !important;
  border: 1px solid #f5f6f8;
  border-radius: calc(0.75rem - 1px);
}

#event-theme-tabs .el-tabs__nav-prev {
  color: #5e72e4 !important;
  font-size: 2em;
  font-style: oblique;
  font-weight: bold;
}

#event-theme-tabs .el-tabs__nav-next {
  color: #5e72e4 !important;
  font-size: 2em;
  font-style: oblique;
  font-weight: bold;
}

</style>
