<template>
  <div class="card">
    <div class="card-header">
      <h1>Edit Profile</h1>
    </div>
    <div class="card-body">
      <label class="form-control-label">Profile Picture</label>

      <div
        v-if="!actions.uploadProfileImg"
        class="col-md-7 offset-md-3 card-img mb-2"
      >
        <template>
          <img
            class="profile-image"
            :src="profileImage"
            v-if="profileImage !== null"
            alt="User Image"
          />

          <el-skeleton v-else animated>
            <template slot="template">
              <div style="padding: 14px;">
                <el-skeleton-item
                  variant="image"
                  style="width: 280px; height: 240px; text-left:15px"
                  class="mb-1"
                />
              </div>
            </template>
          </el-skeleton>
        </template>

        <div class="card-img-overlay d-flex align-items-center">
          <div>
            <base-button
              type="primary"
              @click="actions.uploadProfileImg = true"
            >
              <i class="fa fa-upload mr-2" />Upload new Image</base-button
            >
          </div>
        </div>
      </div>

      <template>
        <base-file-input
          help-message="Upload a new profile image :-)"
          :endpoint="uploadUrl('profile')"
          preview-height="100"
          v-show="actions.uploadProfileImg"
          v-on:newImgUrl="setNewProfileImg"
        />
      </template>
      <base-input
          label="Current Role"
          placeholder="Your name"
          v-model="user.roles[0].name"
          name="Role"
          disabled
      />
      <hr />
      <form ref="profile_form" @submit.prevent="handleProfileUpdate">
        <div class="form-group">
          <base-input
            label="Full Name"
            prepend-icon="fas fa-user"
            placeholder="Your name"
            v-model="user.name"
            name="Name"
            required
          />
          <base-input
            label="Email"
            prepend-icon="fas fa-envelope"
            placeholder="Email"
            type="email"
            name="Email"
            v-model="user.email"
            required
          />
          <validation-error :errors="apiValidationErrors.email" />
          <div class="my-4">
            <base-button
              type="button"
              class="btn btn-sm btn-primary"
              native-type="submit"
            >
              Update Profile
            </base-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Skeleton, SkeletonItem } from "element-ui";

export default {
  name: "UserEditCard",

  components: {
    [Skeleton.name]: Skeleton,
    [SkeletonItem.name]: SkeletonItem,
    BaseInput,
    BaseButton,
    ValidationError,
  },

  mixins: [formMixin],

  props: {
    user: Object,
  },

  data() {
    return {
      image: null,
      file: null,
      actions: {
        uploadProfileImg: false,
      },
    };
  },
  computed: {
    profileImage() {
      if (!_.isEmpty(this.user.profile_image)) {
        if (
          this.user.profile_image.includes("http://") ||
          this.user.profile_image.includes("https://")
        ) {
          return this.user.profile_image;
        }
        return process.env.VUE_APP_AWS_S3_URL + this.user.profile_image;
      }

      return null;
    },
  },
  methods: {
    removeImage() {
      this.image = null;
    },

    async handleProfileUpdate() {
      // const tempUser = JSON.parse(JSON.stringify(this.user))
      // delete tempUser.uuid;
      const dataToUpdate = {name: this.user.name, email: this.user.email, id: this.user.id, type: this.user.type}
      try {
        // 1 - Fire the request
        // await this.$store.dispatch("profile/update", {
        //   name: this.name,
        // });

        await this.$store.dispatch("profile/update", dataToUpdate);
        // 2 - Retrieve the response
        //  this.user = await this.$store.getters["profile/me"];

        this.removeImage();
        this.resetApiValidation();
        this.$notify({
          type: "success",
          message: "Profile updated successfully.",
        });

      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    uploadUrl(resource) {
      return (
        process.env.VUE_APP_API_BASE_URL +
        "/uploads/users/" +
        this.user.id +
        "/" +
        resource
      );
    },
    async setNewProfileImg(url){
      this.user.profile_image = url
      const evt = new CustomEvent("NewProfileImage", { detail: url });
      document.dispatchEvent(evt);
    }
  },
};
</script>
