<template>
  <div class="card" :class="styling">
    <div class="card-body px-lg-5 py-lg-5 mb-5 mt-auto">
      <div class="text-center text-muted mb-4">
        <h3>Sign Up</h3>
      </div>

      <form ref="user_register_form" role="form" @submit.prevent="handleSubmit()" >
        <base-input
          class="mb-3"
          prepend-icon="ni ni-hat-3"
          placeholder="Name"
          name="Name"
          type="text"
          v-model="name"
          required
        >
        </base-input>
        <validation-error :errors="apiValidationErrors?.name" />

        <base-input
          class="mb-3"
          prepend-icon="ni ni-email-83"
          placeholder="Email"
          name="Email"
          type="email"
          v-model="email"
          required
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.email" />

        <base-input
          class="mb-3"
          prepend-icon="ni ni-lock-circle-open"
          placeholder="Password"
          type="password"
          name="Password"
          v-model="password"
          rules="min:8"
          required
        >
        </base-input>
        <password
          class="mb-3"
          v-model="password"
          :strength-meter-only="true"
          @score="showScore"
          :showStrengthMeter="false"
        />
        <validation-error :errors="apiValidationErrors.password" />

        <base-input
          class="mb-3"
          prepend-icon="ni ni-lock-circle-open"
          placeholder="Confirm Password"
          type="password"
          name="Password confirmation"
          v-model="password_confirmation"
          rules="min:8"
          required
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.password_confirmation" />

        <div
          v-if="passwordsMismatch()"
          class="invalid-feedback"
          style="display: block; margin-top: -1.3rem; margin-bottom: 1.5rem"
        >
          The Password confirmation field doesn't match The Password field
        </div>

        <div class="text-muted font-italic form-group">
          <small>
            Password:
            <template v-if="scors === 0">
              <span class="text-danger font-weight-700"> Very Weak </span>
            </template>

            <template v-if="scors === 1">
              <span class="text-danger font-weight-700"> Weak </span>
            </template>

            <template v-if="scors === 2">
              <span class="text-warning font-weight-700"> Medium </span>
            </template>

            <template v-if="scors === 3">
              <span class="text-success font-weight-700"> Strong </span>
            </template>

            <template v-if="scors === 4">
              <span class="text-success font-weight-700"> Very Strong </span>
            </template>
          </small>
        </div>

        <!--                <base-input-->
        <!--                  class="mb-3"-->
        <!--                  placeholder="Invitation Code"-->
        <!--                  name="Invitation Code"-->
        <!--                  v-model="invitation_code"-->
        <!--                  prepend-icon="el-icon-s-claim"-->
        <!--                  :disabled="isInvitationCodeValid()"-->
        <!--                >-->
        <!--                </base-input>-->
        <!--                <validation-error-->
        <!--                  :errors="apiValidationErrors.invitation_code"-->
        <!--                />-->

        <!-- Select user profile-->
        <!--                <div class="row">-->
        <!--                  <div class="col-md-12">-->
        <!--                    <base-input>-->
        <!--                      <el-select-->
        <!--                        class="select-primary"-->
        <!--                        placeholder="Select Profile"-->
        <!--                        v-model="selects.role"-->
        <!--                        required-->
        <!--                      >-->
        <!--                        <el-option-->
        <!--                          v-for="option in selects.roles"-->
        <!--                          class="select-danger"-->
        <!--                          :value="option.value"-->
        <!--                          :label="option.label"-->
        <!--                          :key="option.label"-->
        <!--                        >-->
        <!--                        </el-option>-->
        <!--                      </el-select>-->
        <!--                    </base-input>-->
        <!--                  </div>-->
        <!--                </div>-->

        <hr />

        <div class="row my-4">
          <div class="col-12 text-center text-wrap">
            <base-alert type="secondary" v-if="checkbox">
              <base-checkbox v-model="boolean">
                <small id="user-registration" class="text-muted text-center">
                  I agree with the
                  <a
                    href="https://www.bookmeinscotty.com/terms-and-conditions/"
                    target="_blank"
                    >Terms & Conditions</a
                  >
                  and
                  <a
                    href="https://www.bookmeinscotty.com/terms-of-use/"
                    target="_blank"
                    >Terms of Use</a
                  >
                  and I want to proceed with my registration.
                </small>
              </base-checkbox>
            </base-alert>
          </div>
        </div>

        <vue-recaptcha
          sitekey="6LfDZ5AlAAAAAAQcfEJ4FrQfkuUO2Lu2V8ziQoyr"
          ref="recaptcha"
          @verify="showTC()"
          v-show="!checkbox"
        >
          <base-button
            size="lg"
            outline
            type="primary"
            class="calendar-action-button"
            block="true"
            data-testid="base-recaptcha-btn"
            ><i class="fa fa-robot" />
            I'm a Human
          </base-button>
        </vue-recaptcha>

        <base-button
          v-if="checkbox"
          size="lg"
          block="true"
          type="primary"
          native-type="submit"
          class="my-4"
          :disabled="formInValid()"
        >
          Create account
        </base-button>

        <div class="text-center">
          <slot name="loading">
            <i v-if="loading" class="fas fa-spinner fa-spin"></i>
          </slot>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
// components
import Password from "vue-password-strength-meter";
import { Select, Option } from "element-ui";

// packages
import { VueRecaptcha } from "vue-recaptcha";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";

export default {
  name: "base-register",
  props: {
    onboarding: {
      type: Boolean,
      default: false,
    },
    styling: {
      type: String,
      default: 'bg-secondary border-0',
    },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Password,
    VueRecaptcha,
    ValidationError,
  },
  mixins: [formMixin],
  data() {
    return {
      name: null,
      boolean: false,
      email: null,
      password: null,
      password_confirmation: null,
      invitation_code: null,
      checkbox: false,
      loading: false,
      scors: "",
      selects: {
        role: "creator",
        roles: [
          {
            value: "creator",
            label: "Creator",
          },
        ],
      },
    };
  },

  methods: {
    showScore(score) {
      this.scors = score;
    },
    formInValid() {
      return (
        !this.password ||
        !this.password_confirmation ||
        this.passwordsMismatch() ||
        this.password.length < 8 ||
        this.password_confirmation < 8
      );
    },
    passwordsMismatch() {
      return (
        this.password &&
        this.password_confirmation &&
        this.password.length > 7 &&
        this.password_confirmation.length > 7 &&
        this.password !== this.password_confirmation
      );
    },
    async handleSubmit() {
      if (!this.boolean) {
        await this.$notify({
          type: "danger",
          message: "You need to agree with our terms and conditions.",
        });
        return;
      }

      this.loading = true;

      const user = {
        data: {
          type: "users",
          attributes: {
            name: this.name,
            email: this.email,
            role: this.selects.role,
            password: this.password,
            password_confirmation: this.password_confirmation,
            invitation_code: this.invitation_code,
          },
        },
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };
      this.loading = true;

      try {
        await this.$store
          .dispatch("register", {
            user,
            requestOptions,
            preventRegisterRedirection: this.onboarding,
          })
          .then((response) => {
            if (
              (response?.status && response.status != 200) ||
              (response.errors && response.errors[0].status != 201)
            ) {
              //dispatch notification

              this.$notify({
                type: "danger",
                message:
                  "Oops, something went wrong, this email is already registred in our platform!",
              });
              this.setApiValidation(response.errors);
            } else {
              // Trigger notification
              this.$notify({
                type: "success",
                message: "Successfully registered",
              });
              if (this.onboarding) {
                this.$emit("registered", response?.data);
              }
            }
            this.loading = false;
          });
      } catch (error) {
        this.loading = false;

        this.setApiValidation(error?.response?.data?.errors);
      }
    },
    showTC() {
      this.checkbox = true;
    },
    isInvitationCodeValid() {
      const codes = ["bmis2023", "bmisearlyadopters", "agentsagency"];

      return codes.find((code) => code === this.invitation_code);
    },
  },
};
</script>
<style></style>
