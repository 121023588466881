import service from "@/store/services/tasks-service";

const state = {
  list: {},
  task: {},
  meta: {}
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, task) => {
    state.task = task;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params)
        .then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, {id, params}) {
    return service.get(id, params)
        .then((task) => {
          commit("SET_RESOURCE", task);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params)
        .then((task) => {
          commit("SET_RESOURCE", task);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then(task => {
      commit("SET_RESOURCE", task);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  }
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  task: state => state.task,
  dropdown: state => {
    return state.list.map(task => ({
      id: task.id,
      name: task.name
    }));
  }
};

const tasks = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default tasks;
