<template>
  <router-view></router-view>
</template>

<script>
export default {
  metaInfo() {
    return {
      title:
        "Book Me In Scotty, organise and manage the best events in no-time with our intuitive event resource platform",
      meta: [
        {
          name: "charset",
          content: "utf-8",
        },
        {
          name: "description",
          content:
            "Book Me In Scotty allows users to organize, manage and promote events. Our services are free-of-charges for RSVP ticketing services and resource management",
        },
        {
          name: "keywords",
          content:
            "book me in scotty, bmis, events, event management, event management platform, event management application, event management system, event resource management system, event ticketing system,  business events, networking events, private events, event planner, event organizer",
        },
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1",
        },
        {
          name: "robots",
          content: "index, follow",
        },
      ],
      link: [
        {
          rel: "icon",
          type: "image/png",
          href: "/favicon.png"
        },
        {
          rel: "stylesheet",
          href: "https://fonts.googleapis.com/css?family=Noto+Sans:300,400,600,700",
        },
        {
          rel: "stylesheet",
          href: "https://use.fontawesome.com/releases/v5.6.3/css/all.css",
          integrity: "sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/",
          crossorigin: "anonymous",
        },
      ],
      script: (function () {})(),
    };
  },
  created(){
    this.setGoogleAnalytics()
  }
};
</script>

<style lang="scss">
#sentry-feedback #shadow-root.widget__actor {
  z-index: 1000 !important;
}
</style>