import service from '@/store/services/sponsors-service';

const state = {
  list: {},
  sponsor: {},
  included: [],
  meta: {},
  url: null,
  address: null
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, sponsor) => {
    state.sponsor = sponsor;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  SET_ADDRESS: (state, address) => {
    state.address = address;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, {id, params}) {
    return service.get(id, params)
      .then((sponsor) => {
        commit('SET_RESOURCE', sponsor);
      });
  },

  getPublic({commit, dispatch}, {id, params}) {
    return service.getPublic(id, params)
      .then((sponsor) => {
        commit('SET_RESOURCE', sponsor);
      });
  },

  getAddress({commit, dispatch}, {center, access_token}) {
    return service.getAddress(center, access_token)
      .then((address) => {
        commit('SET_ADDRESS', address);
      });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((sponsor) => {
        commit('SET_RESOURCE', sponsor);
      });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((sponsor) => {
        commit('SET_RESOURCE', sponsor);
      });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },

  upload({commit, dispatch}, {sponsor, image}) {
    return service.upload(sponsor, image)
      .then((url) => {
        commit('SET_URL', url);
      });
  }
};

const getters = {
  list: state       => state.list,
  listTotal: state  => state.meta.page.total,
  sponsor: state       => state.sponsor,
  url: state        => state.url,
  address: state => state.address,
  dropdown: state => {
    return state.list.map(sponsor => ({
      id: sponsor.id.toString(),
      name: sponsor.name.toString(),
      label: sponsor?. label,
      summary: sponsor?.summary,
      logo: sponsor?.logo
    }));
  }
};

const sponsors = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default sponsors;