import DashboardLayout from "@/views/App/DashboardLayout.vue";

import auth from "@/middleware/auth";

// Components pages
const ListPayoutsPage = () =>
    import(/* webpackChunkName: "components" */ "@/views/Reports/ListPayouts.vue");


let reportsNav = {
    path: "/manage",
    component: DashboardLayout,
    name: "Reports",
    children: [
        {
            path: "reports/list-payouts",
            name: "Payouts Management",
            components: { default: ListPayoutsPage },
            meta: { middleware: auth, roles:['admin','developer','moderator'] }
        }
    ]
};

export default reportsNav;
