import DashboardLayout from "@/views/App/DashboardLayout.vue";

// Components pages
const ListTagPage = () =>
    import(/* webpackChunkName: "components" */ "@/views/Tags/Management/ListTagItems.vue");
const AddTagPage = () =>
    import(/* webpackChunkName: "components" */ "@/views/Tags/Management/AddTagItem.vue");
const EditTagPage = () =>
    import(/* webpackChunkName: "components" */ "@/views/Tags/Management/EditTagItem.vue");

import auth from "@/middleware/auth";

let tagsNav = {
    path: "/manage",
    component: DashboardLayout,
    name: "Tags",
    children: [
        {
            path: "tags/list-tags",
            name: "List Tags",
            components: { default: ListTagPage },
            meta: { middleware: auth }
        },
        {
            path: "tags/add-tag",
            name: "Add Tag",
            components: { default: AddTagPage },
            meta: { middleware: auth }
        },
        {
            path: "tags/edit-tag/:id",
            name: "Edit Tag",
            components: { default: EditTagPage },
            meta: { middleware: auth }
        },
    ]
}

export default tagsNav;
