<template>
  <div
    :class="[inlineClass, { disabled: disabled }]">
    <el-radio
        :id="cbId"
        :label="name"
        :disabled="disabled"
        :size="size"
        :border="true"
        :data-testid="name"
        v-model="model"
    >
        <slot></slot>
    </el-radio>
  </div>
</template>
<script>
import {
  Radio,
  RadioGroup,
} from "element-ui";

  export default {
    name: 'base-radio',
    components: {
      [Radio.name]: Radio,
      [RadioGroup.name]: RadioGroup,
    },
    props: {
      name: {
        type: [String, Number, Boolean],
        description: 'Radio label'
      },
      value: {
        type: [String],
        description: 'Radio value'
      },
      disabled: {
        type: Boolean,
        description: 'Whether radio is disabled'
      },
      border: {
        type: Boolean,
        description: 'whether to add a border around Radio'
      },
      size: {
        type: String,
        default: 'medium',
        description: 'Set the radio size to medium / small / mini'
      },
      inline: {
        type: Boolean,
        description: 'Whether radio are grouped is inline'
      },
    },
    data() {
      return {
        cbId: ''
      };
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      inlineClass() {
        if (this.inline) {
          return `form-check-inline`;
        }
        return '';
      },
    },
    created() {
      this.cbId = Math.random()
        .toString(16)
        .slice(2);
    }
  };
</script>

<style lang="scss">
.el-radio__label {
  text-transform: capitalize;
}

.el-radio__input.is-checked .el-radio__inner {
  background: #5e72e4;
  border-color: #5e72e4;
}

.el-radio__input.is-checked + .el-radio__label {
  color: #5e72e4;
}

.el-radio--medium.is-bordered {
  border-radius: .75rem;
}

.el-radio.is-bordered.is-checked {
  border-radius: .75rem;
  border-color: #ffffff;
  background: rgba(94, 114, 228, 0.28);
}
</style>
