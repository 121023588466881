const PublicLayout = () =>
    import (/* webpackChunkName: "pages" */ "@/views/Pages/Layout/PublicLayout.vue");

const PublicEventPage = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Events/Pages/PublicEventPage.vue");

const GuestAddEventItem = () =>
    import(/* webpackChunkName: "pages" */"@/views/Pages/Guest/PublicAddEventItem.vue");

 const PublicSpeakerPage = () =>
    import(/* webpackChunkName: "speakers" */ "@/views/Speakers/PublicSpeakerPage.vue");

 const PublicSponsorPage = () =>
    import (/* webpackChunckName: "sponsors" */ "@/views/Sponsors/PublicSponsorPage.vue");   
// Without Authentication
let publicItemNav = {
    path: "/",
    component: PublicLayout,
    name: "Public",
    children: [
        {
            path: "/event/:id",
            name: "Public Event Page",
            component: PublicEventPage
        },
        {
            path: "/add-item",
            name: "Public Add Event Item",
            component: GuestAddEventItem
        },
        {
            path: "/speakers/:id",
            name: "Public Event Page",
            component: PublicSpeakerPage
        },
        {
            path: "/sponsor/:id",
            name: "Public Event Page",
            component: PublicSponsorPage
        }
    ]
}

export default publicItemNav;