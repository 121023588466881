import DashboardLayout from "@/views/App/DashboardLayout.vue";

// Dashboard pages
const MainDashboard = () =>
    import(/* webpackChunkName: "dashboard" */ "@/views/Overview/MainDashboard.vue");
// Calendar
const Calendar = () =>
    import(/* webpackChunkName: "extra" */ "@/views/Calendar/Management/Calendar.vue");
// Charts
const Charts = () =>
    import(/* webpackChunkName: "dashboard" */ "@/views/Charts.vue");
// Widgets
const Widgets = () =>
    import(/* webpackChunkName: "dashboard" */ "@/views/Widgets.vue");
// Alt Dashboard
const AlternativeDashboard = () =>
    import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard/AlternativeDashboard.vue");

    // Collections
const Collections = () =>
import(/* webpackChunkName: "events" */ "@/views/Events/Pages/CollectionsPage.vue");

// Stripe Coupons
const StripeCoupons = () =>
    import(/* webpackChunkName: "stripe" */ "@/views/StripeCoupons/GenerateStripeCoupons.vue");
import auth from "@/middleware/auth";

let overviewNav = {
    path: "/",
    component: DashboardLayout,
    name: "Dashboard layout",
    children: [
        {
            path: "manage/dashboard",
            name: "Main Dashboard",
            component: MainDashboard,
            meta: { middleware: auth }
        },
        {
            path: "manage/calendar",
            name: "Calendar",
            component: Calendar,
            meta: { middleware: auth }
        },
        {
            path: "manage/collections",
            name: "Collection",
            component: Collections,
            meta: { middleware: auth }
        },
        {
            path: "charts",
            name: "Charts",
            component: Charts
        },
        {
            path: "widgets",
            name: "Widgets",
            component: Widgets
        },
        {
            path: "alternative",
            name: "Alternative",
            component: AlternativeDashboard,
            meta: {
                navbarType: "light"
            }
        },
        {
            path: "generate-stripe-coupons",
            name: "Generate Stripe Coupons",
            component: StripeCoupons
        }
    ]
};

export default overviewNav;
