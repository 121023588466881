import axios from "axios";
import Jsona from "jsona";

const url       = process.env.VUE_APP_API_BASE_URL;
const assetsUrl = process.env.VUE_APP_AWS_S3_URL;

const jsona = new Jsona();

function get() {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.get(`${url}/me?include=roles`, options).then(response => {
    let res = response.data;
    if(!_.isEmpty(res.data.attributes)){
      response.data.data.attributes.profile_image = assetsUrl.concat('',res.data.attributes.profile_image);
    }

    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta
    };
  });
}

function update(profile) {
  const payload = jsona.serialize({
    stuff: profile,
    includeNames: []
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .patch(`${url}/me?include=roles`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

export default {
  get,
  update
};
