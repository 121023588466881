var render = function render(){var _vm=this,_c=_vm._self._c;return _c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"opened":"","link":{
        name: 'Overview',
        icon: 'fas fa-chart-bar text-primary',
      }}},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          path: '/manage/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Calendar',
          path: '/manage/calendar',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Collection',
          path: '/manage/collections',
        }}})],1),_c('sidebar-item',{attrs:{"link":{
        name: 'Events',
        icon: 'ni ni-calendar-grid-58 text-primary',
      }}},[_c('sidebar-item',{attrs:{"link":{
          name: 'Event Management',
          path: '/manage/events/list-items',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Tag Management',
          path: '/manage/tags/list-tags',
        }}}),(_vm.isDeveloper())?_c('sidebar-item',{attrs:{"link":{
          name: 'Survey Management',
          path: '/manage/survey/list-surveys',
        }}}):_vm._e(),(_vm.isDeveloper())?_c('sidebar-item',{attrs:{"link":{
          name: 'Speaker Management',
          path: '/manage/speakers/list-speakers',
        }}}):_vm._e(),(_vm.isDeveloper())?_c('sidebar-item',{attrs:{"link":{
          name: 'Sponsor Management',
          path: '/manage/sponsors/list-sponsors',
        }}}):_vm._e()],1),_c('sidebar-item',{attrs:{"true":"","link":{
        name: 'Attendees',
        icon: 'fas fa-users text-primary',
      }}},[_c('sidebar-item',{attrs:{"link":{
          name: 'Guest Management',
          path: '/manage/attendees/list-attendees',
        }}}),(_vm.isDeveloper())?_c('sidebar-item',{attrs:{"link":{
          name: 'Ticket Management',
          path: '/manage/attendees/list-tickets',
        }}}):_vm._e()],1),_c('sidebar-item',{attrs:{"true":"","link":{
        name: 'Bookings',
        icon: 'ni ni-money-coins text-primary',
      }}},[_c('sidebar-item',{attrs:{"link":{
          name: 'Ticket Management',
          path: '/manage/tickets/list-tickets',
        }}})],1),(
        _vm.roles.includes('admin') ||
        _vm.roles.includes('developer') ||
        _vm.roles.includes('moderator')
      )?_c('sidebar-item',{attrs:{"link":{
        name: 'Reports',
        icon: 'fa fa-coins text-primary',
      }}},[_c('sidebar-item',{attrs:{"link":{
          name: 'Payout Management',
          path: '/manage/reports/list-payouts',
        }}})],1):_vm._e(),_c('sidebar-item',{attrs:{"true":"","link":{
        name: 'Locations',
        icon: 'ni ni-square-pin text-primary',
      }}},[_c('sidebar-item',{attrs:{"link":{
          name: 'Venue Management',
          path: '/manage/venues/list-venues',
          // path: '/examples/user-management/list-users',
        }}})],1),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.roles.includes('admin') ||
        _vm.roles.includes('developer') ||
        _vm.roles.includes('moderator')
      ),expression:"\n        roles.includes('admin') ||\n        roles.includes('developer') ||\n        roles.includes('moderator')\n      "}],attrs:{"link":{
        name: 'Console',
        icon: 'ni ni-badge text-danger',
      }}},[_c('sidebar-item',{attrs:{"link":{
          name: 'User Management',
          path: '/console/users/list-users',
        }}}),(
          _vm.roles.includes('admin') ||
          _vm.roles.includes('developer') ||
          _vm.roles.includes('moderator')
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Category Management',
          path: '/console/categories/list-categories',
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Roles Management',
          path: '/console/roles/list-roles',
        }}})],1),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.roles.includes('developer')),expression:"roles.includes('developer')"}],attrs:{"link":{
        name: 'Development Tools',
        icon: 'fab fa-vuejs',
      }}},[_c('sidebar-item',{staticClass:"btn btn-sm btn-danger mr-4 ml-3 pl-0 mt-2",attrs:{"link":{
          name: 'Documentation',
          target: '_blank',
          isRoute: true,
          path: 'https://vue-argon-dashboard-pro-laravel.creative-tim.com/documentation/',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Examples',
          icon: 'ni ni-single-copy-04 text-pink',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Main Dashboard',
            path: '/manage/dashboard',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Alternative Dashboard',
            path: '/alternative',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Pages',
          icon: 'ni ni-ungroup text-danger',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Pricing', path: '/pricing' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Lock', path: '/lock' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Timeline', path: '/pages/timeline' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Profile', path: '/pages/user' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Components',
          icon: 'ni ni-ui-04 text-info',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Buttons', path: '/components/buttons' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Cards', path: '/components/cards' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Grid', path: '/components/grid-system' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Notifications', path: '/components/notifications' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Icons', path: '/components/icons' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Typography', path: '/components/typography' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Multi Level' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Third level menu', path: '#!' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Just another link', path: '#a' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'One last link', path: '#b' }}})],1)],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Forms',
          icon: 'ni ni-single-copy-04 text-pink',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Elements', path: '/forms/elements' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Components', path: '/forms/components' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Validation', path: '/forms/validation' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Tables',
          icon: 'ni ni-align-left-2 text-default',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Tables', path: '/tables/regular' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Sortable', path: '/tables/sortable' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Paginated Tables', path: '/tables/paginated' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Maps',
          icon: 'ni ni-map-big text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Google', path: '/maps/google' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Vector', path: '/maps/vector' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Widgets',
          icon: 'ni ni-archive-2 text-green',
          path: '/widgets',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Charts',
          icon: 'ni ni-chart-pie-35 text-info',
          path: '/charts',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Calendar',
          icon: 'ni ni-calendar-grid-58 text-red',
          path: '/calendar',
        }}}),(_vm.isDeveloper())?_c('sidebar-item',{attrs:{"link":{
          name: 'Generate Stripe Coupons',
          icon: 'ni ni-archive-2 text-green',
          path: '/generate-stripe-coupons',
        }}}):_vm._e()],1),_c('hr',{staticClass:"my-3",staticStyle:{"border-color":"rgba(0, 0, 0, 0.1) currentcolor currentcolor","border-style":"solid none none","border-width":"1px 0px 0px","border-image":"none 100% / 1 / 0 stretch","min-width":"80%","overflow":"visible","box-sizing":"content-box","height":"0px"}}),_c('sidebar-item',{staticClass:"btn btn-danger bg-gradient-danger",attrs:{"id":"cta-create-event","link":{
        name: 'Create Event',
        icon: 'ni ni-fat-add mr-4',
        target: '_self',
        isRoute: true,
        path: '/manage/events/add-item',
      },"data-testid":"cta-create-event"}}),_c('hr',{staticClass:"my-3",staticStyle:{"border-color":"rgba(0, 0, 0, 0.1) currentcolor currentcolor","border-style":"solid none none","border-width":"1px 0px 0px","border-image":"none 100% / 1 / 0 stretch","min-width":"80%","overflow":"visible","box-sizing":"content-box","height":"0px"}}),_c('user-promo-plan',{staticClass:"ml-3 mr-3 align-content-center",attrs:{"role":_vm.roles[0]}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }