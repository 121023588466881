<template>
  <div id="base-video-row" class="row" v-cloak>
    <div id="base-youtube-player" class="col-md-12 mt-2 mb-2" v-if="ytVideo"></div>
  </div>
</template>

<script>
import BaseYoutubePlayer from 'youtube-player';

export default {
  name: "BaseVideoYouTubePlayer",
  props: {
    ytVideo: {
      type: String,
      description: 'Full YouTube video url'
    },
    height: {
      type: String,
      description: 'Full YouTube video url',
      default: '360'
    },
    width: {
      type: String,
      description: 'Full YouTube video url',
      default: '640'
    }
  },
  data() {
    return {
      player: null,
      done: false
    }
  },
  mounted() {
    // Initialize the YouTube Player based on IFrameAPI
      this.initYouTubePlayer();
  },
  methods: {
    initYouTubePlayer() {
      // Create a new YouTube Player instance
      this.player = BaseYoutubePlayer('base-youtube-player', {
        height: this.height,
        width: this.width,
        videoId: this.getVideoIdFromUrl(this.ytVideo),
        playerVars: {
          'playsinline': 1
        },
        // events: {
        // 'onReady': this.onPlayerReady(),
        //   'onStateChange': this.onPlayerStateChange()
        // }
      });

      // More options https://developers.google.com/youtube/iframe_api_reference
      this.player.pauseVideo().then(function () {
        console.info('Youtube video is paused');
      });
    },
    getVideoIdFromUrl(fullURL) {
      /************************************************************************
       * Types of supported Youtube - URLs
       * http://www.youtube.com/watch?v=0zM3nApSvMg&feature=feedrec_grec_index
       * http://www.youtube.com/user/IngridMichaelsonVEVO#p/a/u/1/QdK8U-VIH_o
       * http://www.youtube.com/v/0zM3nApSvMg?fs=1&amp;hl=en_US&amp;rel=0
       * http://www.youtube.com/watch?v=0zM3nApSvMg#t=0m10s
       * http://www.youtube.com/embed/0zM3nApSvMg?rel=0
       * http://www.youtube.com/watch?v=0zM3nApSvMg
       * http://youtu.be/0zM3nApSvMg
       **********************************************************************/
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

      if (!_.isNull(fullURL)) {
        const match = fullURL.match(regExp);

        return (match && match[7].length == 11) ? match[7] : false;
      }
    },
  },
};
</script>

<style lang="scss">
#base-video-row {
  padding: 15px !important;
}

#base-youtube-player {
  border-style: solid;
  border-color: rgb(94, 114, 228);
  border-width: 2px;
  border-radius: 25px;
  padding-right: 0;
  padding-left: 0;
}
</style>

<style scoped>
[v-cloak] {
  display:none;
}
</style>
