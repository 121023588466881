import DashboardLayout from "@/views/App/DashboardLayout.vue";

import auth from "@/middleware/auth";
import creator from "@/middleware/creator";

import UserProfile from "@/views/Users/Profile/UserProfilePage.vue";
import ListRolePage from "@/views/Users/Role/ListRolePage.vue";
import AddRolePage from "@/views/Users/Role/AddRolePage.vue";
import EditRolePage from "@/views/Users/Role/EditRolePage.vue";
import ListUserPage from "@/views/Users/Management/ListUserPage.vue";
import AddUserPage from "@/views/Users/Management/AddUserPage.vue";
import EditUserDetails from "@/views/Users/Management/EditUserPage.vue";

let consoleNav = {
    path: "/console",
    component: DashboardLayout,
    name: "Console",
    children: [
        {
            path: "user-profile",
            name: "User Profile",
            components: { default: UserProfile },
            meta: { middleware: auth }
        },
        {
            path: "roles/list-roles",
            name: "List Roles",
            components: { default: ListRolePage },
            meta: { middleware: auth }
        },
        {
            path: "roles/management/add-role",
            name: "Add Role",
            components: { default: AddRolePage },
            meta: { middleware: auth }
        },
        {
            path: "roles/management/edit-role/:id",
            name: "Edit Role",
            components: { default: EditRolePage },
            meta: { middleware: auth }
        },
        {
            path: "users/list-users",
            name: "List Users",
            components: { default: ListUserPage },
            meta: { middleware: auth }
        },
        {
            path: "users/management/add-user",
            name: "Add User",
            components: { default: AddUserPage },
            meta: { middleware: auth }
        },
        {
            path: "users/management/edit-user/:id",
            name: "Edit User",
            components: { default: EditUserDetails },
            meta: { middleware: auth }
        },
    ]
};


export default consoleNav;
