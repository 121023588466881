import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import VueMeta from 'vue-meta';
import store from "@/store";


Vue.use(VueRouter);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

function getUserRole() {
  let me = null;
  try {
      me = store.getters["profile/me"];
  } catch (error) {
      console.error(error);
  }
 return me?.roles[0]?.name ?? null;
}

// configure router
const router = new VueRouter({
  base:'/',
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware)
    return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({...context, next: nextMiddleware});
  };
}

router.beforeEach(async (to, from, next) => {

  if (to?.meta?.roles){
    if (!getUserRole()) await store.dispatch('profile/me');
    if (!to.meta.roles.includes(getUserRole())){
      localStorage.removeItem("vue-authenticate.vueauth_access_token");
      window.location = "/login";
    }
  }
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
    const context = {from, next, to, router};
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({...context, next: nextMiddleware});
  }

  return next();
});

export default router;
