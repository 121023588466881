import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/sponsors`, options).then(response => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta
    };
  });
}

function get(id, params) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    },
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/sponsors/${id}`, options).then(response => {
      return jsona.deserialize(response.data);
    });
}

function getPublic(id, params) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type' : 'application/vnd.api+json'
    },
    params: params,
    paramsSerializer: function(params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios.get(`${url}/public/sponsors/${id}`, options).then(response => {
      return jsona.deserialize(response.data);
    });
}

function add(sponsor) {
  const payload = jsona.serialize({
    stuff: sponsor,
    includeNames: []
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .post(`${url}/sponsors`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(sponsor) {
  delete sponsor.logo;

  const payload = jsona.serialize({
    stuff: sponsor,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .patch(`${url}/sponsors/${sponsor.id}`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.delete(`${url}/sponsors/${id}`, options);
}

function upload(sponsor, logo) {
  const bodyFormData = new FormData();
  bodyFormData.append("attachment", logo);

  return axios
    .post(`${url}/uploads/sponsors/${sponsor.id}/logo`, bodyFormData)
    .then(response => {
      return response.data.url;
    });
}

function getAddress(center, access_token) { 
  return axios
    .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${center[0]},${center[1]}.json?access_token=${access_token}`)
    .then(response => {return response.data});
}

export default {
  list,
  get,
  getPublic,
  add,
  update,
  destroy,
  upload,
  getAddress
};