import service from '@/store/services/vendors-service';
 
// Prepare Vuex state
const state = {
  list: {},
  vendor: {},
  meta: {},
  
};

// Init Vuex mutations
const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, vendor) => {
    state.vendor = vendor;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

// Define actions
const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params)
    .then(({list, meta }) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({ commit, dispatch }, { id, params }) {
    return service.get(id, params)
    .then((vendor) => { 
      commit('SET_RESOURCE', vendor); 
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then((vendor) => 
    { commit('SET_RESOURCE', vendor); 
  });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then((vendor) => 
    { commit('SET_RESOURCE', vendor); 
  });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  },
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  vendor: state => state.vendor,
};

const vendors = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default vendors;
