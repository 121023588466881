import service from '@/store/services/surveys-service';

const state = {
  list: {},
  survey: {},
  meta: {},
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, survey) => {
    state.survey = survey;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
  list({commit, dispatch}, params) {
    return service.list(params)
      .then(({list, meta}) => {
        commit('SET_LIST', list);
        commit('SET_META', meta);
      });
  },

  get({commit, dispatch}, {id, params}) {
    return service.get(id, params)
      .then((survey) => {
        commit('SET_RESOURCE', survey);
      });
  },

  add({commit, dispatch}, params) {
    return service.add(params)
      .then((survey) => {
        commit('SET_RESOURCE', survey);
      });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((survey) => {
        commit('SET_RESOURCE', survey);
      });
  },

  destroy({commit, dispatch}, params) {
    return service.destroy(params);
  },
  save({ commit, dispatch }, params) {
    return service.save(params)
  },
  send({ commit, dispatch }, params) {
    return service.send(params)
  },
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  survey: state => state.survey
};

const surveys = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default surveys;
