import service from "@/store/services/tickets-service";

const state = {
  list: {},
  ticket: {},
  meta: {}
};

const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, ticket) => {
    state.ticket = ticket;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  }
};

const actions = {
  list({ commit, dispatch }, params) {
    return service.list(params).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params).then(ticket => {
      commit("SET_RESOURCE", ticket);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params).then(ticket => {
      commit("SET_RESOURCE", ticket);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params).then(ticket => {
      commit("SET_RESOURCE", ticket);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params);
  }
};

const getters = {
  list: state => state.list,
  listTotal: state => state.meta.page.total,
  ticket: state => state.ticket,
  dropdown: state => {
    return state.list.map(ticket => ({
      id: ticket.id,
      name: ticket.name
    }));
  }
};

const tickets = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default tickets;
