import axios from "axios";
import Jsona from "jsona";

const url       = process.env.VUE_APP_API_BASE_URL;
const assetsUrl = process.env.VUE_APP_AWS_S3_URL;

const jsona = new Jsona();

function get() {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.get(`${url}/subscriptions/session`, options).then
  (response => {
    return response.data.data.subscription_payment_session
  });
}

function add(status) {
  const payload = status
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };
  return axios
    .post(`${url}/subscriptions/session`, payload, options)
    .then(response => {
      return response?.data?.data?.subscription_payment_session;
    });
}



export default {
  get,
  add
};
