<template>
  <!-- Subscription Plans -->
  <div id="user-subscriptions-plans" class="card">
    <div class="card-header">
      <h1>Subscription Plans</h1>
    </div>
    <div class="card-body">
      <!-- Subscription card -->
      <card v-if="isPremiumUser() || isProfessionalUser() || isDeveloper()">
        <!-- Card body -->
        <div class="row align-items-center">
          <div class="col">
            <p class="text-sm text-muted mb-0">
              <span class="text-success">● </span> Active Subscription
            </p>
            <h4 class="mb-0">Your role: {{ getUserRole() }}</h4>
          </div>
          <div class="col-auto">
            <div class="col-auto text-center">
              <a :href="getCustomerPortal()" target="_blank" rel="noopener">
                <button class="btn btn-sm btn-primary" size="sm">
                  <i class="fa fa-user" />
                  Manage my Subscription
                </button>
              </a>
            </div>
          </div>
          <hr/>
          <div class="col-md-10 mt-3">
            <small v-if="requiredFieldsMissing.length > 0" class="text-danger"
            >* Please fill-up all the mandatory Payments field(s):
              <span
                  v-for="missingField in requiredFieldsMissing"
                  :key="missingField"
              > ●  {{ missingField }}
              </span>
            </small>
          </div>
        </div>
      </card>



      <h3 class="text-center">Feature-rich plans with upfront pricing</h3>
      <p class="text-center text-muted">
        We offer a full suite of features and tools to help at every stage of
        your event process.
      </p>
      <base-input id="subscription-plan">
        <div class="row align-content-between">
          <base-radio
            name="one"
            class="custom-control custom-radio col-md-3 offset-1"
            v-model="subscriptionLength"
          >
            Pay monthly
          </base-radio>
          <base-radio
            name="three"
            class="custom-control custom-radio col-md-3 offset-1"
            v-model="subscriptionLength"
          >
            Pay quarterly
          </base-radio>
          <base-radio
            name="year"
            class="custom-control custom-radio col-md-3 offset-1"
            v-model="subscriptionLength"
          >
            Pay yearly
          </base-radio>
        </div>
      </base-input>
    </div>

    <!-- Subscription Plans -->
    <div class="mt-4 mb-5">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="pricing card-group flex-column flex-md-row mb-3">
            <!-- Freemium Pack -->
            <card
              header-classes="bg-transparent"
              body-classes="px-lg-4"
              class="card-pricing border-0 text-center mb-4"
            >
              <h4
                slot="header"
                class="text-uppercase ls-1 text-primary py-3 mb-0"
              >
                <h4>Freemium Plan</h4>
                <small class="text-muted text-capitalize"
                  >Best for Enthusiast Organizers</small
                >
              </h4>
              <div>
                <div class="display-2" v-if="subscriptionLength === 'year'">
                  <small class="text-uppercase text-small">AU$</small>
                  99.9
                </div>
                <div
                  class="display-2"
                  v-else-if="subscriptionLength === 'three'"
                >
                  <small class="text-uppercase text-small">AU$</small> 24.99
                </div>

                <div class="display-2" v-else>
                  <small class="text-uppercase text-small">AU$</small> 9.99
                </div>

                <p v-if="subscriptionLength === 'year'">per year*</p>
                <badge
                  v-if="subscriptionLength === 'year'"
                  rounded
                  type="base"
                  size="sm"
                  class="mb-2"
                  >Save 15% + 30-days Free</badge
                >

                <p v-if="subscriptionLength === 'three'">for 3 months*</p>
                <badge
                  v-if="subscriptionLength === 'three'"
                  rounded
                  type="base"
                  size="sm"
                  class="mb-2"
                >
                  Save 10% + 30-days Free
                </badge>
                <p v-if="subscriptionLength === 'one'">per month*</p>
                <badge
                  v-if="subscriptionLength === 'one'"
                  rounded
                  type="base"
                  size="sm"
                  class="mb-2"
                  >30-days Free</badge
                >
              </div>
              <small class="text-muted text-capitalize">Suitable for:</small>

              <ul class="list-unstyled my-4 pl--2">
                <li>
                  <div class="d-flex align-items-left">
                    <div>
                      <div
                        class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                      >
                        <i class="fas fa-check"></i>
                      </div>
                    </div>
                    <div>
                      <small class="pl-2">Weddings or Baby Showers</small>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="d-flex align-items-left">
                    <div>
                      <div
                        class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                      >
                        <i class="fas fa-check"></i>
                      </div>
                    </div>
                    <div>
                      <small class="pl-2">Gatherings or Birthdays</small>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="d-flex align-items-left">
                    <div>
                      <div
                        class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                      >
                        <i class="fas fa-check"></i>
                      </div>
                    </div>
                    <div>
                      <small class="pl-2"
                        >Private Parties or Celebrations</small
                      >
                    </div>
                  </div>
                </li>

                <li>
                  <div class="d-flex align-items-left">
                    <div>
                      <div
                        class="icon icon-xs icon-shape bg-gradient-primary shadow rounded-circle text-white"
                      >
                        <i class="fas fa-check"></i>
                      </div>
                    </div>
                    <div>
                      <small class="pl-2">Graduations or Launch Events</small>
                    </div>
                  </div>
                </li>
              </ul>
              <base-button
                type="btn btn-success"
                disabled
                class="mb-3"
                v-if="getUserRole() === 'creator-plus'"
                ><i class="fas fa-check"></i> Subscribed</base-button
              >
              <base-button
                v-if="
                  getUserRole() !== 'creator-plus' &&
                  getUserRole() !== 'organizer'
                "
                @click="getSessionId('freemium')"
                type="primary"
                class="mb-3"
                :disabled="loading"
              >
                Upgrade me
              </base-button>
              <a slot="footer">
                <div class="text-center">
                  <small> *You can cancel anytime</small>
                  <slot name="loading">
                    <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                  </slot>
                </div>
              </a>
            </card>

            <!-- Premium Pack -->
            <card
              gradient="primary"
              class="card-pricing zoom-in shadow-lg rounded border-0 text-center mb-4"
              header-classes="bg-transparent"
              body-classes="px-lg-4"
              footer-classes="bg-transparent"
            >
              <h4
                slot="header"
                class="text-uppercase ls-1 text-white py-3 mb-0"
              >
                <h4 class="text-white">Premium Plan</h4>
                <small class="text-white text-muted text-capitalize"
                  >Best for Professional Organizers</small
                >
              </h4>
              <div>
                <div
                  class="display-2 text-white"
                  v-if="subscriptionLength === 'year'"
                >
                  <small class="text-uppercase text-small">AU$</small> 399.99
                </div>
                <div
                  class="display-2 text-white"
                  v-else-if="subscriptionLength === 'three'"
                >
                  <small class="text-uppercase text-small">AU$</small> 99.99
                </div>
                <div class="display-2 text-white" v-else>
                  <small class="text-uppercase text-small">AU$</small> 39.99
                </div>
                <p class="text-white" v-if="subscriptionLength === 'year'">
                  per year*
                </p>
                <badge
                  v-if="subscriptionLength === 'year'"
                  rounded
                  type="base"
                  size="sm"
                  class="mb-2"
                  >Save 15% + 30-days Free</badge
                >
                <p class="text-white" v-if="subscriptionLength === 'three'">
                  for 3 months*
                </p>
                <badge
                  v-if="subscriptionLength === 'three'"
                  rounded
                  type="base"
                  size="sm"
                  class="mb-2"
                >
                  Save 10% + 30-days Free
                </badge>
                <p v-if="subscriptionLength === 'one'" class="text-white">
                  per month*
                </p>
                <badge
                  v-if="subscriptionLength === 'one'"
                  rounded
                  type="base"
                  size="sm"
                  class="mb-2"
                  >30-days Free</badge
                >
              </div>
              <small class="text-white text-muted text-capitalize"
                >Suitable for:
              </small>
              <ul class="list-unstyled my-4">
                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div
                        class="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted"
                      >
                        <i class="fas fa-check"></i>
                      </div>
                    </div>
                    <div>
                      <small class="text-white pl-2"
                        >Conferences or Small Summits</small
                      >
                    </div>
                  </div>
                </li>

                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div
                        class="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted"
                      >
                        <i class="fas fa-check"></i>
                      </div>
                    </div>
                    <div>
                      <small class="text-white pl-2"
                        >Galas or Fundraisers</small
                      >
                    </div>
                  </div>
                </li>

                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div
                        class="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted"
                      >
                        <i class="fas fa-check"></i>
                      </div>
                    </div>
                    <div>
                      <small class="text-white pl-2"
                        >Live Shows or Performances</small
                      >
                    </div>
                  </div>
                </li>

                <li>
                  <div class="d-flex align-items-center">
                    <div>
                      <div
                        class="icon icon-xs icon-shape bg-white shadow rounded-circle text-muted"
                      >
                        <i class="fas fa-check"></i>
                      </div>
                    </div>
                    <div>
                      <small class="text-white pl-2"
                        >Parties or Outdoor Events</small
                      >
                    </div>
                  </div>
                </li>
              </ul>

              <div class="text-center">
                <base-button
                  type="btn btn-success"
                  disabled
                  class="mb-3"
                  v-if="getUserRole() === 'organizer'"
                  ><i class="fas fa-check"></i> Subscribed</base-button
                >
                <base-button
                  v-if="
                    getUserRole() !== 'creator-plus' &&
                    getUserRole() !== 'organizer'
                  "
                  @click="getSessionId('premium')"
                  type="secondary"
                  class="mb-3"
                  :disabled="loading"
                >
                  Upgrade me
                </base-button>
              </div>

              <a slot="footer" class="text-white">
                <div class="text-center">
                  <small> *You can cancel anytime</small>
                  <slot name="loading">
                    <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                  </slot>
                </div>
              </a>
            </card>
          </div>

          <div class="text-center">
            <small class="text-muted">
              **The 30-days free trial includes the complete set of features
            </small>
            <a href="https://www.bookmeinscotty.com/pricing" target="_blank">
              <base-button type="primary" class="mt-2 ml-1">
                Features & Details
              </base-button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";

export default {
  name: "UserSubscriptionPlans",

  components: {},

  mixins: [formMixin],

  props: {
    user: Object,
    requiredFieldsMissing: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      session: null,
      userRole: null,
      subscriptionLength: "three",
    };
  },

  created() {
    if (this.$route.query.subscribed === "true") {
      this.$notify({
        type: "success",
        message: "Subscription created successfully.",
      });
    } else if (this.$route.query.subscribed === "false") {
      this.$notify({
        type: "danger",
        /**
         *
         */
        message:
          "Oops, your subscription was unsuccessful. Please try again or contact with administrator.",
      });
    }
  },

  methods: {
    async getSessionId(type) {
      this.loading = true;
      try {
        await this.$store.dispatch("subscription/add", {
          package: type,
          status: this.subscriptionLength,
        });

        this.session = this.$store.getters["subscription/getSession"];

        window.location.href = this.session.url;

        this.loading = false;
      } catch (error) {
        // Notification
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong with your account!",
        });
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.subscription-radio-input {
  .form-group {
    background-color: white;
    border-radius: 5px;
  }
}
.subscription-radio-group {
  align-items: center;
  flex-direction: column;
}
.subscription-radio-option {
  display: flex;
  min-width: 110px;
}
</style>
