<template>
  <div
    class="container-fluid"
    style="display: flex; align-items: center; flex-direction: column"
  >
    <!-- Quick Header-->
      <div class="col-md-12 mb-4">
        <a href="#" style="width: 100%; display: flex; justify-content: center">
          <img
              src="/img/brand/bmis-default-logo-base.png"
              style="max-width: 100%; max-height: 3rem"
          />
        </a>
      </div>

    <div class="col-md-6 mb--5">
      <card>
        <div class="card-header">
          <h3>Ticket's Details</h3>
        </div>
        <div class="card-body">
          <base-input label="Ticket number" v-model="ticket.ticket_number" disabled/>
        </div>
      </card>

      <card>
        <div class="card-header">
          <h3>Attendee's Details</h3>
        </div>
        <div class="card-body">
          <base-input label="Full Name" v-model="ticket.name" disabled />
          <base-input label="Email Address" v-model="ticket.email" disabled />
          <base-input label="Special Requirements" v-model="ticket.special_requirements" disabled />
        </div>
      </card>

      <card>
        <div class="card-header">
          <h3>Payment Details</h3>
        </div>
        <div class="card-body">
          <base-input label="Payment" v-model="ticket.is_paid === 1 ? 'Completed' : 'Pending'"
                      :class="[ticket.is_paid === 1 ? 'paid' : 'unpaid']" disabled />

          <base-input label="Paid at"
              v-model="ticket.paid_at"
              v-if="ticket.paid_at"
              disabled
          />

          <base-input label="Payment Status"
                      v-model="ticket.status"
                      v-if="ticket.is_paid === 0"
                      disabled />
          <hr />
          <base-input label="Purchased Quantity" v-model="ticket.quantity" disabled />
          <base-input label="Total Paid Amount ($)" v-model="ticket.price" disabled />
        </div>
      </card>
    </div>

    <!-- Footer-->
    <hr />
    <div class="row justify-content-between">
      <div class="col-md-4">
        <div class="copyright text-center">
          <small class="text-muted"> Powered by </small>
          <a href="http://www.bookmeinscotty.com">
            <img
                src="/img/brand/bmis-default-full-logo_italic_bck.png"
                style="max-width: 35%; max-height: 3rem;"
            />©
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <div class="copyright text-center">
          <small class="text-muted text-uppercase mr-4"> © 2022 | All Rights Reserved</small>
        </div>
      </div>

      <div class="col-md-3">
        <div class="copyright text-center">
          <small class="text-muted text-uppercase"> Made with <i class="fa fa-heart heart"/> by BMIS Team </small>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>
<script>
export default {
  name: "base-attendee-ticket",
  props: {
    ticket: {
      type: Object,
      default: null,
    },
    isForLoggedInuser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  watch: {
    ticket: {
      handler: "initiateTicket",
      immediate: false,
      deep: true,
    },
  },
  methods: {
    initiateTicket() {
      if (this.ticket) {
        this.ticket.status = "AT DOOR";
        this.ticket.paid = this.ticket.is_paid === -1 ? "No" : "Yes";
      }
    },
  },
  // created() {
  //   this.ticket.status = "AT DOOR";
  //   this.ticket.paid = this.ticket.is_paid === -1 ? "No" : "Yes";
  // },
};
</script>
<style></style>
