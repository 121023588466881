<template>
  <el-page-header @back="handleClick" :style="buttonStyle" :content="content"/>
</template>
<script>

import { PageHeader } from "element-ui";

export default {
  name: "back-button",
  components: {
    [PageHeader.name]: PageHeader
  },
  props: {
    buttonStyle: {
      type: String,
      default: "color: #5e72e4;"
    },
    content: {
      type: String,
      default: ""
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>
<style></style>
