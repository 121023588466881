import DashboardLayout from "@/views/App/DashboardLayout.vue";

// Components pages
const ListSpeakerPage = () =>
    import(/* webpackChunkName: "speakers" */ "@/views/Speakers/ListSpeakerItems.vue");
const AddSpeakerPage = () =>
    import(/* webpackChunkName: "speakers" */ "@/views/Speakers/AddSpeakerItem.vue");
const EditSpeakerPage = () =>
    import(/* webpackChunkName: "speakers" */ "@/views/Speakers/EditSpeakerItem.vue");
const PreviewSpeakerPage = () =>
    import(/* webpackChunkName: "speakers" */ "@/views/Speakers/PreviewSpeakerItem.vue");
import auth from "@/middleware/auth";

let speakersNav = {
    path: "/manage",
    component: DashboardLayout,
    name: "Speakers",
    children: [
        {
            path: "speakers/list-speakers",
            name: "List Speakers",
            components: { default: ListSpeakerPage },
            meta: { middleware: auth }
        },
        {
            path: "speakers/add-speaker",
            name: "Add Speaker",
            components: { default: AddSpeakerPage },
            meta: { middleware: auth }
        },
        {
            path: "speakers/edit-speaker/:id",
            name: "Edit Speaker",
            components: { default: EditSpeakerPage },
            meta: { middleware: auth }
        },
        ,
        {
            path: "speakers/:id",
            name: "Speaker Preview",
            components: { default: PreviewSpeakerPage },
        }
    ]
}

export default speakersNav;
