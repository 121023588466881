<template>
  <div class="wrapper">
    <notifications></notifications>
    <app-side-navigation :roles="roles"/>

    <div class="d-flex flex-column main-content min-vh-100" v-if="this.roles.length">

      <app-navbar :type="$route.meta.navbarType"/>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- SPA loads here -->
          <router-view/>

        </fade-transition>
      </div>

      <app-footer class="mt-auto" v-if="!$route.meta.hideFooter"/>

    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}
import Vuex from "vuex";
import { FadeTransition } from "vue2-transitions";

import AppNavbar from "@/views/App/AppNavbar.vue";
import AppSideNavigation from "@/views/App/AppSideNav.vue";
import AppFooter from "@/views/App/AppFooterNav.vue";

export default {
  components: {
    FadeTransition,
    AppNavbar,
    AppSideNavigation,
    AppFooter,
  },
  data() {
    return {
      roles: [],
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar(),
    this.$store.dispatch("profile/me");
  },
  computed: {
    ...Vuex.mapState({
      me: (state) => state.profile.me,
    }),
  },
  watch: {
    me: {
      handler: function (val) {
        this.roles = val.roles.map((r) => r.name);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
body.g-sidenav-hidden {
  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 8px;
    margin-right: 8px;
    padding: 11px 0px;
  }
}
body.g-sidenav-show {
  #purchase-button,
  #demo-button,
  #star-button,
  #docs-button {
    margin-left: 20px;
    margin-right: 20px;
    padding: 11px 0px;
  }
}
</style>
