import Vue from "vue";
import store from "@/store";

import moment from "moment-timezone";
import { upperFirst } from "lodash";

Vue.mixin({
    methods:{
        getLocalTime (originalTime, originalTimeZone) {
            try {
                const me = store.getters["profile/me"];
                const timezone = me.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

                const originalTime = moment.tz(
                    originalTime,
                    originalTimeZone
                );
                console.log(timezone, originalTime);

                return originalTime.clone().tz(timezone).format("LT");

            } catch (error) {
                console.error('Fetching user profile failed, please try to logout/login again -', error);
            }
        },
    }
});