import DashboardLayout from "@/views/App/DashboardLayout.vue";

import auth from "@/middleware/auth";

import ListCategoryPage from "@/views/Examples/CategoryManagement/ListCategoryPage";
import AddCategoryPage from "@/views/Examples/CategoryManagement/AddCategoryPage";
import EditCategoryPage from "@/views/Examples/CategoryManagement/EditCategoryPage";

let categoriesNav = {
    path: "/console",
    component: DashboardLayout,
    name: "Categories",
    children: [
        {
            path: "categories/list-categories",
            name: "List Categories",
            components: { default: ListCategoryPage },
            meta: { middleware: auth }
        },
        {
            path: "categories/add-category",
            name: "Add Category",
            components: { default: AddCategoryPage },
            meta: { middleware: auth }
        },
        {
            path: "categories/edit-category/:id",
            name: "Edit Category",
            components: { default: EditCategoryPage },
            meta: { middleware: auth }
        }
    ]
};

export default categoriesNav;
