import DashboardLayout from "@/views/App/DashboardLayout.vue";

import auth from "@/middleware/auth";

// Components pages
const ListVenuePage = () =>
    import(/* webpackChunkName: "components" */ "@/views/Venues/Management/ListVenues.vue");
// const AddVenuePage = () =>
//     import(/* webpackChunkName: "components" */ "@/views/Venue/Management/AddVenueItem.vue");
const EditVenuePage = () =>
    import(/* webpackChunkName: "components" */ "@/views/Venues/Management/EditVenueItem.vue");

let venuesNav = {
    path: "/manage",
    component: DashboardLayout,
    name: "Venues",
    children: [
        {
            path: "venues/list-venues",
            name: "List Venues",
            components: { default: ListVenuePage },
            meta: { middleware: auth }
        },
        // {
        //     path: "venues/add-venue",
        //     name: "Add Venue",
        //     components: { default: AddVenuePage },
        //     meta: { middleware: auth }
        // },
        {
            path: "venues/edit-venue/:id",
            name: "Edit Venue",
            components: { default: EditVenuePage },
            meta: { middleware: auth }
        }
    ]
};

export default venuesNav;
