import service from '@/store/services/subscription-service';

const state = {
    sessionId: null,
};

const mutations = {
  SET_RESOURCE: (state, sessionId) => {
    state.sessionId = sessionId;
  }
};

const actions = {

  getSession({ commit, dispatch }, params) {
    return service.get(params).then(sessionId => {
      commit("SET_RESOURCE", sessionId);
    });
  },
  add({ commit, dispatch }, params) {
    return service.add(params)
      .then((sessionId) => { commit('SET_RESOURCE', sessionId); });
  }
};

const getters = {
    getSession: state => state.sessionId
};

const subscription = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default subscription;
