import DashboardLayout from "@/views/App/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/Layout/AuthLayout.vue";

// GeneralViews
import NotFound from "@/views/General/NotFoundPage.vue";

// Example pages
import UserProfile from "@/views/Users/Profile/UserProfilePage.vue";
import ListRolePage from "@/views/Users/Role/ListRolePage.vue";
import EditRolePage from "@/views/Users/Role/EditRolePage.vue";
import AddRolePage from "@/views/Users/Role/AddRolePage.vue";
import ListUserPage from "@/views/Users/Management/ListUserPage.vue";
import EditUserPage from "@/views/Users/Management/EditUserPage.vue";
import AddUserPage from "@/views/Users/Management/AddUserPage.vue";

// Overview Management
import overviewNav from "@/router/navigation/overview-menu.js";

// Event Management
import itemsNav from "@/router/navigation/items-menu.js";

// Category Management
import categoriesNav from "@/router/navigation/categories-menu.js";

// Tag Management
import tagsNav from "@/router/navigation/tags-menu.js";


// Survey Management
import surveyNav from "@/router/navigation/surveys-menu.js";

// Ticket Management
import ticketsNav from "@/router/navigation/tickets-menu.js";

// Guest Management
import attendeesNav from "@/router/navigation/attendees-menu.js";

// Item Management
import ListItemPage from "@/views/Examples/ItemManagement/ListItemPage.vue";
import AddItemPage from "@/views/Examples/ItemManagement/AddItemPage.vue";
import EditItemPage from "@/views/Examples/ItemManagement/EditItemPage";

// Console  Management
import consoleNav from "@/router/navigation/console-menu.js";

// Venue Management
import venuesNav from "@/router/navigation/venues-menu.js";

// Reports
import reportsNav from "@/router/navigation/reports-menu.js";

// Speakers Management
import speakersNav from "@/router/navigation/speakers-menu.js";

//Sponsor Management
import sponsorsNav from "@/router/navigation/sponsors-menu.js";

// Public Pages
import publicNav from "@/router/navigation/public-menu.js";
import publicTicketNav from "@/router/navigation/public-ticket-menu.js";
import publicCalendarNav from "@/router/navigation/public-calendar-menu.js";
import publicCollectionsNav from "@/router/navigation/public-collections-menu.js";
import publicSurveyNav from "@/router/navigation/public-surveys-menu.js";

// Components pages
const Buttons = () =>
  import(/* webpackChunkName: "components" */ "@/views/Components/Buttons.vue");
const Cards = () =>
  import(/* webpackChunkName: "components" */ "@/views/Components/Cards.vue");
const GridSystem = () =>
  import(
    /* webpackChunkName: "components" */ "@/views/Components/GridSystem.vue"
  );
const Notifications = () =>
  import(
    /* webpackChunkName: "components" */ "@/views/Components/Notifications.vue"
  );
const Icons = () =>
  import(/* webpackChunkName: "components" */ "@/views/Components/Icons.vue");
const Typography = () =>
  import(
    /* webpackChunkName: "components" */ "@/views/Components/Typography.vue"
  );

// Forms pages
const FormElements = () =>
  import(/* webpackChunkName: "forms" */ "@/views/Forms/FormElements.vue");
const FormComponents = () =>
  import(/* webpackChunkName: "forms" */ "@/views/Forms/FormComponents.vue");
const FormValidation = () =>
  import(/* webpackChunkName: "forms" */ "@/views/Forms/FormValidation.vue");

// Maps pages
const GoogleMaps = () =>
  import(/* webpackChunkName: "extra" */ "@/views/Maps/GoogleMaps.vue");
const VectorMaps = () =>
  import(/* webpackChunkName: "extra" */ "@/views/Maps/VectorMaps.vue");

// Pages
const User = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Users/Profile/UserProfilePage.vue");
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const TimeLine = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/TimeLinePage.vue");
const Login = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/App/Login.vue");
const Register = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/App/Register.vue");
const Lock = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Lock.vue");
const PasswordReset = () =>
  import(/* webpackChunkName: "password" */ "@/views/Users/Password/Reset.vue");
const PasswordEmail = () =>
  import(/* webpackChunkName: "password" */ "@/views/Users/Password/Email.vue");
const Onboarding = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/App/Onboarding.vue");
// TableList pages
const RegularTables = () =>
  import(/* webpackChunkName: "tables" */ "@/views/Tables/RegularTables.vue");
const SortableTables = () =>
  import(/* webpackChunkName: "tables" */ "@/views/Tables/SortableTables.vue");
const PaginatedTables = () =>
  import(/* webpackChunkName: "tables" */ "@/views/Tables/PaginatedTables.vue");

//import middleware for AUTH  User-Roles-Permissions
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import admin from "@/middleware/admin";
import dev from "@/middleware/developer";
import creator from "@/middleware/creator";

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      component: Buttons
    },
    {
      path: "cards",
      name: "Cards",
      component: Cards
    },
    {
      path: "grid-system",
      name: "Grid System",
      component: GridSystem
    },
    {
      path: "notifications",
      name: "Notifications",
      component: Notifications
    },
    {
      path: "icons",
      name: "Icons",
      component: Icons
    },
    {
      path: "typography",
      name: "Typography",
      component: Typography
    }
  ]
};

let accountManagement = {
  path: "/users",
  component: DashboardLayout,
  name: "Console",
  children: [
    {
      path: "user-profile",
      name: "User Profile",
      components: { default: UserProfile },
      meta: { middleware: auth }
    },
  ]
};

let consoleMenu = {
  path: "/console",
  component: DashboardLayout,
  name: "Console",
  children: [
    {
      path: "user-profile",
      name: "User Profile",
      components: { default: UserProfile },
      meta: { middleware: auth }
    },
    {
      path: "console/roles/list-roles",
      name: "List Roles",
      components: { default: ListRolePage },
      meta: { middleware: auth }
    },
    {
      path: "role-management/add-role",
      name: "Add Role",
      components: { default: AddRolePage },
      meta: { middleware: auth }
    },
    {
      path: "role-management/edit-role/:id",
      name: "Edit Role",
      components: { default: EditRolePage },
      meta: { middleware: auth }
    },
    {
      path: "manage/users/list-users",
      name: "List Users",
      components: { default: ListUserPage },
      meta: { middleware: auth }
    },
    {
      path: "user-management/add-user",
      name: "Add User",
      components: { default: AddUserPage },
      meta: { middleware: auth }
    },
    {
      path: "user-management/edit-user/:id",
      name: "Edit User",
      components: { default: EditUserPage },
      meta: { middleware: auth }
    },
  ]
};

let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/elements",
  name: "Forms",
  children: [
    {
      path: "elements",
      name: "Form elements",
      component: FormElements
    },
    {
      path: "components",
      name: "Form components",
      component: FormComponents
    },
    {
      path: "validation",
      name: "Form validation",
      component: FormValidation
    }
  ]
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
    },
    {
      path: "/offer-onboarding",
      name: "Onboarding",
      component: Onboarding,
    },
    {
      path: "/password/reset",
      name: "PasswordReset",
      component: PasswordReset,
    },
    {
      path: "/password/email",
      name: "PasswordEmail",
      component: PasswordEmail,
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock
    }
  ]
};

let tablesMenu = {
  path: "/tables",
  component: DashboardLayout,
  redirect: "/table/regular",
  name: "Tables menu",
  children: [
    {
      path: "regular",
      name: "Tables",
      component: RegularTables
    },
    {
      path: "sortable",
      name: "Sortable",
      component: SortableTables
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      component: PaginatedTables
    }
  ]
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      component: GoogleMaps
    },
    {
      path: "vector",
      name: "Vector Map",
      component: VectorMaps
    }
  ]
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      component: User
    },
    {
      path: "timeline",
      name: "Timeline Page",
      component: TimeLine
    },
  ]
};

const routes = [
  {
    path: "/",
    redirect: "/manage/dashboard"
  },
  authPages,
  overviewNav,
  accountManagement,
  consoleMenu,
  componentsMenu,
  categoriesNav,
  itemsNav,
  publicNav,
  publicTicketNav,
  consoleNav,
  tagsNav,
  ticketsNav,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  attendeesNav,
  venuesNav,
  publicCalendarNav,
  reportsNav,
  publicCollectionsNav,
  surveyNav,
  publicSurveyNav,
  speakersNav,
  sponsorsNav
];

export default routes;
